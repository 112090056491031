import {
  ADD_CARRIERS_ERROR,
  ADD_CARRIERS_REQUEST,
  ADD_CARRIERS_SUCCESS,
  ALL_CARRIERS_ERROR,
  ALL_CARRIERS_REQUEST,
  ALL_CARRIERS_SUCCESS,
  LIST_CARRIERS_GEO_ERROR,
  LIST_CARRIERS_GEO_REQUEST,
  LIST_CARRIERS_GEO_SUCCESS,
  DELETE_CARRIERS_ERROR,
  DELETE_CARRIERS_REQUEST,
  DELETE_CARRIERS_SUCCESS,
} from "./ActionType";
const initialState = {
  isRequest: false,
  isSuccess: false,
  isError: false,
  allCarriers: [],
  geoCarrier: [],
  deleteCarrier: {
    isRequest: false,
    isSuccess: false,
    isError: false,
    data: {},
  },
  addedCarrier: {
    isRequest: false,
    isSuccess: false,
    isError: false,
    data: {},
  },
};


 const storeCourierMappingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_CARRIERS_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        allCarriers: action.payload,
      };
    case ALL_CARRIERS_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        allCarriers: action.payload,
      };
    case ALL_CARRIERS_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        allCarriers: action.payload,
      };

    case LIST_CARRIERS_GEO_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        geoCarrier: action.payload,
      };
    case LIST_CARRIERS_GEO_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        geoCarrier: action.payload,
      };
    case LIST_CARRIERS_GEO_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        geoCarrier: action.payload,
      };

    case DELETE_CARRIERS_REQUEST:
      return {
        ...state,
        deleteCarrier: {
          ...state.deleteCarrier,
          isRequest: true,
          isError: false,
          isSuccess: false,
          data: action.payload,
        },
      };
    case DELETE_CARRIERS_SUCCESS:
      return {
        ...state,
        deleteCarrier: {
          ...state.deleteCarrier,
          isSuccess: true,
          isRequest: false,
          isError: false,
          data: action.payload,
        },
      };
    case DELETE_CARRIERS_ERROR:
      return {
        ...state,
        deleteCarrier: {
          ...state.deleteCarrier,
          isSuccess: false,
          isRequest: false,
          isError: true,
          data: action.payload,
        },
      };

    case ADD_CARRIERS_REQUEST:
      return {
        ...state,
        addedCarrier: {
          ...state.deleteCarrier,
          isSuccess: false,
          isRequest: true,
          isError: false,
          data: action.payload,
        },
      };
    case ADD_CARRIERS_SUCCESS:
      return {
        ...state,
        addedCarrier: {
          ...state.deleteCarrier,
          isSuccess: true,
          isRequest: false,
          isError: false,
          data: action.payload,
        },
      };
    case ADD_CARRIERS_ERROR:
      return {
        ...state,
        addedCarrier: {
          ...state.deleteCarrier,
          isSuccess: false,
          isRequest: false,
          isError: true,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};

export default storeCourierMappingReducer;