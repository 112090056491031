import {
    EVENTS_ERROR,
    EVENTS_REQUEST,
    EVENTS_SUCCESS,
  } from "./ActionType";
  const initialState = {
    isRequest: false,
    isSuccess: false,
    isError: false,
    data: {},
  };
  
  const eventsReducer = (state = initialState, action) => {
    switch (action.type) {
      case EVENTS_REQUEST:
        return {
          isRequest: true,
          isSuccess: false,
          isError: false,
          data: action.payload,
        };
  
      case EVENTS_SUCCESS:
        return {
          isRequest: false,
          isSuccess: true,
          isError: false,
          data: action.payload,
        };
      case EVENTS_ERROR:
        return {
          isRequest: false,
          isSuccess: false,
          isError: true,
          data: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default eventsReducer;