import {ROUTES_NAME} from "../../../config/constants";
import {
    HISTORICAL_QUEUE_ERROR,
    HISTORICAL_QUEUE_REQUEST,
    HISTORICAL_QUEUE_SUCCESS,
    HISTORICAL_STATUS_ERROR,
    HISTORICAL_STATUS_REQUEST,
    HISTORICAL_STATUS_SUCCESS,
    HISTORICAL_TOKEN_ERROR,
    HISTORICAL_TOKEN_REQUEST,
    HISTORICAL_TOKEN_SUCCESS,
    HISTORICAL_TOTAL_POSSIBLE_ORDERS_REQUEST,
    HISTORICAL_TOTAL_POSSIBLE_ORDERS_TOKEN_ERROR,
    HISTORICAL_TOTAL_POSSIBLE_ORDERS_TOKEN_SUCCESS,
} from "./ActionType";
import { ACCESS_TOKEN } from "../../../constants";

export const historicalOrdersStatus = (payload) => {
 const {page, shopId, storeType} = payload;
  return (dispatch) => {
    dispatch(historicalOrdersRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.historicalStatus(shopId,storeType)}`;

    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        pageNumber: page,
        length: 10,
      }),
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        'Content-Type': 'application/json',
        'Origin': `${process.env.REACT_APP_URL}`
      },
    })
      .then((res) => res.json())
      .then((dashboardDetails) => {
        dispatch(historicalOrdersSuccess(dashboardDetails));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(historicalOrdersError());
      });
  };
};

function historicalOrdersRequest(dashboardData) {
    return {
        type: HISTORICAL_STATUS_REQUEST,
        payload: dashboardData,
    };
}

function historicalOrdersSuccess(dashboardData) {
    return {
        type: HISTORICAL_STATUS_SUCCESS,
        payload: dashboardData,
    };
}

function historicalOrdersError(dashboardData) {
    return {
        type: HISTORICAL_STATUS_ERROR,
        payload: dashboardData,
    };
}

export const historicalOrdersQueue = (payload) => {
  const {date, id, ordersCount,type, storeType} = payload;
  return (dispatch) => {
    dispatch(historicalOrdersQueueRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.storeQueue(id,storeType)}`;
    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        backInTimeType: type,
        date: date,
        ordersCount:ordersCount
      }),
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        'Content-Type': 'application/json',
        'Origin': `${process.env.REACT_APP_URL}`
      },
    })
      .then((res) => res.json())
      .then((dashboardDetails) => {
        dispatch(historicalOrdersQueueSuccess(dashboardDetails));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(historicalOrdersQueueError());
      });
  };
};

function historicalOrdersQueueRequest(dashboardData) {
    return {
        type: HISTORICAL_QUEUE_REQUEST,
        payload: dashboardData,
    };
}

function historicalOrdersQueueSuccess(dashboardData) {
    return {
        type: HISTORICAL_QUEUE_SUCCESS,
        payload: dashboardData,
    };
}

function historicalOrdersQueueError(dashboardData) {
    return {
        type: HISTORICAL_QUEUE_ERROR,
        payload: dashboardData,
    };
}

// API CALL FOR HISTORICAL ORDER TOKEN COUNT                                             

export const historicalOrdersTokenCount = (payload) => {
  const {date, shopId, storeType} = payload;
  return (dispatch) => {
    dispatch(historicalOrderstokenRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.historicalCount(shopId,storeType)}`;

    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        backInTimeType: "USER",
        date: date,
      }),
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        'Content-Type': 'application/json',
        'Origin': `${process.env.REACT_APP_URL}`
      },
    })
      .then((res) => res.json())
      .then((dashboardDetails) => {
        dispatch(historicalOrderstokenSuccess(dashboardDetails));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(historicalOrderstokenError());
      });
  };
};

function historicalOrderstokenRequest(dashboardData) {
    return {
        type: HISTORICAL_TOKEN_REQUEST,
        payload: dashboardData,
    };
}

function historicalOrderstokenSuccess(dashboardData) {
    return {
        type: HISTORICAL_TOKEN_SUCCESS,
        payload: dashboardData,
    };
}

function historicalOrderstokenError(dashboardData) {
    return {
        type: HISTORICAL_TOKEN_ERROR,
        payload: dashboardData,
    };
}

//API call for possible orders-count

export const gettotalPossibleOrders = (payload) => {
  const {date, shopId, storeType} = payload;
  return (dispatch) => {
    dispatch(totalPossibleOrdersRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.possible_orders(shopId, storeType)}`;

    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        backInTimeType: "USER",
        date: date,
      }),
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        'Content-Type': 'application/json',
        'Origin': `${process.env.REACT_APP_URL}`
      },
    })
      .then((res) => res.json())
      .then((dashboardDetails) => {
        dispatch(totalPossibleOrdersSuccess(dashboardDetails));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(totalPossibleOrdersError());
      });
  };
};

function totalPossibleOrdersRequest(dashboardData) {
  return {
      type: HISTORICAL_TOTAL_POSSIBLE_ORDERS_REQUEST,
      payload: dashboardData,
  };
}

function totalPossibleOrdersSuccess(dashboardData) {
  return {
      type: HISTORICAL_TOTAL_POSSIBLE_ORDERS_TOKEN_SUCCESS,
      payload: dashboardData,
  };
}

function totalPossibleOrdersError(dashboardData) {
  return {
      type: HISTORICAL_TOTAL_POSSIBLE_ORDERS_TOKEN_ERROR,
      payload: dashboardData,
  };
}