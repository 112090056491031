import {Divider, Image, Layout, LegacyCard, Select, TextField,} from "@shopify/polaris";
import {React, useCallback, useState} from "react";
import Header from "../../components/header";
import {ReportImg, ShopifyIcon} from "../../assets";
import "./style.css";
import DataTable from "./table/Table";
import Pagination from "../../components/pagination";

const Report = () => {
  const [report, setReport] = useState("Performance by day");
  const [entries, setEnteries] = useState("1");

  const handleReport = useCallback((value) => setReport(value), []);

  const options = [
    { label: "Performance by day", value: "Performance by day" },
    { label: "Awaiting sync counter", value: "Awaiting sync counter" },
    {
      label: "Trialing store total orders",
      value: "Trialing store total orders",
    },
  ];

  const handleEntriesChange = useCallback(
    (newValue) => setEnteries(newValue),
    []
  );
  return (
    <Layout>
      <Header title="Report" />
      <Layout.Section>
        <div className="wrapper">
          <LegacyCard sectioned title="">
            <div style={{ padding: "15px" }}>
              <p className="title">Report</p>
              <div className="mt-1 mb-1">
                <Divider borderStyle="base"></Divider>
              </div>
              <div className="d-flex gap-20">
                <Select
                  label=""
                  options={options}
                  onChange={handleReport}
                  value={report}
                />
                <div>
                  <Image src={ReportImg} width="100%" />
                </div>
              </div>
            </div>
          </LegacyCard>
        </div>
      </Layout.Section>
      {/* //Store Breakdown */}
      <Layout.Section>
        <div className="wrapper">
          <LegacyCard sectioned>
            <div style={{ padding: "15px" }}>
              <p className="title">Stores Breakdown</p>
              <div className="mt-1 mb-1">
                <Divider borderStyle="base"></Divider>
              </div>
              <div className="d-flex justify-content-between gap-10 flex_wrap">
                <div className="d-flex flex-col gap-10">
                  <div className="d-flex gap-10">
                    <div className="icon_container">
                      <span style={{ padding: "5px" }}>
                        <Image src={ShopifyIcon} width={20} />
                      </span>
                    </div>
                    <p className="integration_title">Shopify</p>
                  </div>
                  <div className="d-flex gap-20">
                    <p className="plans_text">Basic :</p>
                    <p className="amount plans_text">223,423</p>
                  </div>
                  <div className="d-flex gap-20">
                    <p className="plans_text">Premium :</p>
                    <p className="amount plans_text">223,423</p>
                  </div>
                  <div className="d-flex gap-20">
                    <p className="plans_text">Plus :</p>
                    <p className="amount plans_text">223,423</p>
                  </div>
                </div>

                <div className="d-flex flex-col gap-10">
                  <div className="d-flex gap-10">
                    <div className="icon_container">
                      <span style={{ padding: "5px" }}>
                        <Image src={ShopifyIcon} width={20} />
                      </span>
                    </div>
                    <p className="integration_title">Shopify</p>
                  </div>
                  <div className="d-flex gap-20">
                    <p className="plans_text">Basic :</p>
                    <p className="amount plans_text">223,423</p>
                  </div>
                  <div className="d-flex gap-20">
                    <p className="plans_text">Premium :</p>
                    <p className="amount plans_text">223,423</p>
                  </div>
                  <div className="d-flex gap-20">
                    <p className="plans_text">Plus :</p>
                    <p className="amount plans_text">223,423</p>
                  </div>
                </div>
                <div className="d-flex flex-col gap-10">
                  <div className="d-flex gap-10">
                    <div className="icon_container">
                      <span style={{ padding: "5px" }}>
                        <Image src={ShopifyIcon} width={20} />
                      </span>
                    </div>
                    <p className="integration_title">Shopify</p>
                  </div>
                  <div className="d-flex gap-20">
                    <p className="plans_text">Basic :</p>
                    <p className="amount plans_text">223,423</p>
                  </div>
                  <div className="d-flex gap-20">
                    <p className="plans_text">Premium :</p>
                    <p className="amount plans_text">223,423</p>
                  </div>
                  <div className="d-flex gap-20">
                    <p className="plans_text">Plus :</p>
                    <p className="amount plans_text">223,423</p>
                  </div>
                </div>
                <div className="d-flex flex-col gap-10">
                  <div className="d-flex gap-10">
                    <div className="icon_container">
                      <span style={{ padding: "5px" }}>
                        <Image src={ShopifyIcon} width={20} />
                      </span>
                    </div>
                    <p className="integration_title">Shopify</p>
                  </div>
                  <div className="d-flex gap-20">
                    <p className="plans_text">Basic :</p>
                    <p className="amount plans_text">223,423</p>
                  </div>
                  <div className="d-flex gap-20">
                    <p className="plans_text">Premium :</p>
                    <p className="amount plans_text">223,423</p>
                  </div>
                  <div className="d-flex gap-20">
                    <p className="plans_text">Plus :</p>
                    <p className="amount plans_text">223,423</p>
                  </div>
                </div>
              </div>
            </div>
          </LegacyCard>
        </div>
      </Layout.Section>
      {/* Table */}
      <Layout.Section>
        <div className="wrapper">
          <LegacyCard sectioned>
            <div style={{ padding: "15px" }}>
              <div className="d-flex justify-content-between flex-wrap">
                <div>
                  <Select
                    label="Type"
                    options={options}
                    onChange={handleReport}
                    value={report}
                  />
                </div>
                <div>
                  <TextField
                    label="Number of entries"
                    type="number"
                    value={entries}
                    onChange={handleEntriesChange}
                    autoComplete="off"
                  />
                </div>
              </div>
              <DataTable/>
              <div className="d-flex mt-2 justify-content-between">
                <p>Showing 0 to 0 of 0 entries</p>
                <Pagination/>
            </div>
            </div>
          </LegacyCard>
        </div>
      </Layout.Section>
    </Layout>
  );
};

export default Report;
