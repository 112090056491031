
const warningStatusMsg = [
    {
        backendStatusMessage: "Subscription canceled",
        languageConstStatusMessage: "Subscription_canceled",
        backendStatusDiscription: "Sync has been paused, please reactivate your plan.",
        languageConstStatusDiscription:"Subscription_canceled_discription"
    },
    {
        backendStatusMessage: "Missing merchant ID",
        languageConstStatusMessage: "Missing_merchant_ID",
        backendStatusDiscription:"Please set up a default merchant id [PayPal Setting link].",
        languageConstStatusDiscription:"Missing_merchant_ID_discription"
    },
    {
        backendStatusMessage: "Invalid tracking number",
        languageConstStatusMessage: "Invalid_tracking_number",
        backendStatusDiscription:"The Tracking number provided by your store as a non valid characters that not acceptable by Paypal.",
        languageConstStatusDiscription:"Invalid_tracking_number_dicsription"
    },
    {
        backendStatusMessage: "Invalid transaction ID",
        languageConstStatusMessage: "Invalid_transaction_ID",
        backendStatusDiscription:"Transaction ID does not belong to the linked PayPal account.",
        languageConstStatusDiscription:"Invalid_transaction_ID_discription"
    },
    {
        backendStatusMessage: "Digital sync disabled",
        languageConstStatusMessage: "Digital_sync_disabled",
        backendStatusDiscription:"Received order does not required shipping but digital order sync is disabled got to Store setting in order to [activate][Link to Store setting] it.",
        languageConstStatusDiscription:"Digital_sync_disabled_discription"
    },
    {
        backendStatusMessage: "Upsell sync disabled",
        languageConstStatusMessage: "Upsell_sync_disabled",
        backendStatusDiscription:"Multiple transactions attached to the order if you wish to sync them all please [enable][Link to Store setting] upsell feature from store setting.",
        languageConstStatusDiscription:"Upsell_sync_disabled_discription"
    },
    {
        backendStatusMessage: "Carrier matcher disabled",
        languageConstStatusMessage: "Carrier_matcher_disabled",
        backendStatusDiscription:"We did find a matching carrier[FOUND_CARRIER_NAME] with an accuracy of 89%, to sync this order you need to [activate carrier matcher][Link to Store setting] and set up your accepted accuracy.",
        languageConstStatusDiscription:"Carrier_matcher_disabled_discription"
    },
    {
        backendStatusMessage: "Daily quota limit reached",
        languageConstStatusMessage: "Daily_quota_limit_reached",
        backendStatusDiscription:"This Order will be synced as we are putting on the queue for the next batch. Queuing/Scheduled.",
        languageConstStatusDiscription:"Daily_quota_limit_reached_discription"
    },
    {
        backendStatusMessage: "Retry failed order",
        languageConstStatusMessage: "Retry_failed_order",
        backendStatusDiscription:"Your failed orders have been sent to re-sync queue.",
        languageConstStatusDiscription:"Retry_failed_order_discription"
    },
    {
        backendStatusMessage: "Unfulfilled",
        languageConstStatusMessage: "Unfulfilled",
        backendStatusDiscription:"Order is not fulfilled on the ecommerce Store.",
        languageConstStatusDiscription:"Unfulfilled_discription"
    },
    {
        backendStatusMessage: "Upgrade required",
        languageConstStatusMessage: "Upgrade_required",
        backendStatusDiscription:"This order is a digital Product order and required to [upgrade][should fire upgrade action] to premium to get it synced.",
        languageConstStatusDiscription:"Upgrade_required_discription"
    },
    {
        backendStatusMessage: "Invalid carrier",
        languageConstStatusMessage: "Invalid_carrier",
        backendStatusDiscription:"Please provide a valid carrier name in your Shopify store.",
        languageConstStatusDiscription:"Invalid_carrier_discription"
    },
    {
        backendStatusMessage: "Unknown error",
        languageConstStatusMessage: "Unknown_error",
        backendStatusDiscription:"Unknown Error received from Paypal.",
        languageConstStatusDiscription:"Unknown_error_discription"
    },
    {
        backendStatusMessage:"Synced using routing policy.",
        languageConstStatusMessage:"Synced_using_routing_policy",
        backendStatusDiscription:"",
        languageConstStatusDiscription:""
    },
    {
        backendStatusMessage:"Synced using carrier perfect matcher algorithm.",
        languageConstStatusMessage:"Synced_using_carrier_perfect_matcher_algorithm",
        backendStatusDiscription:"",
        languageConstStatusDiscription:""
    },
    {
        backendStatusMessage:"Synced using last mile carrier.",
        languageConstStatusMessage:"Synced_using_last_mile_carrier",
        backendStatusDiscription:"",
        languageConstStatusDiscription:""
    },
    {
        backendStatusMessage:"Invalid Gateway",
        languageConstStatusMessage:"Invalid_Gateway",
        backendStatusDiscription:"Gateway is not Paypal.",
        languageConstStatusDiscription:"Gateway_is_not_Paypal"
    },
    {
        backendStatusMessage:"Paypal not connected",
        languageConstStatusMessage:"Paypal_not_connected",
        backendStatusDiscription:"Merchant ID {{merchantAccountId}} is not connected to Trackipal.",
        languageConstStatusDiscription:"Merchant_ID_is_not_connected_to_Trackipal"
    },
    {
        backendStatusMessage:"",
        languageConstStatusMessage:"",
        backendStatusDiscription:"Please set up a default merchant id.",
        languageConstStatusDiscription:"Please_set_up_a_default_merchant_id"
    },
    {
        backendStatusMessage:"",
        languageConstStatusMessage:"",
        backendStatusDiscription:"Order is not fulfilled on the ecommerce Store.",
        languageConstStatusDiscription:"Order_is_not_fulfilled_on_the_ecommerce_Store"
    },
    {
        backendStatusMessage:"",
        languageConstStatusMessage:"",
        backendStatusDiscription:"The Tracking number provided by your store as a non valid characters that not acceptable by Paypal.",
        languageConstStatusDiscription:"The_Tracking_number_provided_by_your_store_as_a_non_valid_characters_that_not_acceptable_by_Paypal"
    },
    {
        backendStatusMessage:"",
        languageConstStatusMessage:"",
        backendStatusDiscription:"Transaction ID does not belong to the linked PayPal account.",
        languageConstStatusDiscription:"Transaction_ID_does_not_belong_to_the_linked_PayPal_account"
    },
    {
        backendStatusMessage:"",
        languageConstStatusMessage:"",
        backendStatusDiscription:"Sync has been paused, please reactivate your plan.",
        languageConstStatusDiscription:"Sync_has_been_pause_please_reactivate_your_plan"
    },
    {
        backendStatusMessage:"",
        languageConstStatusMessage:"",
        backendStatusDiscription:"Received order does not required shipping but digital order sync is disabled got to Store setting in order to it.",
        languageConstStatusDiscription:"Received_order_does_not_required_shipping_but_digital_order_sync_is_disabled_got_to_Store_setting_in_order_to_it"
    },
    {
        backendStatusMessage:"",
        languageConstStatusMessage:"",
        backendStatusDiscription:"This order is a digital Product order and required to upgrade to premium to get it synced.",
        languageConstStatusDiscription:"This_order_is_a_digital_Product_order_and_required_to_upgrade_to_premium_to_get_it_synced."
    },
    {
        backendStatusMessage:"",
        languageConstStatusMessage:"",
        backendStatusDiscription:"Multiple transactions attached to the order if you wish to sync them all please enable upsell feature from store setting.",
        languageConstStatusDiscription:"Multiple_transactions_attached_to_the_order_if_you_wish_to_sync_them_all_please_enable_upsell_feature_from_store_setting."
    },
    {
        backendStatusMessage:"",
        languageConstStatusMessage:"",
        backendStatusDiscription:"This Order will be synced as we are putting on the queue for the next batch. Queuing/Scheduled.",
        languageConstStatusDiscription:"This_Order_will_be_synced_as_we_are_putting_on_the_queue_for_the_next_batch_Queuing/Scheduled."
    },
    {
        backendStatusMessage:"",
        languageConstStatusMessage:"",
        backendStatusDiscription:"Your failed orders have been sent to re-sync queue.",
        languageConstStatusDiscription:"Your_failed_orders_have_been_sent_to_re_sync_queue."
    },
]



export function getStatusMessage(message) {
  const statusInfo = warningStatusMsg.find((item) => {
    return item.backendStatusMessage === message;
  });

  return statusInfo?.languageConstStatusMessage ?? message;
}

export function getStatusDescription(description) {
  const statusInfo = warningStatusMsg.find((item) => {
    return item.backendStatusDiscription === description;
  })

  return statusInfo?.languageConstStatusDiscription ?? description;
}
