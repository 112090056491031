import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export  const TableSkeleton = () => {
  return (
    <>
      <div style={{ display: "flex", gap: "", justifyContent:"space-between",marginTop:"10px", overflow:"hidden"}}>
        <div style={{ display: "flex", gap: "35px", flexDirection: "column" }}>
          <Box sx={{ width: 200 }}>
            <Skeleton animation={false} />
            <Skeleton animation={false} />
          </Box>
          <Box sx={{ width: 200 }}>
            <Skeleton animation={false} />
            <Skeleton animation={false} />
          </Box>
          <Box sx={{ width: 200 }}>
            <Skeleton animation={false} />
            <Skeleton animation={false} />
          </Box>
          <Box sx={{ width: 200 }}>
            <Skeleton animation={false} />
            <Skeleton animation={false} />
          </Box>
          <Box sx={{ width: 200 }}>
            <Skeleton animation={false} />
            <Skeleton animation={false} />
          </Box>
        </div>
        <div style={{ display: "flex", gap: "35px", flexDirection: "column" }}>
          <Box sx={{ width: 400 }}>
            <Skeleton animation={false} />
            <Skeleton animation={false} />
          </Box>
          <Box sx={{ width: 400 }}>
            <Skeleton animation={false} />
            <Skeleton animation={false} />
          </Box>
          <Box sx={{ width: 400 }}>
            <Skeleton animation={false} />
            <Skeleton animation={false} />
          </Box>
          <Box sx={{ width: 400 }}>
            <Skeleton animation={false} />
            <Skeleton animation={false} />
          </Box>
          <Box sx={{ width: 400 }}>
            <Skeleton animation={false} />
            <Skeleton animation={false} />
          </Box>
        </div>
        <div style={{ display: "flex", gap: "35px", flexDirection: "column" }}>
          <Box sx={{ width: 200 }}>
            <Skeleton animation={false} />
            <Skeleton animation={false} />
          </Box>
          <Box sx={{ width: 200 }}>
            <Skeleton animation={false} />
            <Skeleton animation={false} />
          </Box>
          <Box sx={{ width: 200 }}>
            <Skeleton animation={false} />
            <Skeleton animation={false} />
          </Box>
          <Box sx={{ width: 200 }}>
            <Skeleton animation={false} />
            <Skeleton animation={false} />
          </Box>
          <Box sx={{ width: 200 }}>
            <Skeleton animation={false} />
            <Skeleton animation={false} />
          </Box>
        </div>
        <div style={{ display: "flex", gap: "35px", flexDirection: "column" }}>
          <Box sx={{ width: 100 }}>
            <Skeleton height={35} animation={false} />
          </Box>

          <Box sx={{ width: 100 }}>
            <Skeleton height={35} animation={false} />
          </Box>

          <Box sx={{ width: 100 }}>
            <Skeleton height={35} animation={false} />
          </Box>

          <Box sx={{ width: 100 }}>
            <Skeleton height={35} animation={false} />
          </Box>

          <Box sx={{ width: 100 }}>
            <Skeleton height={35} animation={false} />
          </Box>
        </div>
        <div style={{ display: "flex", gap: "35px", flexDirection: "column" }}>
          <Box sx={{ width: 200 }}>
            <Skeleton animation={false} />
          </Box>
          <Box sx={{ width: 200 }}>
            <Skeleton animation={false} />
          </Box>
          <Box sx={{ width: 200 }}>
            <Skeleton animation={false} />
          </Box>
          <Box sx={{ width: 200 }}>
            <Skeleton animation={false} />
          </Box>
          <Box sx={{ width: 200 }}>
            <Skeleton animation={false} />
          </Box>
          <Box sx={{ width: 200 }}>
            <Skeleton animation={false} />
          </Box>
        </div>
      </div>
    </>
  );
}

export const OrderTableSkeleton = () => {
    return (
      <div style={{ display: "flex", gap: "10px", justifyContent:"space-between",overflow:"hidden" }}>
          <div style={{ display: "flex", gap: "35px", flexDirection: "column" }}>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
          </div>
          <div style={{ display: "flex", gap: "35px", flexDirection: "column" }}>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
          </div>
          <div style={{ display: "flex", gap: "35px", flexDirection: "column" }}>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 200 }}>
              <Skeleton animation={false} />
              <Skeleton animation={false} />
            </Box>
          </div>
   
          <div style={{ display: "flex", gap: "35px", flexDirection: "column", alignItems:"center" }}>
            <Box sx={{ width: 100 }}>
              <Skeleton height={35} animation={false} />
            </Box>
  
            <Box sx={{ width: 100 }}>
              <Skeleton height={35} animation={false} />
            </Box>
  
            <Box sx={{ width: 100 }}>
              <Skeleton height={35} animation={false} />
            </Box>
  
            <Box sx={{ width: 100 }}>
              <Skeleton height={35} animation={false} />
            </Box>
  
            <Box sx={{ width: 100 }}>
              <Skeleton height={35} animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton height={35} animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton height={35} animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton height={35} animation={false} />
            </Box>
          </div>
          <div style={{marginTop:"10px", display: "flex", gap: "35px", flexDirection: "column" }}>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
          </div>
          <div style={{marginTop:"10px", display: "flex", gap: "35px", flexDirection: "column" }}>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
          </div>
          <div style={{marginTop:"10px", display: "flex", gap: "35px", flexDirection: "column" }}>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton animation={false} />
            </Box>
          </div>
        </div>
  
    );
  }
  
  export const  HistoricalSkeletonTable = () => {
    return (
      <div style={{ display: "flex", gap: "10px", justifyContent:"space-between", overflow:"hidden" }}>
          
          <div style={{ display: "flex", gap: "35px", flexDirection: "column" }}>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            
          </div>
          <div style={{ display: "flex", gap: "35px", flexDirection: "column" }}>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            
          </div>
          <div style={{ display: "flex", gap: "35px", flexDirection: "column" }}>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            
          </div>
          <div style={{ display: "flex", gap: "", flexDirection: "column", justifyContent:"space-between" }}>
            <Box sx={{ width: 100 }}>
              <Skeleton height={35} animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton height={35} animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton height={35} animation={false} />
            </Box>
            <Box sx={{ width: 100 }}>
              <Skeleton height={35} animation={false} />
            </Box>
  
            <Box sx={{ width: 100 }}>
              <Skeleton height={35} animation={false} />
            </Box>
  
            <Box sx={{ width: 100 }}>
              <Skeleton height={35} animation={false} />
            </Box>
  
            
          
          </div>
          <div style={{ display: "flex", gap: "35px", flexDirection: "column" }}>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: 150 }}>
              <Skeleton animation={false} />
            </Box>
            
          </div>
        </div>
  
    );
  }