import React from 'react'

const StoreCustomBadge = (props) => {
    return (
        <div style={{background:props.color,fontSize:"12px",padding:"0px 10px",color:props.textcolor}}  className='customBadge d-flex table-data'>
          <span>{props.text}</span>
        </div>
      )
}

export default StoreCustomBadge
