import React from 'react'

const CustomBadge = (props) => {
  console.log(props, 'CustomBadge')
  return (
    <div style={{background:props.color, color:props.textColor}}  className='customBadge d-flex table-data'>
      <span style={{background:props.dot}}  className='dot'></span>
      <span>{props.text}</span>
    </div>
  )
}

export default CustomBadge
