export const DASHBOARD_CARDS_REQUEST = 'DASHBOARD_CARDS_REQUEST';
export const DASHBOARD_CARDS_SUCCESS = 'DASHBOARD_CARDS_SUCCESS ';
export const DASHBOARD_CARDS_ERROR = 'DASHBOARD_CARDS_ERROR';

export const TOTAL_SYNCED_YESTERDAY_REQUEST = 'TOTAL_SYNCED_YESTERDAY_REQUEST';
export const TOTAL_SYNCED_YESTERDAY__SUCCESS = 'TOTAL_SYNCED_YESTERDAY__SUCCESS ';
export const TOTAL_SYNCED_YESTERDAY__ERROR = 'TOTAL_SYNCED_YESTERDAY__ERROR';

export const TOTAL_SYNCED_TODAY_REQUEST = 'TOTAL_SYNCED_TODAY_REQUEST';
export const TOTAL_SYNCED_TODAY_SUCCESS = 'TOTAL_SYNCED_TODAY_SUCCESS ';
export const TOTAL_SYNCED_TODAY_ERROR = 'TOTAL_SYNCED_TODAY_ERROR';

export const TOTAL_SYNCED_LASTTWODAYS_REQUEST = 'TOTAL_SYNCED_LASTTWODAYS_REQUEST';
export const TOTAL_SYNCED_LASTTWODAYS_SUCCESS = 'TOTAL_SYNCED_LASTTWODAYS_SUCCESS ';
export const TOTAL_SYNCED_LASTTWODAYS_ERROR = 'TOTAL_SYNCED_LASTTWODAYS_ERROR';

export const TOTAL_SYNCED_LASTTHIRTYDAYS_REQUEST = 'TOTAL_SYNCED_LASTTHIRTYDAYS_REQUEST';
export const TOTAL_SYNCED_LASTTHIRTYDAYS_SUCCESS = 'TOTAL_SYNCED_LASTTHIRTYDAYS_SUCCESS ';
export const TOTAL_SYNCED_LASTTHIRTYDAYS_ERROR = 'TOTAL_SYNCED_LASTTHIRTYDAYS_ERROR';

export const TOTAL_SYNCED_LASTSEVENDAYS_REQUEST = 'TOTAL_SYNCED_LASTSEVENDAYS_REQUEST';
export const TOTAL_SYNCED_LASTSEVENDAYS_SUCCESS = 'TOTAL_SYNCED_LASTSEVENDAYS_SUCCESS ';
export const TOTAL_SYNCED_LASTSEVENDAYS_ERROR = 'TOTAL_SYNCED_LASTSEVENDAYS_ERROR';

export const TOTAL_STORES_REQUEST = 'TOTAL_STORES_REQUEST';
export const TOTAL_STORES_SUCCESS = 'TOTAL_STORES_SUCCESS ';
export const TOTAL_STORES_ERROR = 'TOTAL_STORES_ERROR';

export const TOTAL_PAYPAL_UPLOADS_REQUEST = 'TOTAL_PAYPAL_UPLOADS_REQUEST';
export const TOTAL_PAYPAL_UPLOADS_SUCCESS = 'TOTAL_PAYPAL_UPLOADS_SUCCESS ';
export const TOTAL_PAYPAL_UPLOADS_ERROR = 'TOTAL_PAYPAL_UPLOADS_ERROR';

export const TOTAL_ORDERS_REQUEST = 'TOTAL_ORDERS_REQUEST';
export const TOTAL_ORDERS_SUCCESS = 'TOTAL_ORDERS_SUCCESS ';
export const TOTAL_ORDERS_ERROR = 'TOTAL_ORDERS_ERROR';

export const TOTAL_ORDERS_ACTIVE_REQUEST = 'TOTAL_ORDERS_ACTIVE_REQUEST';
export const TOTAL_ORDERS_ACTIVE_SUCCESS = 'TOTAL_ORDERS_ACTIVE_SUCCESS ';
export const TOTAL_ORDERS_ACTIVE_ERROR = 'TOTAL_ORDERS_ACTIVE_ERROR';

export const DASHBOARD_PROFILE_REQUEST = 'DASHBOARD_PROFILE_REQUEST';
export const DASHBOARD_PROFILE_SUCCESS = 'DASHBOARD_PROFILE_SUCCESS ';
export const DASHBOARD_PROFILE_ERROR = 'DASHBOARD_PROFILE_ERROR';

export const DASHBOARD_AWAITINGSYNC_REQUEST = 'DASHBOARD_AWAITINGSYNC_REQUEST';
export const DASHBOARD_AWAITINGSYNC_SUCCESS = 'DASHBOARD_AWAITINGSYNC_SUCCESS ';
export const DASHBOARD_AWAITINGSYNC_ERROR = 'DASHBOARD_AWAITINGSYNC_ERROR';
