import { React, useEffect, useState } from "react";
import "./style.css";
import {
  Button,
  Image,
  Layout,
  LegacyCard,
  Link,
  Text,
  Icon,
} from "@shopify/polaris";
import { ThreeDot } from "../../../assets";
import {
  getDashboardAllData,
  getFailedData,
  getLinkedPaypalShop,
  getMissingData,
  getNetworth,
  getPendingData,
  getQueuedData,
  getSyncedData,
  getSyncedLastTenDays,
  receivedLastTenDaysRequestData,
} from "../../../redux/store/dashboard/Action";
import { useDispatch, useSelector } from "react-redux";
import Chart from "../../../components/chart/DashboardChart";
import { useNavigate, useParams } from "react-router-dom";
import { Curriencies } from "../../../utils/Currencies";
import { getStoreDetails } from "../../../redux/store/find_by_id/Action";
import { ArrowUpMinor, ArrowDownMinor } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";

const CongratulationsBanner = () => {
  const navigate = useNavigate();
  const { id,storeType} = useParams();
  const { t, i18n } = useTranslation();
  const [networth, setNewtworth] = useState(0);
  const [receivedOrder, setReceivedOrder] = useState(0);
  const [syncedOrder, setSyncedOrder] = useState(0);
  const [receivedAmount, setReceivedAmount] = useState(5);
  const [SyncedAmount, setSyncedAmount] = useState(0);
  const [currencyCode, setCurrencyCode] = useState("USD");
  const [receivedData, setReceivedData] = useState([]);
  const [syncedData, setSyncedData] = useState([]);
  const [orderEvolution, setOrderEvolution] = useState();
  const [processedOrderEvolution, setProcessedOrderEvolution] = useState();

  const dashboardData = useSelector(
    (store) => store.requiredStoreDashboardData
  );
  const StoreDetailsData = useSelector(
    (store) => store.requireStoreDetailsRedycer
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNetworth(id, storeType));
    dispatch(getSyncedData(id, storeType));
    dispatch(getSyncedLastTenDays(id,storeType));
    dispatch(receivedLastTenDaysRequestData(id,storeType));
    dispatch(getLinkedPaypalShop(id,storeType));
    dispatch(getDashboardAllData(id, storeType));
    dispatch(getFailedData(id, storeType));
    dispatch(getMissingData(id, storeType));
    dispatch(getQueuedData(id, storeType));
    dispatch(getPendingData(id, storeType));
    dispatch(getStoreDetails(id, storeType));
  }, [id]);
  useEffect(() => {
    if (
      dashboardData.isSuccess &&
      dashboardData?.synced &&
      dashboardData.syncedLastTenDays !== undefined &&
      dashboardData.receivedLastTenDays !== undefined
    ) {
      setReceivedData(dashboardData?.receivedLastTenDays);
      setSyncedData(dashboardData?.syncedLastTenDays);
      setNewtworth(dashboardData?.data?.networth);
      let totalAmount = 0;
      let totalCount = 0;
      dashboardData?.syncedLastTenDays?.map((i) => {
        totalAmount = totalAmount + i.value;
        totalCount = totalCount + i.count;
        return ()=>{};
      });
      setSyncedOrder(totalCount);
      setSyncedAmount(totalAmount);

      let amount = 0;
      let count = 0;

      dashboardData?.receivedLastTenDays?.map((i) => {
        amount = amount + i.value;
        count = count + i.count;
        return ()=>{};
      });
      setReceivedAmount(amount);
      setReceivedOrder(count);
      let yesterday =
        dashboardData?.receivedLastTenDays[
          dashboardData?.receivedLastTenDays?.length - 1
        ]?.count;
      let dayBeforeYesterday =
        dashboardData?.receivedLastTenDays[
          dashboardData?.receivedLastTenDays?.length - 2
        ]?.count;
      let change =
        ((yesterday - dayBeforeYesterday) / dayBeforeYesterday) * 100;
      setOrderEvolution(change);

      let yesterdayy =
        dashboardData.syncedLastTenDays[
          dashboardData.syncedLastTenDays?.length - 1
        ]?.count;
      let dayBeforeYesterdayy =
        dashboardData?.syncedLastTenDays[
          dashboardData?.syncedLastTenDays?.length - 2
        ]?.count;
      let changee =
        ((yesterdayy - dayBeforeYesterdayy) / dayBeforeYesterday) * 100;
      setProcessedOrderEvolution(changee);
    }
    if (
      dashboardData?.synced?.message === "Actual error message" ||
      dashboardData?.syncedLastTenDays?.message === "Actual error message" ||
      dashboardData?.receivedLastTenDays?.message === "Actual error message" ||
      dashboardData?.data?.message === "Actual error message"
    ) {
      navigate("/logout");
    }
  }, [dashboardData]);

  useEffect(() => {
    if (
      StoreDetailsData.isSuccess === true &&
      StoreDetailsData.findById !== undefined &&
      StoreDetailsData.findById.currency !== null
    ) {
      Curriencies.map((i) => {
        if (i.code === StoreDetailsData?.findById?.currency) {
          // setCurrency(i.symbol + " ");
          setCurrencyCode(i.code);
        }
      });
    }
    if(StoreDetailsData?.findById?.message === "Actual error message") {
      navigate('/logout')
    }
  }, [StoreDetailsData]);
  return (
    <>
      <Layout>
        <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
          <Layout.Section>
            <LegacyCard sectioned>
              <div
                className="banner d-flex gap-10 align-item-center justify-content-center lpMode">
                <div className="banner-bg">
                  <div>
                      <span className="networth">
                        {networth === undefined
                          ? ""
                          : networth.toLocaleString("en-US", {
                              style: "currency",
                              currency: currencyCode,
                            })}
                      </span>
                  </div>
                  <Text>
                    <p className="congratulationsText">
                    {t("Congratulations!")}
                      {networth === undefined
                        ? ""
                        : networth.toLocaleString("en-US", {
                            style: "currency",
                            currency: currencyCode,
                          })}
                      {t("worthofrevenuesofar")}
                    </p>
                  </Text>
                  <div style={{ marginBottom: "50px", marginTop: "18px" }}>
                    <Link
                      url="https://apps.shopify.com/trackipal?#modal-show=ReviewListingModal"
                      external
                    >
                      <Button primary> {t("RATEUS")}</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </LegacyCard>
          </Layout.Section>
          <Layout.Section oneThird>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <LegacyCard sectioned>
                <div className="">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex gap-5">
                      <span className="orderText">{t("OrdersReceived")}</span>
                      <span className="last10days">({t("last10days")})</span>
                    </div>
                    <div style={{ padding: "0 20px" }}>
                      <Image
                        source={ThreeDot}
                        alt="Nice work on building a Shopify app"
                        width={20}
                      />
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-between"
                    style={{ marginTop: "10px" }}
                  >
                    <div>
                      <p className="d-flex gap-10">
                        <span className="order-receivedOrder-syncedOrder-digit">
                          {" "}
                          {receivedOrder}{" "}
                        </span>
                        <span className="d-flex ">
                          {isNaN(orderEvolution) ? (
                            ""
                          ) : (
                            <>
                              <span
                                style={{
                                  color:
                                    orderEvolution > 0 ? " #007F60" : "#c5280c",
                                }}
                              >
                                {orderEvolution.toFixed(2)}%
                              </span>
                              <div>
                                <Icon
                                  source={
                                    orderEvolution > 0
                                      ? ArrowUpMinor
                                      : ArrowDownMinor
                                  }
                                  color={
                                    orderEvolution > 0 ? "primary" : "critical"
                                  }
                                />
                              </div>
                            </>
                          )}
                        </span>
                      </p>
                      <h1>
                        {receivedAmount === undefined
                          ? ""
                          : receivedAmount.toLocaleString("en-US", {
                              style: "currency",
                              currency: currencyCode,
                            })}
                      </h1>
                    </div>
                    <div>
                      <Chart data={receivedData} />
                    </div>
                  </div>
                </div>
              </LegacyCard>
              <LegacyCard sectioned>
                <div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex gap-5">
                      <span className="orderText">{t("OrdersSynced")}</span>
                      <span className="last10days">({t("last10days")})</span>
                    </div>
                    <div style={{ padding: "0 20px" }}>
                      <Image
                        source={ThreeDot}
                        alt="Nice work on building a Shopify app"
                        width={20}
                      />
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-between"
                    style={{ marginTop: "10px" }}
                  >
                    <div>
                      <p className="d-flex gap-10">
                        <span className="order-receivedOrder-syncedOrder-digit">
                          {syncedOrder}{" "}
                        </span>
                        <span className="d-flex ">
                          {isNaN(processedOrderEvolution) ? (
                            ""
                          ) : (
                            <>
                              <span
                                style={{
                                  color:
                                    processedOrderEvolution > 0
                                      ? " #007F60"
                                      : "#c5280c",
                                }}
                              >
                                {processedOrderEvolution.toFixed(2)}%
                              </span>
                              <div>
                                <Icon
                                  source={
                                    processedOrderEvolution > 0
                                      ? ArrowUpMinor
                                      : ArrowDownMinor
                                  }
                                  color={
                                    processedOrderEvolution > 0
                                      ? "primary"
                                      : "critical"
                                  }
                                />
                              </div>
                            </>
                          )}
                        </span>
                      </p>
                      <h1>
                        {SyncedAmount === undefined
                          ? ""
                          : SyncedAmount.toLocaleString("en-US", {
                              style: "currency",
                              currency: currencyCode,
                            })}
                      </h1>
                    </div>
                    <div>
                      <Chart data={syncedData} />
                    </div>
                  </div>
                </div>
              </LegacyCard>
            </div>
          </Layout.Section>
        </div>
      </Layout>
    </>
  );
};

export default CongratulationsBanner;
