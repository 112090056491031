import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppProvider } from "@shopify/polaris";
import { BrowserRouter, Link as ReactRouterLink } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/Store";
import en from '@shopify/polaris/locales/en.json';
import i18n from "./pages/i18n";
import Auth0ProviderWithHistory from "./context/auth/auth0-provider-with-history";


const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <AppProvider linkComponent={Link} i18n={en}>
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <Provider store={store}>
          <App />
        </Provider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </AppProvider>
  // </React.StrictMode>
);

function Link({ children, url = '', ...rest }) {
  if (isOutboundLink(url) || rest.download) {
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }
  return (
    <ReactRouterLink to={url} {...rest}>
      {children}
    </ReactRouterLink>
  );
}
function isOutboundLink(url) {
  return /^(?:[a-z][a-z\d+.-]*:|\/\/)/.test(url);
}

reportWebVitals();
