import { ROUTES_NAME } from "../../../config/constants";
import {
  ADVANCE_SEARCH_ERROR,
  ADVANCE_SEARCH_REQUEST,
  ADVANCE_SEARCH_SUCCESS,
  DASHBOARD_ALL_ERROR,
  DASHBOARD_ALL_REQUEST,
  DASHBOARD_ALL_SUCCESS,
  FAILED_ERROR,
  FAILED_REQUEST,
  FAILED_SUCCESS,
  INVALID_GATEWAY_ERROR,
  INVALID_GATEWAY_REQUEST,
  INVALID_GATEWAY_SUCCESS,
  LINKED_PAYPAL_ERROR,
  LINKED_PAYPAL_REQUEST,
  LINKED_PAYPAL_SUCCESS,
  MISSING_ERROR,
  MISSING_REQUEST,
  MISSING_SUCCESS,
  NETWORTH_ERROR,
  NETWORTH_REQUEST,
  NETWORTH_SUCCESS,
  PENDING_ERROR,
  PENDING_REQUEST,
  PENDING_SUCCESS,
  QUEUED_ERROR,
  QUEUED_REQUEST,
  QUEUED_SUCCESS,
  RECEIVED_LAST_TEN_DAYS_ERROR,
  RECEIVED_LAST_TEN_DAYS_REQUEST,
  RECEIVED_LAST_TEN_DAYS_SUCCESS,
  SYNCED_ERROR,
  SYNCED_LAST_TEN_DAYS_ERROR,
  SYNCED_LAST_TEN_DAYS_REQUEST,
  SYNCED_LAST_TEN_DAYS_SUCCESS,
  SYNCED_REQUEST,
  SYNCED_SUCCESS,
} from "./ActionType";
import { shopID } from "../../../utils";
import { ACCESS_TOKEN } from "../../../constants";

export const getNetworth = (shopId,storeType) => {
  return async (dispatch) => {
    await dispatch(networthRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.networth(
      shopId, storeType
    )}`;

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((newtworth) => {
        dispatch(networthSuccess(newtworth));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(networthError());
      });
  };
};

function networthRequest(newtworthData) {
  return {
    type: NETWORTH_REQUEST,
    payload: newtworthData,
  };
}

function networthSuccess(newtworthData) {
  return {
    type: NETWORTH_SUCCESS,
    payload: newtworthData,
  };
}

function networthError(newtworthData) {
  return {
    type: NETWORTH_ERROR,
    payload: newtworthData,
  };
}

//for synced data

export const getSyncedData = (shopId,storeType) => {
  return async (dispatch) => {
    await dispatch(syncedRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.synced(
      shopId,storeType
    )}`;
    console.log(url, "synced");

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((synced) => {
        dispatch(syncedSuccess(synced));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(syncedError());
      });
  };
};

function syncedRequest(syncedData) {
  return {
    type: SYNCED_REQUEST,
    payload: syncedData,
  };
}

function syncedSuccess(syncedData) {
  return {
    type: SYNCED_SUCCESS,
    payload: syncedData,
  };
}

function syncedError(syncedData) {
  return {
    type: SYNCED_ERROR,
    payload: syncedData,
  };
}

//LAST TEN DAYS SYNCED

export const getSyncedLastTenDays = (shopId,storeType) => {
  return async (dispatch) => {
    dispatch(syncedLastTenDaysRequest());
    const url = `${
      process.env.REACT_APP_BACKEND_API_URL
    }${ROUTES_NAME.synced_last_ten_days(shopId,storeType)}`;

    await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch(syncedLastTenDaysSuccess(data));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(syncedLastTenDaysError());
      });
  };
};

function syncedLastTenDaysRequest(syncedData) {
  return {
    type: SYNCED_LAST_TEN_DAYS_REQUEST,
    payload: syncedData,
  };
}

function syncedLastTenDaysSuccess(syncedData) {
  return {
    type: SYNCED_LAST_TEN_DAYS_SUCCESS,
    payload: syncedData,
  };
}

function syncedLastTenDaysError(syncedData) {
  return {
    type: SYNCED_LAST_TEN_DAYS_ERROR,
    payload: syncedData,
  };
}

//RECEIVED LAST TEN DAYS

export const receivedLastTenDaysRequestData = (shopId,storeType) => {
  return async (dispatch) => {
    dispatch(receivedLastTenDaysRequest());
    const url = `${
      process.env.REACT_APP_BACKEND_API_URL
    }${ROUTES_NAME.received_last_ten_days(shopId,storeType)}`;

    await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((received) => {
        dispatch(receivedLastTenDaysSuccess(received));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(receivedLastTenDaysError());
      });
  };
};

function receivedLastTenDaysRequest(receivedData) {
  return {
    type: RECEIVED_LAST_TEN_DAYS_REQUEST,
    payload: receivedData,
  };
}

function receivedLastTenDaysSuccess(receivedData) {
  return {
    type: RECEIVED_LAST_TEN_DAYS_SUCCESS,
    payload: receivedData,
  };
}

function receivedLastTenDaysError(receivedData) {
  return {
    type: RECEIVED_LAST_TEN_DAYS_ERROR,
    payload: receivedData,
  };
}

//LINKED PAYPAL ACCOUNT

export const getLinkedPaypalShop = (shopId, storeType) => {
  return async (dispatch) => {
    dispatch(linkedPaypalByShopRequest());
    const url = `${
      process.env.REACT_APP_BACKEND_API_URL
    }${ROUTES_NAME.linked_paypal_by_shop(shopId,storeType)}`;

    await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((paypal) => {
        dispatch(linkedPaypalByShopSuccess(paypal));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(linkedPaypalByShopError());
      });
  };
};

function linkedPaypalByShopRequest(receivedData) {
  return {
    type: LINKED_PAYPAL_REQUEST,
    payload: receivedData,
  };
}

function linkedPaypalByShopSuccess(receivedData) {
  return {
    type: LINKED_PAYPAL_SUCCESS,
    payload: receivedData,
  };
}

function linkedPaypalByShopError(receivedData) {
  return {
    type: LINKED_PAYPAL_ERROR,
    payload: receivedData,
  };
}

// DASHBOARD-ALL API-CALL

export const getDashboardAllData = (shopId, storeType) => {
  return async (dispatch) => {
    dispatch(dashboardAllRequest());
    const url = `${
      process.env.REACT_APP_BACKEND_API_URL
    }${ROUTES_NAME.dashboard_all(shopId,storeType)}`;

    await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch(dashboardAllSuccess(data));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(dashboardAllError());
      });
  };
};

function dashboardAllRequest(receivedData) {
  return {
    type: DASHBOARD_ALL_REQUEST,
    payload: receivedData,
  };
}

function dashboardAllSuccess(receivedData) {
  return {
    type: DASHBOARD_ALL_SUCCESS,
    payload: receivedData,
  };
}

function dashboardAllError(receivedData) {
  return {
    type: DASHBOARD_ALL_ERROR,
    payload: receivedData,
  };
}

//FAILED API CALL

export const getFailedData = (shopId,storeType) => {
  return async (dispatch) => {
    dispatch(failedRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.failed(
      shopId,storeType
    )}`;

    await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch(failedSuccess(data));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(failedError());
      });
  };
};

function failedRequest(receivedData) {
  return {
    type: FAILED_REQUEST,
    payload: receivedData,
  };
}

function failedSuccess(receivedData) {
  return {
    type: FAILED_SUCCESS,
    payload: receivedData,
  };
}

function failedError(receivedData) {
  return {
    type: FAILED_ERROR,
    payload: receivedData,
  };
}

//mISSING API CALL

export const getMissingData = (shopId,storeType) => {
  return async (dispatch) => {
    dispatch(missingRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.missing(
      shopId,storeType
    )}`;
    await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch(missingSuccess(data));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(missingError());
      });
  };
};

function missingRequest(receivedData) {
  return {
    type: MISSING_REQUEST,
    payload: receivedData,
  };
}

function missingSuccess(receivedData) {
  return {
    type: MISSING_SUCCESS,
    payload: receivedData,
  };
}

function missingError(receivedData) {
  return {
    type: MISSING_ERROR,
    payload: receivedData,
  };
}

// queued API CALL

export const getQueuedData = (shopId,storeType) => {
  return async (dispatch) => {
    dispatch(queuedRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.queued(
      shopId,storeType
    )}`;

    await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch(queuedSuccess(data));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(queuedError());
      });
  };
};

function queuedRequest(receivedData) {
  return {
    type: QUEUED_REQUEST,
    payload: receivedData,
  };
}

function queuedSuccess(receivedData) {
  return {
    type: QUEUED_SUCCESS,
    payload: receivedData,
  };
}

function queuedError(receivedData) {
  return {
    type: QUEUED_ERROR,
    payload: receivedData,
  };
}

// PENDIND API CALL

export const getPendingData = (shopId, storeType) => {
  return async (dispatch) => {
    dispatch(pendingRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.pending(
      shopId, storeType
    )}`;

    await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch(pendingSuccess(data));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(pendingError());
      });
  };
};

function pendingRequest(receivedData) {
  return {
    type: PENDING_REQUEST,
    payload: receivedData,
  };
}

function pendingSuccess(receivedData) {
  return {
    type: PENDING_SUCCESS,
    payload: receivedData,
  };
}

function pendingError(receivedData) {
  return {
    type: PENDING_ERROR,
    payload: receivedData,
  };
}

// Advance Search API call for the dashboard and orders table

export const getAdvanceSearchData = (payload) => {
  const { page, ordersStatus, startDate, endDate, search, shopId, storeType } = payload;
  return async (dispatch) => {
    dispatch(advanceSearchRequest());
    const url = `${
      process.env.REACT_APP_BACKEND_API_URL
    }${ROUTES_NAME.advanceSearch(shopId,storeType)}`;

    await fetch(url, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        pageNumber: page,
        length: 10,
        itemCriteria: {
          orderStatus: ordersStatus,
          startDate: startDate,
          endDate: endDate,
          search: search,
        },
      }),
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch(advanceSearchSuccess(data));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(advanceSearchError());
      });
  };
};

function advanceSearchRequest(receivedData) {
  return {
    type: ADVANCE_SEARCH_REQUEST,
    payload: receivedData,
  };
}

function advanceSearchSuccess(receivedData) {
  return {
    type: ADVANCE_SEARCH_SUCCESS,
    payload: receivedData,
  };
}

function advanceSearchError(receivedData) {
  return {
    type: ADVANCE_SEARCH_ERROR,
    payload: receivedData,
  };
}

//INVALID GATEWAY API CALL

export const invalidGatewayData = (shopId,storeType) => {
  return async (dispatch) => {
    dispatch(invalidRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.invalid_gateway(
      shopId,storeType
    )}`;
    await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch(invalidSuccess(data));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(invalidError());
      });
  };
};

function invalidRequest(receivedData) {
  return {
    type: INVALID_GATEWAY_REQUEST,
    payload: receivedData,
  };
}

function invalidSuccess(receivedData) {
  return {
    type: INVALID_GATEWAY_SUCCESS,
    payload: receivedData,
  };
}

function invalidError(receivedData) {
  return {
    type: INVALID_GATEWAY_ERROR,
    payload: receivedData,
  };
}
