import {
  IndexTable,
  Layout,
  LegacyCard,
  EmptySearchResult,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { processesApi } from "../../../redux/troubleshooting/processes/Action";
import { useNavigate, useParams } from "react-router-dom";
import DatePickerComponent from "../../../components/datepicker/DatePickerComponent";
import moment from "moment";
import { HistoricalSkeletonTable } from "../../../components/skeletonLoader";
import { historicalOrdersQueue } from "../../../redux/store/historicalOrders/Action";

const Processes = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [processes, setProcesses] = useState([]);

  const dispatch = useDispatch();
  const processesData = useSelector((store) => store.requiredProcessesData);

  const { queue } = useSelector((store) => store.requiredHistoricalData);

  useEffect(() => {
    const payload = {
      storeId: id,
      page: 0,
    };
    dispatch(processesApi(payload));
  }, []);
  useEffect(() => {
    if (processesData.data) {
      setProcesses(processesData?.data?.content);
    }
    if (processesData?.data?.message === "Actual error message") {
      navigate("/logout");
    }
  }, [processesData]);

  useEffect(() => {
    if (queue.isSuccess) {
      const payload = {
        storeId: id,
        page: 0,
      };
      dispatch(processesApi(payload));
    }
    if (queue?.data?.message === "Actual error message") {
      navigate("/logout");
    }
  }, [queue]);


  const resourceName = {
    singular: "process",
    plural: "process",
  };

  const emptyStateMarkup = (
    <EmptySearchResult
      title={"No  process found"}
      description={""}
      withIllustration
    />
  );

  function formatDateTime(datetime) {
    try {
      const date = moment(datetime);
      const formattedDate = date.format("MMM DD, YYYY");
      return formattedDate;
    } catch (err) {
      return "";
    }
  }

  const rowMarkup = processes?.map(
    (
      {
        storeId,
        backInTimeIdentifier,
        status,
        fromDate,
        endDate,
        total,
        pending,
        backInTimeType
      },
      index
    ) => (
      <IndexTable.Row id={storeId} key={storeId} position={index}>
        <IndexTable.Cell>
          <p style={{ padding: "10px" }}>{backInTimeIdentifier}</p>{" "}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <p style={{ padding: "10px" }}>{backInTimeType}</p>{" "}
        </IndexTable.Cell>
        <IndexTable.Cell>{status}</IndexTable.Cell>
        <IndexTable.Cell>
          {fromDate ? formatDateTime(fromDate) : ""}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {endDate ? formatDateTime(endDate) : ""}
        </IndexTable.Cell>
        <IndexTable.Cell>{total ? total : "" }</IndexTable.Cell>
        <IndexTable.Cell>{pending ? pending : ""}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const backInTime = (
    <span className="d-flex gap-10">
      <span>Back in time ID</span>
    </span>
  );
  const type = (
    <span className="d-flex gap-10">
      <span>Type</span>
    </span>
  );
  const status = (
    <span className="d-flex gap-10">
      <span>Status</span>
    </span>
  );
  const fromDate = (
    <span className="d-flex gap-10">
      <span>From Date</span>
    </span>
  );
  const endDate = (
    <span className="d-flex gap-10">
      <span>End Date</span>
    </span>
  );
  const total = (
    <span className="d-flex gap-10">
      <span>Total</span>
    </span>
  );
  const pending = (
    <span className="d-flex gap-10">
      <span>Pending</span>
    </span>
  );

  const handleHistoricalOrdersQueue = (date, ordersCount) => {
    const payload = {
      id: id,
      date: date,
      type:"SUPPORT",
      ordersCount: ordersCount,
    };
    dispatch(historicalOrdersQueue(payload));
  };

  return (
    <>
      <Layout.Section>
          <div className="wrapper">
            <LegacyCard sectioned>
              <p className="card_title" style={{ padding: "15px" }}>
                Back in time support
              </p>
              <div
                className="d-flex gap-10"
                style={{ alignItems: "center", padding: "15px" }}
              >
                <div>
                  <DatePickerComponent
                    handleHistoricalOrdersQueue={handleHistoricalOrdersQueue}
                  />
                </div>
              </div>
            </LegacyCard>
          </div>
      </Layout.Section>
      <Layout.Section>
        <div className="wrapper">
          <LegacyCard sectioned>
            <p className="card_title" style={{ padding: "15px" }}>
              Processes
            </p>
            <LegacyCard sectioned>
              {processesData.isRequest ? (
                <HistoricalSkeletonTable />
              ) : (
                <IndexTable
                  resourceName={resourceName}
                  itemCount={processes?.length || 0}
                  emptyState={emptyStateMarkup}
                  headings={[
                    { id: "1", title: backInTime },
                    { id: "7", title: type },
                    { id: "2", title: status },
                    { id: "3", title: fromDate },
                    { id: "4", title: endDate },
                    { id: "5", title: total },
                    { id: "6", title: pending },
                  ]}
                  selectable={false}
                >
                  {rowMarkup}
                </IndexTable>
              )}
            </LegacyCard>
          </LegacyCard>
        </div>
      </Layout.Section>
    </>
  );
};

export default Processes;
