import React, { useCallback, useState, useEffect } from "react";
import Header from "../../components/header";
import {
  Button,
  Divider,
  Layout,
  LegacyCard,
  Select,
  TextField,
  Pagination,
} from "@shopify/polaris";
import StoreTable from "./table";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { shopCountriesApi } from "../../redux/shopCountries/Action";
import { getAdvanceSearchStoreData } from "../../redux/orders/Action";
import RangePicker from "../../components/datepicker/DateRangePicker";

const Store = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [textFieldValue, setTextFieldValue] = useState("");
  const [selected, setSelected] = useState("");
  const [country, setCountry] = useState("");
  const [orders, setOrders] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState();
  const [displayLength, setDisplayLenght] = useState(10);
  const [plan, setPlan] = useState("");
  const [storeType, setStoreType] = useState("All")

  const handleTextFieldChange = useCallback(
    (value) => setTextFieldValue(value),
    []
  );
  const handleplanChange = useCallback((value) => setPlan(value), []);
  const advanceSearch = useSelector(
    (store) => store.requriedAdvanceSearchStoreTable
  );

  const shopCountries = useSelector((store) => store.requiredShopCountriesData);

  useEffect(() => {
    const payload = {
      page: 0,
      pageLength: parseInt(displayLength),
      name: null,
      country: null,
      chargesStatus: null,
      startDate: null,
      endDate: null,
      plan: null,
      storeType: null
    };
    dispatch(getAdvanceSearchStoreData(payload));
    dispatch(shopCountriesApi());
  }, []);

  useEffect(() => {
    if (
      advanceSearch.advanceSearch !== undefined &&
      advanceSearch.advanceSearch.content !== undefined
    ) {
      setOrders(advanceSearch.advanceSearch?.content);
      setPageCount(advanceSearch?.advanceSearch?.totalPages);
      setCountryList(shopCountries?.data);
    }
    if(advanceSearch?.advanceSearch?.message === "Actual error message" ||
      shopCountries?.data?.message === "Actual error message"){
      navigate('/logout')
    }
  }, [advanceSearch, shopCountries]);

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const options = [
    { label: "All", value: "All" },
    { label: "Active", value: "Active" },
    { label: "Declined", value: "Declined" },
    { label: "Frozen", value: "Frozen" },
    { label: "Canceled", value: "Canceled" },
  ];

  const displayLenghtOptions = [
    { label: "10", value: "10" },
    { label: "25", value: "25" },
    { label: "50", value: "50" },
    { label: "100", value: "100" },
  ];

  const listofIntegration = [
    { label: "All", value: "All" },
    { label: "Shopify", value: "Shopify" },
    { label: "Wix", value: "Wix" },
    { label: "Ecwid", value: "Ecwid" },
    { label: "BigCommerce", value: "Bigcommerce" },
  ]

  const planOptions = [
    { label: "All", value: "All" },
    {
      label: "Basic",
      value: "Basic",
    },
    { label: "Premium", value: "Premium" },
    { label: "Plus", value: "Plus" },
  ];
  const handleCountryChange = useCallback((country) => setCountry(country));
  const handleDisplayLenght = useCallback(
    (value) => setDisplayLenght(value),
    []
  );
  const handleIntegrationType = useCallback(
    (value) => setStoreType(value),
    []
  );

  const countryListOption = [{ label: "All", value: "All" }];
  if (countryList?.length > 0) {
    countryList.forEach((i) => {
      if (i != null) {
        const countryData = {
          label: i.countryName,
          value: i.countryCode,
        };
        countryListOption.push(countryData);
      }
    });
  }

  const handleOrdersFilter = () => { };

  const handleTable = () => {
    const payload = {
      name: textFieldValue === "" ? null : textFieldValue,
      country: country === "" || country === "All" ? null : country,
      chargesStatus: selected === "" || selected === "All" ? null : selected,
      startDate: sessionStorage.getItem("startDate"),
      endDate: sessionStorage.getItem("endDate"),
      page: 0,
      pageLength: parseInt(displayLength),
      plan: plan === "" || plan === "All" ? null : plan,
      storeType: storeType === "" || storeType === "All" ? null : storeType,
    };
    dispatch(getAdvanceSearchStoreData(payload));
    setCurrentPage(0);
  };
  const handlePreviousPagination = () => {
    if (currentPage === 0) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
      const payload = {
        name: textFieldValue === "" ? null : textFieldValue,
        country: country === "" || country === "All" ? null : country,
        chargesStatus: selected === "" || selected === "All" ? null : selected,
        startDate: sessionStorage.getItem("startDate"),
        endDate: sessionStorage.getItem("endDate"),
        page: currentPage - 1,
        pageLength: parseInt(displayLength),
        plan: plan === "" ? null : plan,
        storeType: storeType === "" || storeType === "All" ? null : storeType,
      };
      dispatch(getAdvanceSearchStoreData(payload));
    }
  };

  const handleNextPagination = () => {
    if (currentPage === pageCount - 1) {
      return; 
    } else {
      setCurrentPage(currentPage + 1);
      const payload = {
        name: textFieldValue === "" ? null : textFieldValue,
        country: country === "" || country === "All" ? null : country,
        chargesStatus: selected === "" || selected === "All" ? null : selected,
        startDate: sessionStorage.getItem("startDate"),
        endDate: sessionStorage.getItem("endDate"),
        page: currentPage + 1,
        pageLength: parseInt(displayLength),
        plan: plan === "" ? null : plan,
        storeType: storeType === "" || storeType === "All" ? null : storeType,
      };
      dispatch(getAdvanceSearchStoreData(payload));
    }
  };

  const handleKeyPress = (event) => {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed) {
      event.preventDefault();
      handleTable();
    }
  }
  return (
    <Layout>
      <Header title="Stores" />
      <Layout.Section>
        <div className="wrapper">
          <LegacyCard sectioned>
            <div
              className="d-flex flex-wrap gap-10 mobileviewgap"
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Select
                label="Number of entries"
                options={displayLenghtOptions}
                onChange={handleDisplayLenght}
                value={displayLength}
              />

              <div data-testid='apply-store-test-id'>
                <Select
                  label="Integration type"
                  options={listofIntegration}
                  onChange={handleIntegrationType}
                  value={storeType}
                />
              </div>

              <div onKeyDown={handleKeyPress}>
                <TextField
                  label="Search Text"
                  value={textFieldValue}
                  onChange={handleTextFieldChange}
                  placeholder="Type text"
                  autoComplete="off"
                />
              </div>
              <Select
                label="Country"
                options={countryListOption}
                onChange={handleCountryChange}
                value={country}
                placeholder="Select your country"
              />
              <Select
                label="Charges status"
                options={options}
                onChange={handleSelectChange}
                value={selected}
                placeholder="Select charge status"
              />
              <Select
                label="Select Plan"
                options={planOptions}
                onChange={handleplanChange}
                value={plan}
                placeholder="Plan"
              />
              <div>
                <span>Occoured at</span>
                <RangePicker handleOrdersFilter={handleOrdersFilter} />
              </div>
              <div style={{ marginTop: "20px" }} onClick={() => {
                handleTable()
              }}>
                <Button
                  loading={advanceSearch.isRequest}
                  primary
                >
                  Apply
                </Button>
              </div>
            </div>
            <StoreTable loading={advanceSearch.isRequest} orders={orders} />
            <Divider />
            <div
              className="paginationContainer"
              style={{ display: pageCount > 1 ? "flex" : "none" }}
            >
              <Pagination
                label={currentPage + 1}
                hasPrevious
                onPrevious={() => {
                  handlePreviousPagination();
                }}
                hasNext
                onNext={() => {
                  handleNextPagination();
                }}
              />
            </div>
          </LegacyCard>
        </div>
      </Layout.Section>
    </Layout>
  );
};

export default Store;
