export const NETWORTH_REQUEST = "NETWORTH_REQUEST";
export const NETWORTH_SUCCESS = "NETWORTH_SUCCESS ";
export const NETWORTH_ERROR = "NETWORTH_ERROR";

export const SYNCED_REQUEST = "SYNCED_REQUEST";
export const SYNCED_SUCCESS = "SYNCED_SUCCESS ";
export const SYNCED_ERROR = "SYNCED_ERROR";

export const SYNCED_LAST_TEN_DAYS_REQUEST = "SYNCED_LAST_TEN_DAYS_REQUEST";
export const SYNCED_LAST_TEN_DAYS_SUCCESS = "SYNCED_LAST_TEN_DAYS_SUCCESS ";
export const SYNCED_LAST_TEN_DAYS_ERROR = "SYNCED_LAST_TEN_DAYS_ERROR";

export const RECEIVED_LAST_TEN_DAYS_REQUEST = "RECEIVED_LAST_TEN_DAYS_REQUEST";
export const RECEIVED_LAST_TEN_DAYS_SUCCESS = "RECEIVED_LAST_TEN_DAYS_SUCCESS ";
export const RECEIVED_LAST_TEN_DAYS_ERROR = "RECEIVED_LAST_TEN_DAYS_ERROR";

export const LINKED_PAYPAL_REQUEST = "LINKED_PAYPAL_REQUEST";
export const LINKED_PAYPAL_SUCCESS = "LINKED_PAYPAL_SUCCESS ";
export const LINKED_PAYPAL_ERROR = "LINKED_PAYPAL_ERROR";

export const DASHBOARD_ALL_REQUEST = "DASHBOARD_ALL_REQUEST";
export const DASHBOARD_ALL_SUCCESS = "DASHBOARD_ALL_SUCCESS ";
export const DASHBOARD_ALL_ERROR = "DASHBOARD_ALL_ERROR";

export const FAILED_REQUEST = "FAILED_REQUEST";
export const FAILED_SUCCESS = "FAILED_SUCCESS ";
export const FAILED_ERROR = "FAILED_ERROR";

export const MISSING_REQUEST = "MISSING_REQUEST";
export const MISSING_SUCCESS = "MISSING_SUCCESS ";
export const MISSING_ERROR = "MISSING_ERROR";

export const QUEUED_REQUEST = "QUEUED_REQUEST";
export const QUEUED_SUCCESS = "QUEUED_SUCCESS ";
export const QUEUED_ERROR = "QUEUED_ERROR";

export const PENDING_REQUEST = "PENDING_REQUEST";
export const PENDING_SUCCESS = "PENDING_SUCCESS ";
export const PENDING_ERROR = "PENDING_ERROR";

export const ADVANCE_SEARCH_REQUEST = "ADVANCE_SEARCH_REQUEST";
export const ADVANCE_SEARCH_SUCCESS = "ADVANCE_SEARCH_SUCCESS ";
export const ADVANCE_SEARCH_ERROR = "ADVANCE_SEARCH_ERROR";


export const INVALID_GATEWAY_REQUEST = "INVALID_GATEWAY_REQUEST";
export const INVALID_GATEWAY_SUCCESS = "INVALID_GATEWAY_SUCCESS ";
export const INVALID_GATEWAY_ERROR = "INVALID_GATEWAY_ERROR";