import { ROUTES_NAME } from "../../config/constants";
import { ACCESS_TOKEN } from "../../constants";
import {
  DASHBOARD_AWAITINGSYNC_ERROR,
  DASHBOARD_AWAITINGSYNC_REQUEST,
  DASHBOARD_AWAITINGSYNC_SUCCESS,
  DASHBOARD_CARDS_ERROR,
  DASHBOARD_CARDS_REQUEST,
  DASHBOARD_CARDS_SUCCESS,
  DASHBOARD_PROFILE_ERROR,
  DASHBOARD_PROFILE_REQUEST,
  DASHBOARD_PROFILE_SUCCESS,
  TOTAL_ORDERS_ACTIVE_ERROR,
  TOTAL_ORDERS_ACTIVE_REQUEST,
  TOTAL_ORDERS_ACTIVE_SUCCESS,
  TOTAL_ORDERS_ERROR,
  TOTAL_ORDERS_REQUEST,
  TOTAL_ORDERS_SUCCESS,
  TOTAL_PAYPAL_UPLOADS_ERROR,
  TOTAL_PAYPAL_UPLOADS_REQUEST,
  TOTAL_PAYPAL_UPLOADS_SUCCESS,
  TOTAL_STORES_ERROR,
  TOTAL_STORES_REQUEST,
  TOTAL_STORES_SUCCESS,
  TOTAL_SYNCED_LASTSEVENDAYS_ERROR,
  TOTAL_SYNCED_LASTSEVENDAYS_REQUEST,
  TOTAL_SYNCED_LASTSEVENDAYS_SUCCESS,
  TOTAL_SYNCED_LASTTHIRTYDAYS_ERROR,
  TOTAL_SYNCED_LASTTHIRTYDAYS_REQUEST,
  TOTAL_SYNCED_LASTTHIRTYDAYS_SUCCESS,
  TOTAL_SYNCED_LASTTWODAYS_ERROR,
  TOTAL_SYNCED_LASTTWODAYS_REQUEST,
  TOTAL_SYNCED_LASTTWODAYS_SUCCESS,
  TOTAL_SYNCED_TODAY_ERROR,
  TOTAL_SYNCED_TODAY_REQUEST,
  TOTAL_SYNCED_TODAY_SUCCESS,
} from "./ActionType";

export const getDashboardTotalSyncedYesterday = () => {
  return (dispatch) => {
    dispatch(dashboardRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.dashboardTotalSyncedYesterday}`;

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((dashboardDetails) => {
        dispatch(dashboardSuccess(dashboardDetails));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(dashboardError());
      });
  };
};

function dashboardRequest(dashboardData) {
  return {
    type: DASHBOARD_CARDS_REQUEST,
    payload: dashboardData,
  };
}
function dashboardSuccess(dashboardData) {
  return {
    type: DASHBOARD_CARDS_SUCCESS,
    payload: dashboardData,
  };
}
function dashboardError(dashboardData) {
  return {
    type: DASHBOARD_CARDS_ERROR,
    payload: dashboardData,
  };
}

//Total synced Today

export const getDashboardTotalSyncedToday = () => {
  return (dispatch) => {
    dispatch(TotalSyncRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.dashboardTotalSyncedToday}`;

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((dashboardDetails) => {
        dispatch(TotalSyncSuccess(dashboardDetails));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(TotalSyncError());
      });
  };
};

function TotalSyncRequest(dashboardData) {
  return {
    type: TOTAL_SYNCED_TODAY_REQUEST,
    payload: dashboardData,
  };
}
function TotalSyncSuccess(dashboardData) {
  return {
    type: TOTAL_SYNCED_TODAY_SUCCESS,
    payload: dashboardData,
  };
}
function TotalSyncError(dashboardData) {
  return {
    type: TOTAL_SYNCED_TODAY_ERROR,
    payload: dashboardData,
  };
}

// total sync last two days

export const getDashboardTotalSyncedLastTwoDays = () => {
  return (dispatch) => {
    dispatch(TotalSyncLastTwoDaysRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.dashboardTotalSyncedLastTwoDays}`;

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((dashboardDetails) => {
        dispatch(TotalSyncLastTwoDaysSuccess(dashboardDetails));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(TotalSyncLastTwoDaysError());
      });
  };
};

function TotalSyncLastTwoDaysRequest(dashboardData) {
  return {
    type: TOTAL_SYNCED_LASTTWODAYS_REQUEST,
    payload: dashboardData,
  };
}
function TotalSyncLastTwoDaysSuccess(dashboardData) {
  return {
    type: TOTAL_SYNCED_LASTTWODAYS_SUCCESS,
    payload: dashboardData,
  };
}
function TotalSyncLastTwoDaysError(dashboardData) {
  return {
    type: TOTAL_SYNCED_LASTTWODAYS_ERROR,
    payload: dashboardData,
  };
}

// total sync last thirty days

export const getDashboardTotalSyncedLastThirtyDays = () => {
  return (dispatch) => {
    dispatch(TotalSyncLastThirtyDaysRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.dashboardTotalSyncedLastThirtyDays}`;

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((dashboardDetails) => {
        dispatch(TotalSyncLastThirtyDaysSuccess(dashboardDetails));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(TotalSyncLastThirtyDaysError());
      });
  };
};

function TotalSyncLastThirtyDaysRequest(dashboardData) {
  return {
    type: TOTAL_SYNCED_LASTTHIRTYDAYS_REQUEST,
    payload: dashboardData,
  };
}
function TotalSyncLastThirtyDaysSuccess(dashboardData) {
  return {
    type: TOTAL_SYNCED_LASTTHIRTYDAYS_SUCCESS,
    payload: dashboardData,
  };
}
function TotalSyncLastThirtyDaysError(dashboardData) {
  return {
    type: TOTAL_SYNCED_LASTTHIRTYDAYS_ERROR,
    payload: dashboardData,
  };
}

//last Seven days

export const getDashboardTotalSyncedLastSevenDays = () => {
  return (dispatch) => {
    dispatch(TotalSyncLastSevenDaysRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.dashboardTotalSyncedLastSevenDays}`;

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((dashboardDetails) => {
        dispatch(TotalSyncLastSevenDaysSuccess(dashboardDetails));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(TotalSyncLastSevenDaysError());
      });
  };
};

function TotalSyncLastSevenDaysRequest(dashboardData) {
  return {
    type: TOTAL_SYNCED_LASTSEVENDAYS_REQUEST,
    payload: dashboardData,
  };
}
function TotalSyncLastSevenDaysSuccess(dashboardData) {
  return {
    type: TOTAL_SYNCED_LASTSEVENDAYS_SUCCESS,
    payload: dashboardData,
  };
}
function TotalSyncLastSevenDaysError(dashboardData) {
  return {
    type: TOTAL_SYNCED_LASTSEVENDAYS_ERROR,
    payload: dashboardData,
  };
}

//total stores

export const getDashboardTotalStores = () => {
  return (dispatch) => {
    dispatch(dashboardTotalStoresRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.dashboardTotalStores}`;

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((dashboardDetails) => {
        dispatch(dashboardTotalStoresSuccess(dashboardDetails));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(dashboardTotalStoresError());
      });
  };
};

function dashboardTotalStoresRequest(dashboardData) {
  return {
    type: TOTAL_STORES_REQUEST,
    payload: dashboardData,
  };
}
function dashboardTotalStoresSuccess(dashboardData) {
  return {
    type: TOTAL_STORES_SUCCESS,
    payload: dashboardData,
  };
}
function dashboardTotalStoresError(dashboardData) {
  return {
    type: TOTAL_STORES_ERROR,
    payload: dashboardData,
  };
}

// total paypal uploads

export const getdashboardTotalPaypalUploads= () => {
  return (dispatch) => {
    dispatch(dashboardTotalPaypalUploadsRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.dashboardTotalPaypalUploads}`;

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((dashboardDetails) => {
        dispatch(dashboardTotalPaypalUploadsSuccess(dashboardDetails));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(dashboardTotalPaypalUploadsError());
      });
  };
};

function dashboardTotalPaypalUploadsRequest(dashboardData) {
  return {
    type: TOTAL_PAYPAL_UPLOADS_REQUEST,
    payload: dashboardData,
  };
}
function dashboardTotalPaypalUploadsSuccess(dashboardData) {
  return {
    type: TOTAL_PAYPAL_UPLOADS_SUCCESS,
    payload: dashboardData,
  };
}
function dashboardTotalPaypalUploadsError(dashboardData) {
  return {
    type: TOTAL_PAYPAL_UPLOADS_ERROR,
    payload: dashboardData,
  };
}

//total orders


export const getdashboardTotalOrders = () => {
  return (dispatch) => {
    dispatch(dashboardTotalOrdersRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.dashboardTotalOrders}`;

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((dashboardDetails) => {
        dispatch(dashboardTotalOrdersSuccess(dashboardDetails));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(dashboardTotalOrdersError());
      });
  };
};

function dashboardTotalOrdersRequest(dashboardData) {
  return {
    type: TOTAL_ORDERS_REQUEST,
    payload: dashboardData,
  };
}
function dashboardTotalOrdersSuccess(dashboardData) {
  return {
    type: TOTAL_ORDERS_SUCCESS,
    payload: dashboardData,
  };
}
function dashboardTotalOrdersError(dashboardData) {
  return {
    type: TOTAL_ORDERS_ERROR,
    payload: dashboardData,
  };
}

// total orders active

export const getdashboardTotalActive = () => {
  return (dispatch) => {
    dispatch(dashboardTotalActiveRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.dashboardTotalActive}`;

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((dashboardDetails) => {
        dispatch(dashboardTotalActiveSuccess(dashboardDetails));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(dashboardTotalActiveError());
      });
  };
};

function dashboardTotalActiveRequest(dashboardData) {
  return {
    type: TOTAL_ORDERS_ACTIVE_REQUEST,
    payload: dashboardData,
  };
}
function dashboardTotalActiveSuccess(dashboardData) {
  return {
    type: TOTAL_ORDERS_ACTIVE_SUCCESS,
    payload: dashboardData,
  };
}
function dashboardTotalActiveError(dashboardData) {
  return {
    type: TOTAL_ORDERS_ACTIVE_ERROR,
    payload: dashboardData,
  };
}

// dashboard profile 

export const getdashboardprofile = () => {
  return (dispatch) => {
    dispatch(dashboardprofileRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.dashboardprofile}`;

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((dashboardDetails) => {
        dispatch(dashboardprofileSuccess(dashboardDetails));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(dashboardprofileError());
      });
  };
};

function dashboardprofileRequest(dashboardData) {
  return {
    type: DASHBOARD_PROFILE_REQUEST,
    payload: dashboardData,
  };
}
function dashboardprofileSuccess(dashboardData) {
  return {
    type: DASHBOARD_PROFILE_SUCCESS,
    payload: dashboardData,
  };
}
function dashboardprofileError(dashboardData) {
  return {
    type: DASHBOARD_PROFILE_ERROR,
    payload: dashboardData,
  };
}

//AWASITING SYNC

export const getdashboardAwaitingSync = () => {
  return (dispatch) => {
    dispatch(dashboardAwaitingSyncRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.dashboardAwaitingSync}`;

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((dashboardDetails) => {
        dispatch(dashboardAwaitingSyncSuccess(dashboardDetails));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(dashboardAwaitingSyncError());
      });
  };
};

function dashboardAwaitingSyncRequest(dashboardData) {
  return {
    type: DASHBOARD_AWAITINGSYNC_REQUEST,
    payload: dashboardData,
  };
}
function dashboardAwaitingSyncSuccess(dashboardData) {
  return {
    type: DASHBOARD_AWAITINGSYNC_SUCCESS,
    payload: dashboardData,
  };
}
function dashboardAwaitingSyncError(dashboardData) {
  return {
    type: DASHBOARD_AWAITINGSYNC_ERROR,
    payload: dashboardData,
  };
}