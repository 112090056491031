import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {Button, ButtonGroup, InlineError, TextField} from "@shopify/polaris";

export function OrderCheckerForm({
  enabled,
  loading,
  error,
  dataReady,
  showDetails,
  onSubmit,
  onShowDetails,
}) {
  const ref = useRef(null);
  const [searchParams] = useSearchParams();
  const linkOrderNum = useMemo(
    () => searchParams.get("order-checker"),
    [searchParams],
  );

  const [value, setValue] = useState(linkOrderNum ?? "");

  const handleSubmit = () => onSubmit(value);

  const handleKeyPress = (event) => {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed) {
      event.preventDefault();
      handleSubmit();
    }
  };

  useEffect(() => {
    if (linkOrderNum && enabled) {
      onSubmit(linkOrderNum);
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [linkOrderNum, enabled]);

  return (
    <div
      ref={ref}
      className="d-flex flex_wrap"
      style={{
        alignItems: "end",
        gap: "15px",
      }}
    >
      <div style={{ width: 212 }} onKeyDown={handleKeyPress}>
        <TextField
          label="Order number or ID"
          value={value}
          onChange={setValue}
          autoComplete="off"
          placeholder="e.g. 1508 or 6192321659218"
        />
        {error ? (
          <div style={{ position: "absolute" }}>
            <InlineError message={error} fieldID="myFieldID" />
          </div>
        ) : null}
      </div>

      <ButtonGroup>
        <Button
          primary
          disabled={!enabled}
          loading={loading}
          onClick={handleSubmit}
        >
          Check
        </Button>
        {dataReady && (
          <Button onClick={onShowDetails}>
            {showDetails ? "Hide details" : "Show details"}
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
}
