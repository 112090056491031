import React, { useState, useCallback } from "react";
import Header from "../../components/header";
import {
  Layout,
  LegacyCard,
  TextField,
  Button,
  Select,
} from "@shopify/polaris";
import ToggleButton from "../../components/toggleButton/ToggleButton";
const Setting = () => {
  const [visible, setVisible] = useState(false);
  const [KeyOne, setKeyOne] = useState("");
  const [KeyTwo, setKeyTwo] = useState("");
  const [KeyThree, setKeyThree] = useState("");
  const [selected, setSelected] = useState("today");
  const [message, setMessage] = useState("");

  const handleChange = useCallback((newValue) => {
  setKeyOne(newValue)
  setKeyTwo("");
  setKeyThree("");
  setMessage("");
}, []);
  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const options = [ 
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 7 days", value: "lastWeek" },
  ];

  const handleToggleAction = () => {}

  return (
    <>
      <Layout>
        <Header title="Carrier" />
        <Layout.Section>
          <div className="wrapper">
            <ToggleButton buttonOne="Smart notifications" buttonTwo="Global" handleToggleAction={handleToggleAction} setVisible={setVisible} />
          </div>
        </Layout.Section>
       { visible ?<Layout.Section>
          <div className="wrapper">
            <LegacyCard sectioned>
              <div className="d-flex align-item-end justify-content-between">
                <div style={{ width: "25%" }}>
                  <TextField 
                    label="Key 1"
                    value={KeyOne}
                    onChange={handleChange}
                    autoComplete="off"
                    placeholder="Add key 1"
                  />
                </div>

                <div style={{ width: "25%" }}>
                  <TextField
                    label="Key 2"
                    value={KeyTwo}
                    onChange={handleChange}
                    autoComplete="off"
                    placeholder="Add key 2"
                  />
                </div>
                <div style={{ width: "25%" }}>
                  <TextField
                    label="Key 3"
                    value={KeyThree}
                    onChange={handleChange}
                    autoComplete="off"
                    placeholder="Add key 3"
                  />
                </div>
                <div>
                  <Button primary>Save changes</Button>
                </div>
              </div>
            </LegacyCard>
          </div>
       </Layout.Section>
       :
        <Layout.Section>
          <div className="wrapper">
            <LegacyCard sectioned>
              <div
                className="d-flex flex-col"
                style={{ gap: "25px", width: "50%" }}
              >
                <Select
                  label="Type"
                  options={options}
                  onChange={handleSelectChange}
                  value={selected}
                />
                <TextField
                  label="Message"
                  value={message}
                  onChange={handleChange}
                  autoComplete="off"
                  placeholder="Add key 1"
                />
                <div>
                  <Button primary> Save changes</Button>
                </div>
              </div>
            </LegacyCard>
          </div>
        </Layout.Section>}
      </Layout>
    </>
  );
};

export default Setting;
