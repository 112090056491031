import { ROUTES_NAME } from "../../../config/constants";
import { ACCESS_TOKEN } from "../../../constants";
import { FULLFILLMENT_ERROR, FULLFILLMENT_REQUEST, FULLFILLMENT_SUCCESS } from "./ActionType";

export const getFullfillmentCheckerData = (payload) => {
  const {orderId, id, orderName} = payload;
    return (dispatch) => {
      dispatch(fullfillmentRequest());
      const url = `${
        process.env.REACT_APP_BACKEND_API_URL
      }${ROUTES_NAME.fullfillment()}`;
  
      fetch(url, {
        method: "POST",
        body: JSON.stringify({
          storeOrderId:orderId,
          storeId:id,
          orderName:orderName
          }),
        credentials: "include",
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
          "Content-Type": "application/json",
          Origin: `${process.env.REACT_APP_URL}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          dispatch(fullfillmentSuccess(res));
        })
        .catch((data) => {
          console.log(data, "error");
          dispatch(fullfillmentError());
        });
    };
  };
  
  function fullfillmentRequest(data) {
    return {
      type: FULLFILLMENT_REQUEST,
      payload: data,
    };
  }
  function fullfillmentSuccess(data) {
    return {
      type: FULLFILLMENT_SUCCESS,
      payload: data,
    };
  }
  function fullfillmentError(data) {
    return {
      type: FULLFILLMENT_ERROR,
      payload: data,
    };
  }
  