import {
  HISTORICAL_QUEUE_REQUEST,
  HISTORICAL_QUEUE_SUCCESS,
  HISTORICAL_QUEUE_ERROR,
  HISTORICAL_STATUS_SUCCESS,
  HISTORICAL_STATUS_ERROR,
  HISTORICAL_STATUS_REQUEST,
  HISTORICAL_TOKEN_REQUEST,
  HISTORICAL_TOKEN_SUCCESS,
  HISTORICAL_TOKEN_ERROR,
  HISTORICAL_TOTAL_POSSIBLE_ORDERS_REQUEST,
  HISTORICAL_TOTAL_POSSIBLE_ORDERS_TOKEN_SUCCESS,
  HISTORICAL_TOTAL_POSSIBLE_ORDERS_TOKEN_ERROR,
} from "./ActionType";
const initialState = {
  isRequest: false,
  isSuccess: false,
  isError: false,
  status: [],
  queue: {
    isRequest: false,
    isSuccess: false,
    isError: false,
    data: {},
  },
  token: {},
  possibleOrdersCount: {},
};

const historicalOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case HISTORICAL_STATUS_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        status: action.payload,
      };
    case HISTORICAL_STATUS_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        status: action.payload,
      };
    case HISTORICAL_STATUS_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        status: action.payload,
      };
    case HISTORICAL_QUEUE_REQUEST:
      return {
        ...state,
        queue: {
          ...state.queue,
          isRequest: true,
          isSuccess: false,
          isError: false,
          data: action.payload,
        },
      };
    case HISTORICAL_QUEUE_SUCCESS:
      return {
        ...state,
        queue: {
          ...state.queue,
          isRequest: false,
          isSuccess: true,
          isError: false,
          data: action.payload,
        },
      };
    case HISTORICAL_QUEUE_ERROR:
      return {
        ...state,

        queue: {
          ...state.queue,
          isRequest: false,
          isSuccess: false,
          isError: true,
          data: action.payload,
        },
      };
    case HISTORICAL_TOKEN_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        token: action.payload,
      };
    case HISTORICAL_TOKEN_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        token: action.payload,
      };
    case HISTORICAL_TOKEN_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        token: action.payload,
      };
    case HISTORICAL_TOTAL_POSSIBLE_ORDERS_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        possibleOrdersCount: action.payload,
      };
    case HISTORICAL_TOTAL_POSSIBLE_ORDERS_TOKEN_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        possibleOrdersCount: action.payload,
      };
    case HISTORICAL_TOTAL_POSSIBLE_ORDERS_TOKEN_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        possibleOrdersCount: action.payload,
      };
    default:
      return state;
  }
};

export default historicalOrdersReducer;
