import React from "react";
import "./style.css";
import { Button } from "@shopify/polaris";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
const Transcript = (props) => {
  return (
    <>
      <div className="transcript_title_container">
        <p className="transcript_title">Transcript</p>
        <div>
          {props.displayticket ? (
            <Button destructive>Submit ticket</Button>
          ) : null}
        </div>
      </div>
      <div className="transcript_container" style={{overflow:"scroll"}}>
        <JSONPretty id="json-pretty" data={props.transcript}></JSONPretty>
      </div>
    </>
  );
};

export default Transcript;
