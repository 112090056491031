export const ROUTES_NAME = {
  dashboard: "/dashboard",
  profile: "/dashboard/profile",
  dashboardTotalSyncedYesterday:"/dashboard/totalSyncedYesterday",
  dashboardTotalSyncedToday:"/dashboard/totalSyncedToday",
  dashboardTotalSyncedLastTwoDays:"/dashboard/totalSyncedLastTwoDays",
  dashboardTotalSyncedLastThirtyDays:"/dashboard/totalSyncedLastThirtyDays",
  dashboardTotalSyncedLastSevenDays:"/dashboard/totalSyncedLastSevenDays",
  dashboardTotalStores:"/dashboard/totalStores",
  dashboardTotalPaypalUploads:"/dashboard/totalPaypalUploads",
  dashboardTotalOrders:"/dashboard/totalOrders",
  dashboardTotalActive:"/dashboard/totalActive",
  dashboardprofile:"/dashboard/profile",
  dashboardAwaitingSync:"/dashboard/awaitingSync",
  table:"/stores/advanced-search",
  paypalSetting:"/paypal/settings",
  pullLogs:"/pull-logs",
  report: "/reports/performances",
  carrier:"/carriers",
  events:"/stores/events",
  find_detail:"/stores/find-detail",
  stats:"/stores/stats",
  processes:"/stores/processes",
  processes_queue:"/stores/processes/queue",
  shop_countries: `/stores/list-country`,
  all_countries: (storeType)=> `/store/routing-policy/all-countries?storeType=${storeType}`,
  networth:(shopId,storeType)=>`/store/dashboard/networth?shopId=${shopId}&storeType=${storeType}`,
  synced : (shopId,storeType) => `/store/dashboard/synced?shopId=${shopId}&storeType=${storeType}`,
  synced_last_ten_days: (shopId,storeType) => `/store/dashboard/synced-last-10days?shopId=${shopId}&storeType=${storeType}`,
  received_last_ten_days :(shopId,storeType) => `/store/dashboard/received-last-10days?shopId=${shopId}&storeType=${storeType}`,
  linked_paypal_by_shop: (shopId,storeType) => `/store/dashboard/linked-paypal-by-shop?shopId=${shopId}&storeType=${storeType}`,
  dashboard_all:(shopId,storeType) => `/store/dashboard/all?shopId=${shopId}&storeType=${storeType}`,
  failed:(shopId,storeType) => `/store/dashboard/failed?shopId=${shopId}&storeType=${storeType}`,
  missing : (shopId,storeType) => `/store/dashboard/missing?shopId=${shopId}&storeType=${storeType}`,
  queued:(shopId,storeType) => `/store/dashboard/queued?shopId=${shopId}&storeType=${storeType}`,
  pending: (shopId,storeType) => `/store/dashboard/pending?shopId=${shopId}&storeType=${storeType}`,
  invalid_gateway: (shopId,storeType) => `/store/dashboard/invalid-gateway?shopId=${shopId}&storeType=${storeType}`,
  advanceSearch : (shopId,storeType) => `/store/order/advanced-search?shopId=${shopId}&storeType=${storeType}`,
  resync: (shopId,storeType) => `/store/order/re-sync-orders?shopId=${shopId}&storeType=${storeType}`,
  historicalStatus : (shopId,storeType) => `/store/historical/status?shopId=${shopId}&storeType=${storeType}`,
  storeQueue : (id,storeType ) => `/store/historical/queue?shopId=${id}&storeType=${storeType}`,
  storeAllCarrier : (shopId,storeType) => `/store/routing-policy/all-carriers?shopId=${shopId}&storeType=${storeType}`,
  storeAllGeoCarrier : (shopId,storeType) => `/store/routing-policy/list-carriers-by-geo?shopId=${shopId}&storeType=${storeType}`,
  deleteCarrier : (shopId,storeType) => `/store/routing-policy/delete-paypal-carrier?shopId=${shopId}&storeType=${storeType}`,
  addCarrier : (shopId,storeType) => `/store/routing-policy/add-paypal-carrier?shopId=${shopId}&storeType=${storeType}`,
  historicalCount : (shopId,storeType) => `/store/historical/count?shopId=${shopId}&storeType=${storeType}`,
  paypal_setting_default : (payload) => `/store/paypal-settings/default?payPalSettingId=${payload}`,
  paypal_setting_delete : (payload) => `/store/paypal-settings/delete?payPalSettingId=${payload}`,
  digital_sync : (shopId,storeType) => `/store/setting/digital-sync?shopId=${shopId}&storeType=${storeType}`,
  courier_mapping : (shopId,storeType) => `/store/setting/courrier-mapping?shopId=${shopId}&storeType=${storeType}`,
  find_by_id : (shopId,storeType) => `/store/setting/find-by-id?shopId=${shopId}&storeType=${storeType}`,
  fullfillment :() => `/checker/fulfillment`,
  paypalChecker : ()=> `/checker/paypal`,
  fetch_by_name: "/checker/fetch-by-name",
  possible_orders: (shopId,storeType) => `/store/historical/order-count?shopId=${shopId}&storeType=${storeType}`,
  reindexing : (id, storeType) => `/reindex?shopId=${id}&storeType=${storeType}`,
  cancelDigitalOrder: (id, storeType) => `/reindex/cancel-digital?shopId=${id}&storeType=${storeType}`,
  cacheSync : (id, storeType) => `/cache/sync?shopId=${id}&storeType=${storeType}`,
  troubleshoot_totalOrders: (id,storeType) => `/stores/totalOrders?shopId=${id}&storeType=${storeType}`,
  troubleshoot_awaitingSync: (id, storeType) => `/stores/awaitingSync?shopId=${id}&storeType=${storeType}`,
  troubleshoot_totalSynced: (id, storeType) => `/stores/totalSynced?shopId=${id}&storeType=${storeType}`,
  gdpr: (gdprActionType, storeType, page, pageSize ) => `/gdpr?gdprActionType=${gdprActionType}&storeType=${storeType}&page=${page}&pageSize=${pageSize}`
};