import { RESYNC_ORDERS_ERROR, RESYNC_ORDERS_REQUEST, RESYNC_ORDERS_SUCCESS } from "./ActionType";
const initialState = {
    isRequest: false,
    isSuccess: false,
    isError: false,
    data: {},
  };

  const storeOrdersReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESYNC_ORDERS_REQUEST:
            return {
                isRequest: true,
                isSuccess: false,
                isError: false,
                data: action.payload,
              };
        case RESYNC_ORDERS_SUCCESS:
            return {
                isRequest: false,
                isSuccess: true,
                isError: false,
                data: action.payload,
              };
        case RESYNC_ORDERS_ERROR:
            return {
                isRequest: false,
                isSuccess: false,
                isError: true,
                data: action.payload,
              };
              default:
                return state;        
    }
  }

  export default storeOrdersReducer;