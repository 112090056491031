import {
  FULLFILLMENT_ERROR,
  FULLFILLMENT_REQUEST,
  FULLFILLMENT_SUCCESS,
} from "./ActionType";

const initialState = {
  isRequest: false,
  isSuccess: false,
  isError: false,
  fullfillment: {},
};

const fullfillmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FULLFILLMENT_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        fullfillment: action.payload,
      };
    case FULLFILLMENT_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        fullfillment: action.payload,
      };

    case FULLFILLMENT_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        fullfillment: action.payload,
      };
    default:
      return state;
  }
};

export default fullfillmentReducer;