import {
  Layout,
  LegacyCard,
  TextField,
  Button,
  Select, 
} from "@shopify/polaris";
import React, { useState, useCallback } from "react";

const PaypalAccountSettings = () => {
  const [selected, setSelected] = useState("today");
  const [discount, setDiscount] = useState();
  const [merchantID, setMerchnatID] = useState("");
  const [discountTime, setdiscountTime] = useState();
  const [subscribedTime, setSubscribedTime] = useState();

  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const handleSelectDiscount = useCallback((value) => setDiscount(value), []);
  const handleSelectTimeForDiscount = useCallback(
    (value) => setdiscountTime(value),
    []
  );
  const handleSelectTimeForSubscription = useCallback(
    (value) => setSubscribedTime(value),
    []
  );

  const handleMerchantID = useCallback(
    (newValue) => setMerchnatID(newValue),
    []
  );

  const options = [{ label: "Basic", value: "Basic" }];

  const discountOptions = [
    { label: "10", value: "10" },
    { label: "20", value: "20" },
    { label: "30", value: "30" },
  ];

  const timeOption = [
    { label: "10", value: "10" },
    { label: "20", value: "20" },
    { label: "30", value: "30" },
  ];
  return (
    <Layout.Section>
      <div className="wrapper">
          <LegacyCard sectioned>
            <p className="card_title" style={{ padding: "15px" }}>
              Account Settings
            </p>
            <div
              className="d-flex gap-10 flex-wrap"
              style={{ flexWrap: "wrap", justifyContent: "space-between" }}
            >
              <div className="accountSettingCard">
                <LegacyCard sectioned>
                  <div
                    style={{
                      width: "",
                      display: "flex",
                      flexDirection: "column",
                      gap: 20,
                    }}
                  >
                    <p className="card_title" style={{ fontSize: "14px" }}>
                      Subscription
                    </p>
                    <Select
                      label="Subscription"
                      options={options}
                      onChange={handleSelectChange}
                      value={selected}
                      placeholder="Select Subscription"
                    />
                    <Select
                      label="Select time"
                      options={timeOption}
                      onChange={handleSelectTimeForSubscription}
                      value={subscribedTime}
                      placeholder="Select time"
                    />
                    <div>
                      <Button primary>Save</Button>
                    </div>
                  </div>
                </LegacyCard>
              </div>
              <div className="accountSettingCard">
                <LegacyCard sectioned>
                  <div
                    style={{
                      width: "",
                      display: "flex",
                      flexDirection: "column",
                      gap: 20,
                    }}
                  >
                    <p className="card_title" style={{ fontSize: "14px" }}>
                      Discount
                    </p>
                    <Select
                      label="Select discount"
                      options={discountOptions}
                      onChange={handleSelectDiscount}
                      value={discount}
                      placeholder="Select discount"
                    />
                    <Select
                      label="Select time"
                      options={timeOption}
                      onChange={handleSelectTimeForDiscount}
                      value={discountTime}
                      placeholder="Select time"
                    />
                    <div>
                      <Button primary>Save</Button>
                    </div>
                  </div>
                </LegacyCard>
              </div>
              <div className="accountSettingCard">
                <LegacyCard sectioned>
                  <div
                    style={{
                      width: "",
                      display: "flex",
                      flexDirection: "column",
                      gap: 20,
                    }}
                  >
                    <p className="card_title" style={{ fontSize: "14px" }}>
                      Merchant ID
                    </p>
                    <TextField
                      value={merchantID}
                      onChange={handleMerchantID}
                      autoComplete="off"
                    />
                    <div>
                      <Button primary>Save</Button>
                    </div>
                  </div>
                </LegacyCard>
              </div>
            </div>
          </LegacyCard>
      </div>
    </Layout.Section>
  );
};

export default PaypalAccountSettings;
