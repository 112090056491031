import { React, useState } from "react";
import { Button, ButtonGroup, Layout } from "@shopify/polaris";
import { useSearchParams } from "react-router-dom";
import { OrderChecker } from "../segmentedTabs/OrderChecker";
import FullfilmentChecker from "../segmentedTabs/FullfilmentChecker";
import PaypalChecker from "../segmentedTabs/PaypalChecker";
import Processes from "../segmentedTabs/Processes";

const TABS = {
  fulfillmentChecker: "fulfillment-checker",
  paypalChecker: "paypal-checker",
  processes: "processes",
  orderChecker: "order-checker",
};

const SecondButtonSegment = () => {
  const [searchParams] = useSearchParams();
  const [tab, setTab] = useState(
    searchParams.get("order-checker") ? TABS.orderChecker : TABS.processes,
  );

  const renderTab = (tabName) => {
    switch (tabName) {
      case TABS.fulfillmentChecker:
        return <FullfilmentChecker />;

      case TABS.paypalChecker:
        return <PaypalChecker />;

      case TABS.processes:
        return <Processes />;

      case TABS.orderChecker:
        return <OrderChecker />;

      default:
        return "Incorrect tab ID";
    }
  };
  return (
    <>
      <Layout.Section>
        <div className="wrapper d-flex flex-col gap-10">
          <div>
            <ButtonGroup fullWidth={true} segmented>
              <Button
                primary={tab === TABS.processes}
                onClick={() => setTab(TABS.processes)}
              >
                Processes
              </Button>
              <Button
                primary={tab === TABS.fulfillmentChecker}
                onClick={() => setTab(TABS.fulfillmentChecker)}
              >
                Fulfillment checker
              </Button>
              <Button
                primary={tab === TABS.paypalChecker}
                onClick={() => setTab(TABS.paypalChecker)}
              >
                Paypal checker
              </Button>
              <Button
                primary={tab === TABS.orderChecker}
                onClick={() => setTab(TABS.orderChecker)}
              >
                Order checker
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Layout.Section>
      {renderTab(tab)}
    </>
  );
};

export default SecondButtonSegment;
