import {
    IndexTable,
    Layout,
    LegacyCard,
    EmptySearchResult, Icon
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { paypalSettingApi } from "../../../redux/troubleshooting/paypalSetting/Action";
import { useNavigate, useParams } from "react-router-dom";
import { HistoricalSkeletonTable } from "../../../components/skeletonLoader";
import moment from "moment";
import {CircleCancelMinor, CircleTickMinor} from "@shopify/polaris-icons";

const PaypalSettings = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [paypalData, setPaypalData] = useState([]);
  const dispatch = useDispatch();
  const paypalSettingData = useSelector(
    (store) => store.requriedPaypalSettingData
  );
  useEffect(() => {
    const payload = {
      storeId: id,
      page: 0,
    };
    dispatch(paypalSettingApi(payload));
  }, []);

  useEffect(() => {
    if (
      paypalSettingData !== undefined &&
      paypalSettingData.data !== undefined
    ) {
      setPaypalData(paypalSettingData?.data?.content);
    }
    if(paypalSettingData?.data?.message === "Actual error message") {
      navigate('/logout')
    }
  }, [paypalSettingData]);

  const resourceName = {
    singular: "setting",
    plural: "settings",
  };

  const emptyStateMarkup = (
    <EmptySearchResult
      title={'No settings found'}
      description={''}
      withIllustration
    />
  );

  function formatDateTime(datetime) {
    try {
      const date = moment(datetime);
      const formattedDate = date.format("MMM DD, YYYY, h:mm A");
      return formattedDate;
    } catch (err) {
      return "";
    }
  }

  const rowMarkup = paypalData?.map(
    (
      {
        id,
        merchantAccountId,
        storeId,
        description,
        totalOrders,
        payerId,
        status,
        creationDate,
      },
      index,
    ) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell flush>
          {status ? (
            <Icon source={CircleTickMinor} color="success" />
          ) : (
            <Icon source={CircleCancelMinor} color="critical" />
          )}
        </IndexTable.Cell>
        <IndexTable.Cell flush>{merchantAccountId}</IndexTable.Cell>
        <IndexTable.Cell>{payerId}</IndexTable.Cell>
        <IndexTable.Cell>{description}</IndexTable.Cell>
        <IndexTable.Cell>{totalOrders}</IndexTable.Cell>
        <IndexTable.Cell>{status ? "Yes" : "No"}</IndexTable.Cell>
        <IndexTable.Cell>
          {creationDate === null ? "" : formatDateTime(creationDate)}
        </IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  const marchant_ID = (
    <span className="d-flex gap-10">
      <span>Merchant account ID</span>
    </span>
  );
  const payer_ID = (
    <span className="d-flex gap-10">
      <span>Payer ID</span>
    </span>
  );
  const owner = (
    <span className="d-flex gap-10">
      <span>Owner</span>
    </span>
  );
  const total_order = (
    <span className="d-flex gap-10">
      <span>Total Orders</span>
    </span>
  );
  const status = (
    <span className="d-flex gap-10">
      <span>Status</span>
    </span>
  );

  const creation_date = (
    <span className="d-flex gap-10">
      <span>Creation Date</span>
    </span>
  );

  return (
    <Layout.Section>
      <div className="wrapper">
        <LegacyCard sectioned>
          <p className="card_title" style={{ padding: "15px" }}>
            Paypal Settings
          </p>
          <LegacyCard sectioned>
            {paypalSettingData.isRequest ? (
              <HistoricalSkeletonTable />
            ) : (
              <IndexTable
                resourceName={resourceName}
                itemCount={paypalData?.length || 0 }
                emptyState={emptyStateMarkup}
                headings={[
                  { title: null, flush: true },
                  { title: marchant_ID, flush: true },
                  { title: payer_ID },
                  { title: owner },
                  { title: total_order },
                  { title: status },
                  { title: creation_date },
                ]}
                selectable={false}
              >
                {rowMarkup}
              </IndexTable>
            )}


          </LegacyCard>
        </LegacyCard>
      </div>
    </Layout.Section>
  );
};

export default PaypalSettings;
