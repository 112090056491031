import { REPORT_ERROR, REPORT_SUCCESS, REPORT_REQUEST } from "./ActionType";
const initialState = {
  isRequest: false,
  isSuccess: false,
  isError: false,
  data: {},
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case REPORT_REQUEST:
      return {
        isRequest: true,
        isSuccess: false,
        isError: false,
        data: action.payload,
      };
    case REPORT_SUCCESS:
      return {
        isRequest: false,
        isSuccess: true,
        isError: false,
        data: action.payload,
      };
    case REPORT_ERROR:
      return {
        isRequest: false,
        isSuccess: false,
        isError: true,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default reportReducer;