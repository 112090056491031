import {ROUTES_NAME} from "../../../config/constants";
import { ACCESS_TOKEN } from "../../../constants";
import {PULL_LOGS_ERROR, PULL_LOGS_REQUEST, PULL_LOGS_SUCCESS} from "./ActionType";

export const pullLogsApi = (payload) => {
  const {storeId, page} = payload
    return (dispatch) => {
        dispatch(pullLogsRequest());
        const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.pullLogs}`;
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({
              page: page,
              pageLength: 10,
              storeId:storeId
            }),
            credentials: 'include',
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
                'Content-Type': 'application/json',
                'Origin': `${process.env.REACT_APP_URL}`
            },
          })
          .then((res) => res.json())
          .then((pullLogData) => {
            dispatch(pullLogsSuccess(pullLogData));
          })
          .catch((data) => {
            console.log(data,"error");
            dispatch(pullLogsError());
          })  
    }
}


function pullLogsRequest(pullLogsData) {
    return {
      type: PULL_LOGS_REQUEST,
      payload: pullLogsData,
    };
  }
  function pullLogsSuccess(pullLogsData) {
    return {
      type: PULL_LOGS_SUCCESS,
      payload: pullLogsData,
    };
  }
  function pullLogsError(pullLogsData) {
    return {
      type: PULL_LOGS_ERROR,
      payload: pullLogsData,
    };
  }
  