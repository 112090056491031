import {ROUTES_NAME} from "../../config/constants";
import { ACCESS_TOKEN } from "../../constants";
import {SHOP_COUNTRIES_ERROR, SHOP_COUNTRIES_REQUEST, SHOP_COUNTRIES_SUCCESS} from "./ActionType";

export const shopCountriesApi = () => {
  return (dispatch) => {
    dispatch(shopCountriesRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.shop_countries}`;

    fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        'Content-Type': 'application/json',
        'Origin': `${process.env.REACT_APP_URL}`
      },
    })
      .then((res) => res.json())
      .then((shopCountriesDetails) => {
        dispatch(shopCountriesSuccess(shopCountriesDetails));
      })
      .catch((data) => {
        console.log(data,"error")
        dispatch(shopCountriesError());
      });
  };
};

function shopCountriesRequest(shopCountriesData) {
  return {
    type: SHOP_COUNTRIES_REQUEST,
    payload: shopCountriesData,
  };
}

function shopCountriesSuccess(shopCountriesData) {
    return {
        type: SHOP_COUNTRIES_SUCCESS,
        payload: shopCountriesData,
    };
}

function shopCountriesError(shopCountriesData) {
    return {
        type: SHOP_COUNTRIES_ERROR,
        payload: shopCountriesData,
    };
}
