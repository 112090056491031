export const Curriencies =  [
    {"symbol":"£","code":"GBP",},
    {"symbol":"GEL","code":"GEL",},
    {"symbol":"$","code":"USD",},
    {"symbol":"€","code":"EUR",},
    {"symbol":"AED","code":"AED",},
    {"symbol":"Af","code":"AFN",},
    {"symbol":"AMD","code":"AMD",},
    {"symbol":"AR$","code":"ARS",},
    {"symbol":"ALL","code":"Lek",},
    {"symbol":"؋","code":"AFN",},
    {"symbol":"$","code":"ARS",},
    {"symbol":"ƒ","code":"AWG",},
    {"symbol":"$","code":"AUD",},
    {"symbol":"₼","code":"AZN",},
    {"symbol":"$","code":"BSD",},
    {"symbol":"$","code":"BBD",},
    {"symbol":"$","code":"HKD",},
    {"symbol":"RM","code":"MYR",},
    {"symbol":"¥","code":"CNY",},
    {"symbol":"$","code":"SGD",},
    {"symbol":"kr","code":"SEK",},
    {"symbol":"¥","code":"JPY",},
    {"symbol":"kr","code":"NOK",},
    {"symbol":"$","code":"AUD",},
    {"symbol":"Ft","code":"HUF",},
    {"symbol":"CHF","code":"CHF",},
    {"symbol":"$","code":"MXN",},
    {"symbol":"Kč","code":"CZK",},
    {"symbol":"₽","code":"RUB",},
    {"symbol":"$","code":"CAD",},
    {"symbol":"R$","code":"BRL",},
    {"symbol":"kr","code":"DKK",},
    {"symbol":"$","code":"CLP",},
    {"symbol":"€","code":"EUR",},
    {"symbol":"R","code":"ZAR",},
    {"symbol":"$","code":"NZD",},
    {"symbol":"₱","code":"PHP",},
    {"symbol":"zł","code":"PLN",},
    {"symbol":"₹","code":"INR"},
    {"symbol":"MAD","code":"MAD"},
    {"symbol":"₡","code":"CRC"},
    {"symbol":"CFA","code":"XOF"},
    {"symbol":"Kz","code":"AOA"},
    {"symbol":"৳","code":"BDT"},
    {"symbol":"$","code":"COP"},
    {"symbol":"₫","code":"VND"},
    {"symbol":"฿","code":"THB"},
    {"symbol":"₨","code":"PKR"},
    {"symbol":"K","code":"PGK"},
    {"symbol":"ر.ع.","code":"OMR"},
    {"symbol":"S/","code":"PEN"},
    {"symbol":"$","code":"NZD"},
    {"symbol":"₦","code":"NGN"},
    {"symbol":"MRU","code":"MRU"},
    {"symbol":"₸","code":"KZT"},
    {"symbol":"₪","code":"ILS"},
    {"symbol":"G","code":"HTG"},

];
