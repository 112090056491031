import {ROUTES_NAME} from "../../../config/constants";
import { ACCESS_TOKEN } from "../../../constants";
import {shopID} from "../../../utils";
import {
  DIGITAL_SYNC_ERROR,
  DIGITAL_SYNC_REQUEST,
  DIGITAL_SYNC_SUCCESS,
  SET_DEFAULT_ERROR,
  SET_DEFAULT_REQUEST,
  SET_DEFAULT_SUCCESS,
  SET_DELETE_ERROR,
  SET_DELETE_REQUEST,
  SET_DELETE_SUCCESS,
} from "./ActionType";

export const requestToSetDeafultAccount = (id) => {
  return (dispatch) => {
    dispatch(setToDefaultRequest());
    const url = `${
      process.env.REACT_APP_BACKEND_API_URL
    }${ROUTES_NAME.paypal_setting_default(id)}`;
    fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        'Content-Type': 'application/json',
        'Origin': `${process.env.REACT_APP_URL}`
      },
    })
      .then((res) => res.json())
      .then((response) => {
        dispatch(setToDefaultSuccess(response));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(setToDefaultError());
      });
  };
};

function setToDefaultRequest(data) {
  console.log(data, "55");
  return {
    type: SET_DEFAULT_REQUEST,
    payload: data,
  };
}
function setToDefaultSuccess(data) {
  console.log(data, "56");
  return {
    type: SET_DEFAULT_SUCCESS,
    payload: data,
  };
}
function setToDefaultError(data) {
  console.log(data, "57");
  return {
    type: SET_DEFAULT_ERROR,
    payload: data,
  };
}

export const requestToDeleteAccount = (id) => {
  return (dispatch) => {
    dispatch(setToDeleteRequest());
    const url = `${
      process.env.REACT_APP_BACKEND_API_URL
    }${ROUTES_NAME.paypal_setting_delete(id)}`;
    fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        'Content-Type': 'application/json',
        'Origin': `${process.env.REACT_APP_URL}`
      },
    })
      .then((res) => res.json())
      .then((response) => {
        dispatch(setToDeleteSuccess(response));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(setToDeleteError());
      });
  };
};

function setToDeleteRequest(data) {
  console.log(data, "55");
  return {
    type: SET_DELETE_REQUEST,
    payload: data,
  };
}
function setToDeleteSuccess(data) {
  console.log(data, "56");
  return {
    type: SET_DELETE_SUCCESS,
    payload: data,
  };
}
function setToDeleteError(data) {
  console.log(data, "57");
  return {
    type: SET_DELETE_ERROR,
    payload: data,
  };
}

export const digitalSyncStatus = (payload) => {
  const {state, shopId, storeType} = payload;
  return (dispatch) => {
    dispatch(digitalSyncRequest());
    const url = `${
      process.env.REACT_APP_BACKEND_API_URL
    }${ROUTES_NAME.digital_sync(shopId, storeType)}`;
    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        value: state,
      }),
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        'Content-Type': 'application/json',
        'Origin': `${process.env.REACT_APP_URL}`
      },
    })
      .then((res) => res.json())
      .then((response) => {
        dispatch(digitalSyncSuccess(response));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(digitalSyncError());
      });
  };
};

function digitalSyncRequest(data) {
  console.log(data, "55");
  return {
    type: DIGITAL_SYNC_REQUEST,
    payload: data,
  };
}
function digitalSyncSuccess(data) {
  console.log(data, "56");
  return {
    type: DIGITAL_SYNC_SUCCESS,
    payload: data,
  };
}
function digitalSyncError(data) {
  console.log(data, "57");
  return {
    type: DIGITAL_SYNC_ERROR,
    payload: data,
  };
}
