import {Button, ButtonGroup} from "@shopify/polaris";
import {useCallback, useEffect, useState} from "react";

function ToggleButton(props) {
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (props.visible) {
      setActive(props.visible);
    } else {
      setActive(false);
    }
  }, [props.visible]);

  const handleFirstButtonClick = useCallback(() => {
    if (active) return;
    setActive(true);
    props.setVisible(true);
  }, [active]);

  const handleSecondButtonClick = useCallback(() => {
    if (!active) return;
    setActive(false);
    props.setVisible(false);
  }, [active]);

  return (
    <div style={{ marginTop: "25px" }}>
      {/* {props.loading ? (
        <Button loading>Loading</Button>
      ) : ( */}
        <ButtonGroup segmented>
          <Button
            primary={active}
            onClick={() => {
              handleFirstButtonClick();
              props?.handleToggleAction(true);
            }}
          >
            {props.buttonOne}
          </Button>
          <Button
            pressed={!active}
            onClick={() => {
              handleSecondButtonClick();
              props?.handleToggleAction(false);
            }}
          >
            {props.buttonTwo}
          </Button>
        </ButtonGroup>
      {/* )} */}
    </div>
  );
}

export default ToggleButton;
