import {
  LegacyCard,
  VerticalStack,
  Box,
  Button,
  DatePicker,
  Icon,
  Image,
  Popover,
  Text,
  TextField,
  Tooltip,
} from "@shopify/polaris";
import { React, useEffect, useRef, useState } from "react";
import { QuestionMark } from "../../assets";
import { CalendarMinor } from "@shopify/polaris-icons";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { gettotalPossibleOrders } from "../../redux/store/historicalOrders/Action";
import { OrdersCount } from "../popup";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const DateContainer = (props) => {
  const { i18n, t } = useTranslation();
  const { id, storeType } = useParams();
  const [state, setState] = useState(false);
  const [orders, setOrders] = useState(0);
  const [openPopup, setOpenPopup] = useState(false)
  const dispatch = useDispatch();
  const totalPossibleOrders = useSelector(
    (store) => store.requiredHistoricalData
  );
  const [visible, setVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [{ month, year }, setDate] = useState({
    month: selectedDate.getMonth(),
    year: selectedDate.getFullYear(),
  });
  const formattedValue = moment(selectedDate).format("YYYY-MM-DD");
  const datePickerRef = useRef(null);
  function handleInputValueChange() {
    console.log("handleInputValueChange");
  }
  function handleOnClose({ relatedTarget }) {
    setVisible(false);
  }
  function handleMonthChange(month, year) {
    setDate({ month, year });
  }
  function handleDateSelection({ end: newSelectedDate }) {
    setSelectedDate(newSelectedDate);
    setVisible(false);
  }
  useEffect(() => {
    if (selectedDate) {
      setDate({
        month: selectedDate.getMonth(),
        year: selectedDate.getFullYear(),
      });
    }
  }, [selectedDate]);

  const handleTotalOrdersCount = (date) => {
    const payload = {
      shopId: id,
      date: date,
      storeType:storeType
    };
    dispatch(gettotalPossibleOrders(payload));
  };

  useEffect(() => {
    if (
      totalPossibleOrders.isSuccess &&
      totalPossibleOrders?.possibleOrdersCount?.count !== undefined
    ) {
      setOrders(totalPossibleOrders.possibleOrdersCount.count);
    }
  }, [totalPossibleOrders]);

  return (
    <>
      {state ? (
        <OrdersCount
          formattedValue={formattedValue}
          orders={orders}
          deletedAlert={state}
          setDeletedAlert={setState}
          handleOrders={props.handleOrdersQueue}
          setOpenPopup={setOpenPopup}
        />
      ) : (
        ""
      )}
      <div
        className="d-flex justify-content-between w-100 flex-wrap"
        style={{ padding: "20px", alignItems: "end" }}
      >
        <div className="d-flex flex-wrap gap-10" style={{ alignItems: "end" }}>
          <VerticalStack align="center" gap="4">
            <Box minWidth="276px" padding={{ xs: 2 }}>
              <Popover
                active={visible}
                autofocusTarget="none"
                preferredAlignment="left"
                fullWidth
                preferInputActivator={false}
                preferredPosition="below"
                preventCloseOnChildOverlayClick
                onClose={handleOnClose}
                activator={
                  <TextField
                    role="combobox"
                    label={t("Start_date")}
                    prefix={<Icon source={CalendarMinor} />}
                    value={formattedValue}
                    onFocus={() => setVisible(true)}
                    onChange={handleInputValueChange}
                    autoComplete="off"
                  />
                }
              >
                <LegacyCard ref={datePickerRef}>
                  <DatePicker
                    month={month}
                    year={year}
                    selected={selectedDate}
                    onMonthChange={handleMonthChange}
                    onChange={handleDateSelection}
                  />
                </LegacyCard>
              </Popover>
            </Box>
          </VerticalStack>

          <div data-testid="getOrders" style={{ padding: "8px 10px" }}>
            {/* <Button disabled={props.userTotalToken === 0 ? true : false} primary onClick={()=>{props.handleOrdersQueue(formattedValue)}}>{t("Get_orders")}</Button> */}
            <Button
              disabled={props.userTotalToken === 0 ? true : false}
              primary
              onClick={() => {
                handleTotalOrdersCount(formattedValue);
                setState(true);
              }}
            >
              {t("Get_orders")}
            </Button>
          </div>
        </div>
        <div className="d-flex gap-5" style={{padding:"0 10px"}}>
          <Box width="20px">
            <div
              style={{
                background: "#2e72d2",
                textAlign: "center",
                borderRadius: "4px",
              }}
            >
              <p className="text-white">{props.userTotalToken}</p>
            </div>
          </Box>
          <Text>{t("Token_left")}</Text>
          <Tooltip
            padding="5"
            content={t(
              "every_request_uses_up_token_so_include_all_your_older_orders_in_one_go"
            )}
          >
            <Image src={QuestionMark} width={17} />
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default DateContainer;
