import React, { useEffect } from 'react'
import { Layout, LegacyCard } from "@shopify/polaris";
import '../style.css';
import { useDispatch, useSelector } from 'react-redux';
import {getTroubleShootTotalOrders, getTroubleShootAwaitingSyncOrders, getTroubleShootTotalSynced } from '../../../redux/troubleshooting/stats/Action';

import { useNavigate, useParams } from 'react-router-dom';
import { Skeleton } from "@mui/material";

const Cards = () => {
  const navigate = useNavigate(); 
  const { id, storeType } = useParams();
  const dispatch = useDispatch();
  const statsData = useSelector(
    (store) => store.requiredStatsData
  );

  const { totalSyncOrders, awaitingOrders, totalOrders } = useSelector((store) => store.requiredStatsData)

  useEffect(() => {
    dispatch(getTroubleShootTotalOrders(id,storeType));
    dispatch(getTroubleShootAwaitingSyncOrders(id, storeType));
    dispatch(getTroubleShootTotalSynced(id, storeType));
  }, []);
  useEffect(() => {
    if (statsData?.data?.message === "Actual error message") {
      navigate('/logout')
    }
  }, [statsData]);

  return (
    <>
      <Layout.Section>
        <div
          className='cardStyle'
        >
          <div className='cardWidth'>
            <LegacyCard sectioned>
              <div className="card_alignment">
                <p className="card_title">Total orders</p>
                <p className="count">{statsData.isRequest ? <Skeleton  data-testid="skeleton" width="100%"/> : totalOrders ? totalOrders.count : 0}</p>

              </div>
            </LegacyCard>
          </div>
          <div className='cardWidth'>
            <LegacyCard sectioned>
              <div className="card_alignment">
                <p className="card_title">Orders Awaiting sync</p>
                <p className="count">{statsData.isRequest ? <Skeleton data-testid="skeleton" width="100%"/> : awaitingOrders ? awaitingOrders.count : 0}</p>

              </div>
            </LegacyCard>
          </div>
          <div className='cardWidth'>
            <LegacyCard sectioned>
              <div className="card_alignment">
                <p className="card_title">Order synced to paypal</p>
                <p className="count">{statsData.isRequest ? <Skeleton data-testid="skeleton" width="100%"/> : totalSyncOrders ? totalSyncOrders.count : 0}</p>

              </div>
            </LegacyCard>
          </div>
        </div>
      </Layout.Section>
    </>
  )
}

export default Cards
