import { PAYPAL_CHECKER_REQUEST, PAYPAL_CHECKER_ERROR, PAYPAL_CHECKER_SUCCESS } from "./ActionType";
import { ROUTES_NAME } from "../../../config/constants";
import { ACCESS_TOKEN } from "../../../constants";


export const getpaypalCheckerData = (payload) => {
  const {shopId, shopMerchantAccountId,transactionId,trackingNumber } = payload;
    return (dispatch) => {
      dispatch(paypalCheckerRequest());
      const url = `${
        process.env.REACT_APP_BACKEND_API_URL
      }${ROUTES_NAME.paypalChecker()}`;
  
      fetch(url, {
        method: "POST",
        body: JSON.stringify({
            storeId:shopId,
            shopMerchantAccountId:shopMerchantAccountId,
            transactionId:transactionId,
            trackingNumber:trackingNumber
          }),
        credentials: "include",
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
          "Content-Type": "application/json",
          Origin: `${process.env.REACT_APP_URL}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          dispatch(paypalCheckerSuccess(res));
        })
        .catch((data) => {
          console.log(data, "error");
          dispatch(paypalCheckerError());
        });
    };
  };
  
  function paypalCheckerRequest(data) {
    return {
      type: PAYPAL_CHECKER_REQUEST,
      payload: data,
    };
  }
  function paypalCheckerSuccess(data) {
    return {
      type: PAYPAL_CHECKER_SUCCESS,
      payload: data,
    };
  }
  function paypalCheckerError(data) {
    return {
      type: PAYPAL_CHECKER_ERROR,
      payload: data,
    };
  }
  