import { ROUTES_NAME } from "../../../config/constants";
import { ACCESS_TOKEN } from "../../../constants";
import {
  REINDEXING_REQUEST,
  REINDEXING_ERROR,
  REINDEXING_SUCCESS,

  CANCEL_DIGITAL_ORDER_ERROR,
  CANCEL_DIGITAL_ORDER_REQUEST,
  CANCEL_DIGITAL_ORDER_SUCCESS,
} from "./ActionType";

export const getReIndexStore = (shopId, storeType) => {
  return async (dispatch) => {
    await dispatch(reindexRequest());
    const url = `${
      process.env.REACT_APP_BACKEND_API_URL
    }${ROUTES_NAME.reindexing(shopId,storeType)}`;
    fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((resp) => {
        dispatch(reindexSuccess(resp));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(reindexError());
      });
  };
};

function reindexRequest(data) {
  return {
    type: REINDEXING_REQUEST,
    payload: data,
  };
}

function reindexSuccess(data) {
  return {
    type: REINDEXING_SUCCESS,
    payload: data,
  };
}

function reindexError(data) {
  return {
    type: REINDEXING_ERROR,
    payload: data,
  };
}

//for cancel digital order
export const postCancelDigitalOrder = (shopId, storeType) => {
  return async (dispatch) => {
    await dispatch(cancelDigitalOrderRequest());
    const url = `${
      process.env.REACT_APP_BACKEND_API_URL
    }${ROUTES_NAME.cancelDigitalOrder(shopId,storeType)}`;
    fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((resp) => {
        dispatch(cancelDigitalOrderSuccess(resp));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(cancelDigitalOrderError());
      });
  };
};

function cancelDigitalOrderRequest(data) {
  return {
    type: CANCEL_DIGITAL_ORDER_REQUEST,
    payload: data,
  };
}

function cancelDigitalOrderSuccess(data) {
  return {
    type: CANCEL_DIGITAL_ORDER_SUCCESS,
    payload: data,
  };
}

function cancelDigitalOrderError(data) {
  return {
    type: CANCEL_DIGITAL_ORDER_ERROR,
    payload: data,
  };
}
