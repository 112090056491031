import { React, useEffect, useState } from "react";
import {
  IndexTable,
  Layout,
  LegacyCard,
  EmptySearchResult
} from "@shopify/polaris";
import { eventApi } from "../../../redux/troubleshooting/events/Action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { HistoricalSkeletonTable } from "../../../components/skeletonLoader";

const EventsTab = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [event, setEvent] = useState([]);
  const dispatch = useDispatch();
  const eventTableData = useSelector((store) => store.requriedEventsData);
  useEffect(() => {
    const payload = {
      storeId: id,
      page: 0,
    };
    dispatch(eventApi(payload));
  }, []);
  useEffect(() => {
    if (
      eventTableData.data &&
      eventTableData.data !== undefined &&
      eventTableData.data.content !== undefined
    ) {
      setEvent(eventTableData.data.content);
    }
    if(eventTableData?.data?.message === "Actual error message"){
      navigate('/logout')
    }
  }, [eventTableData]);

  const eventType = (
    <span className="d-flex gap-10">
      <span>Event type</span>
    </span>
  );
  const type = (
    <span className="d-flex gap-10">
      <span>Type</span>
    </span>
  );
  const eventDiscription = (
    <span className="d-flex gap-10">
      <span>Event description</span>
    </span>
  );

  const emptyStateMarkup = (
    <EmptySearchResult
      title={'No  events found'}
      description={''}
      withIllustration
    />
  );

  const resourceName = {
    singular: "Event",
    plural: "Events",
  };

  const rowMarkup = event?.map(
    ({ id, storeId, eventType, status, description }, index) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>{eventType}</IndexTable.Cell>
        <IndexTable.Cell>{status}</IndexTable.Cell>
        <IndexTable.Cell>{description}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );


  return (
    <>
      <Layout.Section>
        <div className="wrapper">
          <LegacyCard sectioned>
            <div className="d-flex flex-col" style={{ padding: "15px" }}>
              <p className="card_title">Events</p>
            </div>

            <LegacyCard>
              {eventTableData.isRequest ? (
                <HistoricalSkeletonTable />
              ) : (
                <IndexTable
                  resourceName={resourceName || 0}
                  itemCount={event?.length}
                  emptyState={emptyStateMarkup}
                  headings={[
                    { title: eventType },
                    { title: type },
                    { title: eventDiscription },
                  ]}
                  selectable={false}
                >
                  {rowMarkup}
                </IndexTable>
              )}

             
            </LegacyCard>
          </LegacyCard>
        </div>
      </Layout.Section>
    </>
  );
};

export default EventsTab;
