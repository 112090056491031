import React from 'react'
import {Layout} from '@shopify/polaris';
import './style.css';

const Header = (props) => {
  return (
    <>
       <Layout.Section>
        <div className='card'>
          <p className="dashboard_title">{props.title}</p>
        </div>
      </Layout.Section>
    </>
  )
}

export default Header
