import { React, useCallback, useEffect, useState } from "react";
import {
  Layout,
  LegacyCard,
  TextField,
  Button,
  InlineError,
  Select,
  Banner,
} from "@shopify/polaris";
import CriticalBanner from "../../../components/banner/criticalBanner";
import SuccessBanner from "../../../components/banner/SuccessBanner";
import Transcript from "../../../components/transcript";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getFullfillmentCheckerData } from "../../../redux/store/fullfillment/Action";
import { fetchByNameApi } from "../../../redux/troubleshooting/fetch-by-name/Action";
import FullfillmentOrderNameTable from "../table/FullfillmentOrderNameTable";
const FullfilmentChecker = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [flag, setFlag] = useState(false);
  const [message, setMessage] = useState("");
  const [transcript, setTranscript] = useState();
  const [displayticket, setDisplayTicket] = useState(false);
  const [orders, setOrders] = useState("");
  const [process, setProcess] = useState("id");
  const [visible, setVisible] = useState(false);
  const [orderlist, setOrderList] = useState([]);
  const [error, setError] = useState({
    ordername: "",
    orderId: "",
  });
  const [runTime, setRunTime] = useState(0);
  const handleChange = useCallback((newValue) => setValue(newValue), []);
  const handleOrdersName = useCallback((newValue) => setOrders(newValue), []);
  const handleStoreIdSelect = useCallback(
    (newValue) => setProcess(newValue),
    []
  );

  const fullfillmentData = useSelector(
    (store) => store.requiredFullfillmentReducer
  );

  const orderNameList = useSelector((store) => store.requireFetchByNameReducer);

  useEffect(() => {
    if (fullfillmentData.isSuccess && fullfillmentData.fullfillment) {
      setTranscript(fullfillmentData?.fullfillment?.responseData);
      setDisplayTicket(fullfillmentData?.fullfillment?.createTicket);
      setFlag(fullfillmentData?.fullfillment?.success);
      setMessage(fullfillmentData?.fullfillment?.message);
      setVisible(
        fullfillmentData.fullfillment.responseData === null ? false : true
      );
    }
    if(fullfillmentData?.fullfillment?.message === "Actual error message") {
      navigate('/logout')
    }
  }, [fullfillmentData,navigate]);

  const handleMultipleOrderNameList = useCallback((orderId) => {
    console.log("handleMultipleOrderNameList", orderId);
    const payload = {
      orderId: orderId,
      id: id,
      orderName: null,
    };
    setRunTime(1);
    dispatch(getFullfillmentCheckerData(payload));
    setError({
      ordername: "",
      orderId: "",
    });
  },[dispatch, id]);

  useEffect(() => {
    if (orderNameList.isSuccess) {
      setFlag(false);
      setOrderList(orderNameList.data);
      if (orderNameList.data.length === 1) {
        console.log(orderNameList.data);
        console.log(orderNameList.data[0]);
        handleMultipleOrderNameList(orderNameList.data[0].orderId);
      }
    }
    if(orderNameList?.data?.message === "Actual error message") {
      navigate('/logout')
    }
  }, [orderNameList,handleMultipleOrderNameList,navigate]);

  useEffect(() => {
    setRunTime(0);
  }, []);

  const handleSubmit = (state) => {
    const payload = {
      orderId: process === "id" ? value : null,
      id: id,
      orderName: process === "id" ? null : orders,
    };
    if (state === "name") {
      const payload = {
        orderName: orders,
        storeId: id,
      };
      if (orders) {
        setRunTime(1);
        dispatch(fetchByNameApi(payload));
        setError({
          ordername: "",
          orderId: "",
        });
      } else {
        setError({
          ordername: "Please enter order Name",
          orderId: "",
        });
      }
    } else {
      if (value === "") {
        setError({
          ordername: "",
          orderId: "Please enter order Id",
        });
      } else {
        setRunTime(1);
        dispatch(getFullfillmentCheckerData(payload));
        setError({
          ordername: "",
          orderId: "",
        });
        setMessage("");
      }
    }
  };

  
  const options = [
    { label: "Order Name", value: "name" },
    { label: "Order Id", value: "id" },
  ];

  const handleKeyPress = (event) => {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed) {
      event.preventDefault();
      handleSubmit(process);
    }
  }

  return (
    <>
      <Layout.Section>
        <div className="wrapper">
          <LegacyCard sectioned>
            <div style={{ padding: "15px" }}>
              <LegacyCard sectioned>
                <div
                  className="d-flex flex_wrap"
                  style={{
                    alignItems: "end",
                    gap: "35px",
                  }}
                >
                  <div>
                    <Select
                      label="Select Orders"
                      options={options}
                      onChange={handleStoreIdSelect}
                      value={process}
                      placeholder="Select Orders Name or Id"
                    />
                  </div>
                  {process === "id" ? (
                    <div onKeyDown={handleKeyPress}>
                      <TextField
                        label="Store order ID"
                        value={value}
                        onChange={handleChange}
                        autoComplete="off"
                        placeholder="Please enter store id"
                      />
                      {error ? (
                        <div style={{ position: "absolute" }}>
                          <InlineError
                            message={error.orderId}
                            fieldID="myFieldID"
                          />
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div>
                      <TextField
                        label="Order name"
                        value={orders}
                        onChange={handleOrdersName}
                        autoComplete="off"
                        placeholder="Please enter store name"
                      />
                      {error ? (
                        <div style={{ position: "absolute" }}>
                          <InlineError
                            message={error.ordername}
                            fieldID="myFieldID"
                          />
                        </div>
                      ) : null}
                    </div>
                  )}
                  <div>
                    <Button
                      primary
                      loading={fullfillmentData.isRequest}
                      onClick={() => {
                        handleSubmit(process);
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
                {orderlist.length > 1 && runTime && process === "name" ? (
                  <FullfillmentOrderNameTable
                    handleMultipleOrderNameList={handleMultipleOrderNameList}
                    list={orderlist}
                    loading={orderNameList.isRequest}
                  />
                ) : orderlist.length === 0 && runTime && process === "name" ? (
                  <div style={{ marginTop: "20px" }}>
                    <Banner title="No order Found" status="critical"></Banner>
                  </div>
                ) : (
                  ""
                )}{" "}
                {flag ? (
                  <SuccessBanner message={message} runTime={runTime} />
                ) : (
                  <CriticalBanner message={message} runTime={runTime} />
                )}
                {visible && runTime ? (
                  <Transcript
                    displayticket={displayticket}
                    transcript={transcript}
                  />
                ) : (
                  ""
                )}
              </LegacyCard>
            </div>
          </LegacyCard>
        </div>
      </Layout.Section>
    </>
  );
};

export default FullfilmentChecker;
