import {
  Button,
  Popover,
  ActionList,
  Image,
  LegacyCard,
} from "@shopify/polaris";
import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Spain,
  India,
  Dutch,
  German,
  Italy,
  Polish,
  Portuguese,
  France,
  USA,
} from "../../assets";
import "./style.css";

function Dropdown() {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const { t, i18n } = useTranslation();
  const changeLanguageHandler = (code) => {
    const languageValue = code;
    i18n.changeLanguage(languageValue);
  };
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  const [popoverActive, setPopoverActive] = useState(false);
  const [lang, setLang] = useState(
    localStorage.getItem("lang") ? localStorage.getItem("lang") : "English"
  );
  const [img, setImg] = useState(
    localStorage.getItem("img") ? localStorage.getItem("img") : USA
  );

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      <span className="dropDown-label">
        <Image src={img} width={20} />
        {screenSize.width > 450 ? (
          <span style={{ marginTop: "2px" }}> {lang}</span>
        ) : (
          ""
        )}{" "}
      </span>
    </Button>
  );

  const handleLanguages = (selectedImg, selectedLang, code) => {
    localStorage.setItem("lang", selectedLang);
    localStorage.setItem("img", selectedImg);
    localStorage.setItem("code", code);
    changeLanguageHandler(code);
    setLang(selectedLang);
    setImg(selectedImg);
  };

  return (
    <div style={{}}>
      <Popover
        active={popoverActive}
        activator={activator}
        autofocusTarget="first-node"
        onClose={togglePopoverActive}
      >
        <LegacyCard sectioned>
          <ul>
            <li
              className="dropdown-list"
              onClick={() => {
                handleLanguages(USA, "English", "en");
              }}
            >
              {" "}
              <Image src={USA} width={15} />
              <span>English</span>
            </li>
            <li
              className="dropdown-list"
              onClick={() => {
                handleLanguages(France, "French", "fr");
              }}
            >
              <Image src={France} width={15} />
              <span>French</span>
            </li>
            <li
              className="dropdown-list"
              onClick={() => {
                handleLanguages(German, "German", "de");
              }}
            >
              <Image src={German} width={15} />
              <span>German</span>
            </li>
            <li
              className="dropdown-list"
              onClick={() => {
                handleLanguages(Spain, "Spanish", "es");
              }}
            >
              <Image src={Spain} width={15} />
              <span>Spanish</span>
            </li>
            <li
              className="dropdown-list"
              onClick={() => {
                handleLanguages(Dutch, "Dutch", "nl");
              }}
            >
              <Image src={Dutch} width={15} />
              <span>Dutch</span>
            </li>
            <li
              className="dropdown-list"
              onClick={() => {
                handleLanguages(Polish, "Polish", "pl");
              }}
            >
              <Image src={Polish} width={15} />
              <span>Polish</span>
            </li>
            <li
              className="dropdown-list"
              onClick={() => {
                handleLanguages(Italy, "Italian", "it");
              }}
            >
              <Image src={Italy} width={15} />
              <span>Italian</span>
            </li>
            <li
              className="dropdown-list"
              onClick={() => {
                handleLanguages(Portuguese, "Portuguese", "pt");
              }}
            >
              <Image src={Portuguese} width={15} />
              <span>Portuguese</span>
            </li>
          </ul>
        </LegacyCard>
      </Popover>
    </div>
  );
}

export default Dropdown;
