import {
  DASHBOARD_CARDS_ERROR,
  DASHBOARD_CARDS_REQUEST,
  DASHBOARD_CARDS_SUCCESS,
  TOTAL_SYNCED_TODAY_ERROR,
  TOTAL_SYNCED_TODAY_REQUEST,
  TOTAL_SYNCED_TODAY_SUCCESS,
  TOTAL_SYNCED_LASTTWODAYS_ERROR,
  TOTAL_SYNCED_LASTTWODAYS_REQUEST,
  TOTAL_SYNCED_LASTTWODAYS_SUCCESS,
  TOTAL_SYNCED_LASTTHIRTYDAYS_ERROR,
  TOTAL_SYNCED_LASTTHIRTYDAYS_REQUEST,
  TOTAL_SYNCED_LASTTHIRTYDAYS_SUCCESS,
  TOTAL_SYNCED_LASTSEVENDAYS_ERROR,
  TOTAL_SYNCED_LASTSEVENDAYS_REQUEST,
  TOTAL_SYNCED_LASTSEVENDAYS_SUCCESS,
  TOTAL_STORES_ERROR,
  TOTAL_STORES_REQUEST,
  TOTAL_STORES_SUCCESS,
  TOTAL_PAYPAL_UPLOADS_ERROR,
  TOTAL_PAYPAL_UPLOADS_REQUEST,
  TOTAL_PAYPAL_UPLOADS_SUCCESS,
  TOTAL_ORDERS_ERROR,
  TOTAL_ORDERS_REQUEST,
  TOTAL_ORDERS_SUCCESS,
  TOTAL_ORDERS_ACTIVE_ERROR,
  TOTAL_ORDERS_ACTIVE_REQUEST,
  TOTAL_ORDERS_ACTIVE_SUCCESS,
  DASHBOARD_PROFILE_ERROR,
  DASHBOARD_PROFILE_REQUEST,
  DASHBOARD_PROFILE_SUCCESS,
  DASHBOARD_AWAITINGSYNC_ERROR,
  DASHBOARD_AWAITINGSYNC_REQUEST,
  DASHBOARD_AWAITINGSYNC_SUCCESS,
} from "./ActionType";
const initialState = {
  isRequest: false,
  isSuccess: false,
  isError: false,
  totalSyncedYesterday: {},
  totalSyncedToday: {},
  lastTwoDays: {},
  lastThirtyDays: {},
  lastSevenDays: {},
  totalStores: {},
  totalPaypalUpload: {},
  totalOrders: {},
  totalActiveOrder: {},
  profile: {},
  awaitingSync: {},
};

const dashboardCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_CARDS_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        totalSyncedYesterday: action.payload,
      };

    case DASHBOARD_CARDS_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        totalSyncedYesterday: action.payload,
      };

    case DASHBOARD_CARDS_ERROR:
      return {
        isRequest: false,
        isSuccess: false,
        isError: true,
        totalSyncedYesterday: action.payload,
      };

    case TOTAL_SYNCED_TODAY_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        totalSyncedToday: action.payload,
      };
    case TOTAL_SYNCED_TODAY_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        totalSyncedToday: action.payload,
      };
    case TOTAL_SYNCED_TODAY_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        totalSyncedToday: action.payload,
      };

    case TOTAL_SYNCED_LASTTWODAYS_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        lastTwoDays: action.payload,
      };

    case TOTAL_SYNCED_LASTTWODAYS_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        lastTwoDays: action.payload,
      };

    case TOTAL_SYNCED_LASTTWODAYS_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        lastTwoDays: action.payload,
      };

    case TOTAL_SYNCED_LASTTHIRTYDAYS_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        lastThirtyDays: action.payload,
      };
    case TOTAL_SYNCED_LASTTHIRTYDAYS_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        lastThirtyDays: action.payload,
      };
    case TOTAL_SYNCED_LASTTHIRTYDAYS_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        lastThirtyDays: action.payload,
      };
    case TOTAL_SYNCED_LASTSEVENDAYS_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        lastSevenDays: action.payload,
      };
    case TOTAL_SYNCED_LASTSEVENDAYS_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        lastSevenDays: action.payload,
      };
    case TOTAL_SYNCED_LASTSEVENDAYS_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        lastSevenDays: action.payload,
      };
    case TOTAL_STORES_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        totalStores: action.payload,
      };
    case TOTAL_STORES_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        totalStores: action.payload,
      };
    case TOTAL_STORES_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        totalStores: action.payload,
      };
    case TOTAL_PAYPAL_UPLOADS_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        totalPaypalUpload: action.payload,
      };
    case TOTAL_PAYPAL_UPLOADS_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        totalPaypalUpload: action.payload,
      };
    case TOTAL_PAYPAL_UPLOADS_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        totalPaypalUpload: action.payload,
      };

    case TOTAL_ORDERS_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        totalOrders: action.payload,
      };
    case TOTAL_ORDERS_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        totalOrders: action.payload,
      };
    case TOTAL_ORDERS_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        totalOrders: action.payload,
      };

    case TOTAL_ORDERS_ACTIVE_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        totalActiveOrder: action.payload,
      };
    case TOTAL_ORDERS_ACTIVE_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        totalActiveOrder: action.payload,
      };
    case TOTAL_ORDERS_ACTIVE_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        totalActiveOrder: action.payload,
      };

    case DASHBOARD_PROFILE_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        profile: action.payload,
      };
    case DASHBOARD_PROFILE_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        profile: action.payload,
      };
    case DASHBOARD_PROFILE_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        profile: action.payload,
      };
    case DASHBOARD_AWAITINGSYNC_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        awaitingSync: action.payload,
      };
    case DASHBOARD_AWAITINGSYNC_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        awaitingSync: action.payload,
      };
    case DASHBOARD_AWAITINGSYNC_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        awaitingSync: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardCardReducer;
