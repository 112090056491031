import {ROUTES_NAME,} from "../../config/constants";
import { ACCESS_TOKEN } from "../../constants";
import {REPORT_ERROR, REPORT_REQUEST, REPORT_SUCCESS} from "./ActionType";

export const reportsApi = () => {
    return(dispatch) => {
        dispatch(reportDataRequest());
        const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.report}`;
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({
              page: 0,
              pageLength: 10,
            }),
            credentials: 'include',
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
              'Content-Type': 'application/json',
              'Origin': `${process.env.REACT_APP_URL}`
            },
          })
          .then((res) => res.json())
          .then((tableData) => {
            dispatch(reportDataSuccess(tableData));
          })
          .catch((data) => {
            console.log(data,'error');
            dispatch(reportDataError());
          });
    }
}

function reportDataRequest(reportData) {
    return {
      type: REPORT_REQUEST,
      payload: reportData,
    };
  }
  function reportDataSuccess(reportData) {
    return {
      type: REPORT_SUCCESS,
      payload: reportData,
    };
  }
  function reportDataError(reportData) {
    return {
      type: REPORT_ERROR,
      payload: reportData,
    };
  }
  