import {
  CACHE_SYNC_ERROR,
  CACHE_SYNC_REQUEST,
  CACHE_SYNC_SUCCESS,
} from "./ActionType";

const initialState = {
  isRequest: false,
  isSuccess: false,
  isError: false,
  cacheSync: {},
};

const cacheSyncReducer = (state = initialState, action) => {
  switch (action.type) {
    case CACHE_SYNC_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        cacheSync: action.payload,
      };
    case CACHE_SYNC_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        cacheSync: action.payload,
      };
    case CACHE_SYNC_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        cacheSync: action.payload,
      };
    default:
      return state;
  }
};

export default cacheSyncReducer;
