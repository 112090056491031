import {
  NETWORTH_ERROR,
  NETWORTH_REQUEST,
  NETWORTH_SUCCESS,
  SYNCED_ERROR,
  SYNCED_REQUEST,
  SYNCED_SUCCESS,
  SYNCED_LAST_TEN_DAYS_ERROR,
  SYNCED_LAST_TEN_DAYS_REQUEST,
  SYNCED_LAST_TEN_DAYS_SUCCESS,
  RECEIVED_LAST_TEN_DAYS_ERROR,
  RECEIVED_LAST_TEN_DAYS_REQUEST,
  RECEIVED_LAST_TEN_DAYS_SUCCESS,
  LINKED_PAYPAL_ERROR,
  LINKED_PAYPAL_REQUEST,
  LINKED_PAYPAL_SUCCESS,
  DASHBOARD_ALL_ERROR,
  DASHBOARD_ALL_REQUEST,
  DASHBOARD_ALL_SUCCESS,
  FAILED_ERROR,
  FAILED_REQUEST,
  FAILED_SUCCESS,
  MISSING_ERROR,
  MISSING_REQUEST,
  MISSING_SUCCESS,
  QUEUED_REQUEST,
  QUEUED_SUCCESS,
  QUEUED_ERROR,
  PENDING_SUCCESS,
  PENDING_ERROR,
  PENDING_REQUEST,
  ADVANCE_SEARCH_REQUEST,
  ADVANCE_SEARCH_SUCCESS,
  ADVANCE_SEARCH_ERROR,
  INVALID_GATEWAY_SUCCESS,
  INVALID_GATEWAY_REQUEST,
  INVALID_GATEWAY_ERROR,
} from "./ActionType";
const initialState = {
  isRequest: false,
  isSuccess: false,
  isError: false,
  data: {},
  synced: {},
  syncedLastTenDays: [],
  receivedLastTenDays: [],
  linked_paypal: [],
  dashboard_all: {},
  failed: {},
  missing: {},
  queued: {},
  pending: {},
  advanceSearch: {},
  invalid_gateway: {},
};

const storeDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case NETWORTH_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        data: action.payload,
      };
    case NETWORTH_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        data: action.payload,
      };
    case NETWORTH_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        data: action.payload,
      };

    case SYNCED_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        synced: action.payload,
      };
    case SYNCED_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        synced: action.payload,
      };
    case SYNCED_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        synced: action.payload,
      };
    case SYNCED_LAST_TEN_DAYS_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        syncedLastTenDays: action.payload,
      };
    case SYNCED_LAST_TEN_DAYS_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        syncedLastTenDays: action.payload,
      };
    case SYNCED_LAST_TEN_DAYS_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        syncedLastTenDays: action.payload,
      };
    case RECEIVED_LAST_TEN_DAYS_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        receivedLastTenDays: action.payload,
      };
    case RECEIVED_LAST_TEN_DAYS_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        receivedLastTenDays: action.payload,
      };
    case RECEIVED_LAST_TEN_DAYS_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        receivedLastTenDays: action.payload,
      };
    case LINKED_PAYPAL_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        linked_paypal: action.payload,
      };
    case LINKED_PAYPAL_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        linked_paypal: action.payload,
      };
    case LINKED_PAYPAL_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        linked_paypal: action.payload,
      };
    case DASHBOARD_ALL_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        dashboard_all: action.payload,
      };
    case DASHBOARD_ALL_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        dashboard_all: action.payload,
      };
    case DASHBOARD_ALL_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        dashboard_all: action.payload,
      };
    case FAILED_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        failed: action.payload,
      };
    case FAILED_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        failed: action.payload,
      };
    case FAILED_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        failed: action.payload,
      };
    case MISSING_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        missing: action.payload,
      };
    case MISSING_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        missing: action.payload,
      };
    case MISSING_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        missing: action.payload,
      };
    case QUEUED_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        queued: action.payload,
      };
    case QUEUED_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        queued: action.payload,
      };
    case QUEUED_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        queued: action.payload,
      };
    case PENDING_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        pending: action.payload,
      };
    case PENDING_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        pending: action.payload,
      };
    case PENDING_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        pending: action.payload,
      };
    case ADVANCE_SEARCH_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        advanceSearch: action.payload,
      };
    case ADVANCE_SEARCH_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        advanceSearch: action.payload,
      };
    case ADVANCE_SEARCH_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        advanceSearch: action.payload,
      };

    case INVALID_GATEWAY_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        invalid_gateway: action.payload,
      };
    case INVALID_GATEWAY_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        invalid_gateway: action.payload,
      };
    case INVALID_GATEWAY_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        invalid_gateway: action.payload,
      };
    default:
      return state;
  }
};

export default storeDashboardReducer;
