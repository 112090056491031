import {
  PULL_LOGS_ERROR,
  PULL_LOGS_REQUEST,
  PULL_LOGS_SUCCESS,
} from "./ActionType";
const initialState = {
  isRequest: false,
  isSuccess: false,
  isError: false,
  data: {},
};

const pullLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case PULL_LOGS_REQUEST:
      return {
        isRequest: true,
        isSuccess: false,
        isError: false,
        data: action.payload,
      };
    case PULL_LOGS_SUCCESS:
      return {
        isRequest: false,
        isSuccess: true,
        isError: false,
        data: action.payload,
      };
    case PULL_LOGS_ERROR:
      return {
        isRequest: false,
        isSuccess: false,
        isError: true,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default pullLogReducer;