import React, { useEffect } from "react";
import { Layout, LegacyCard } from "@shopify/polaris";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getDashboardTotalSyncedYesterday,
  getDashboardTotalSyncedToday,
  getDashboardTotalSyncedLastTwoDays,
  getDashboardTotalSyncedLastThirtyDays,
  getDashboardTotalSyncedLastSevenDays,
  getDashboardTotalStores,
  getdashboardTotalOrders,
  getdashboardTotalPaypalUploads,
  getdashboardTotalActive,
  getdashboardAwaitingSync,
} from "../../../redux/dashboard/Action";
import { Skeleton } from "@mui/material";

const DashboardCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    totalSyncedYesterday,
    totalSyncedToday,
    lastTwoDays,
    lastThirtyDays,
    lastSevenDays,
    totalStores,
    totalPaypalUpload,
    totalOrders,
    totalActiveOrder,
    awaitingSync,
  } = useSelector((store) => store.requriedDashboardCardData);

  const dashboardcard = useSelector((store) => store.requriedDashboardCardData);

  useEffect(() => {
    dispatch(getDashboardTotalSyncedYesterday());
    dispatch(getDashboardTotalSyncedToday());
    dispatch(getDashboardTotalSyncedLastTwoDays());
    dispatch(getDashboardTotalSyncedLastThirtyDays());
    dispatch(getDashboardTotalSyncedLastSevenDays());
    dispatch(getDashboardTotalStores());
    dispatch(getdashboardTotalOrders());
    dispatch(getdashboardTotalPaypalUploads());
    dispatch(getdashboardTotalActive());
    dispatch(getdashboardAwaitingSync());
  }, [dispatch]);

  useEffect(() => {
    if(totalSyncedYesterday?.message === "Actual error message" || 
      totalSyncedToday?.message === "Actual error message" || 
      lastTwoDays?.message === "Actual error message" || 
      lastThirtyDays?.message === "Actual error message" || 
      lastSevenDays?.message === "Actual error message" || 
      totalStores?.message === "Actual error message" || 
      totalPaypalUpload?.message === "Actual error message" || 
      totalOrders?.message === "Actual error message" || 
      totalActiveOrder?.message === "Actual error message" || 
      awaitingSync?.message === "Actual error message") {
        navigate("/logout")
    }
  },[totalSyncedYesterday, totalSyncedToday, lastTwoDays, lastThirtyDays, lastSevenDays, totalStores, totalPaypalUpload, totalOrders, totalActiveOrder, awaitingSync, navigate])

  return (
    <Layout.Section>
      <div
        style={{
          display: "flex",
          gap: "20px",
          padding: "0px 30px",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        <div className="dashboardCardWidth">
          <LegacyCard sectioned>
            <div className="card_alignment">
              <p className="card_title">Total store</p>
              {dashboardcard.isRequest ? (
                <Skeleton />
              ) : (
                <p className="count">{totalStores ? totalStores.count : 0}</p>
              )}
            </div>
          </LegacyCard>
        </div>
        <div className="dashboardCardWidth">
          <LegacyCard sectioned>
            <div className="card_alignment">
              <p className="card_title">Active stores</p>
              {dashboardcard.isRequest ? (
                <Skeleton />
              ) : (
                <p className="count">
                  {totalActiveOrder ? totalActiveOrder.count : 0}
                </p>
              )}
            </div>
          </LegacyCard>
        </div>
        <div className="dashboardCardWidth">
          <LegacyCard sectioned>
            <div className="card_alignment">
              <p className="card_title">Orders received</p>
              {dashboardcard.isRequest ? (
                <Skeleton />
              ) : (
                <p className="count">{totalOrders ? totalOrders.count : 0}</p>
              )}
            </div>
          </LegacyCard>
        </div>
        <div className="dashboardCardWidth">
          <LegacyCard sectioned>
            <div className="card_alignment">
              <p className="card_title">Uploads to PayPal</p>
              {dashboardcard.isRequest ? (
                <Skeleton />
              ) : (
                <p className="count">
                  {totalPaypalUpload ? totalPaypalUpload.count : 0}
                </p>
              )}
            </div>
          </LegacyCard>
        </div>
        <div className="dashboardCardWidth">
          <LegacyCard sectioned>
            <div className="card_alignment">
              <p className="card_title">Awaiting sync</p>
              {dashboardcard.isRequest ? (
                <Skeleton />
              ) : (
                <p className="count">{awaitingSync ? awaitingSync.count : 0}</p>
              )}
            </div>
          </LegacyCard>
        </div>
        <div className="dashboardCardWidth">
          <LegacyCard sectioned>
            <div className="card_alignment">
              <p className="card_title">Synced today</p>
              {dashboardcard.isRequest ? (
                <Skeleton />
              ) : (
                <p className="count">
                  {totalSyncedToday ? totalSyncedToday.count : 0}
                </p>
              )}
            </div>
          </LegacyCard>
        </div>
        <div className="dashboardCardWidth">
          <LegacyCard sectioned>
            <div className="card_alignment">
              <p className="card_title">Synced yesterday</p>
              {dashboardcard.isRequest ? (
                <Skeleton />
              ) : (
                <p className="count">
                  {totalSyncedYesterday ? totalSyncedYesterday.count : 0}
                </p>
              )}
            </div>
          </LegacyCard>
        </div>
        <div className="dashboardCardWidth">
          <LegacyCard sectioned>
            <div className="card_alignment">
              <p className="card_title">Synced two days ago</p>
              {dashboardcard.isRequest ? (
                <Skeleton />
              ) : (
                <p className="count">{lastTwoDays ? lastTwoDays.count : 0}</p>
              )}
            </div>
          </LegacyCard>
        </div>
        <div className="dashboardCardWidth">
          <LegacyCard sectioned>
            <div className="card_alignment">
              <p className="card_title">Synced last 7 days</p>
              {dashboardcard.isRequest ? (
                <Skeleton />
              ) : (
                <p className="count">
                  {lastSevenDays ? lastSevenDays.count : 0}
                </p>
              )}
            </div>
          </LegacyCard>
        </div>
        <div className="dashboardCardWidth">
          <LegacyCard sectioned>
            <div className="card_alignment">
              <p className="card_title">Synced last 30 days</p>
              {dashboardcard.isRequest ? (
                <Skeleton />
              ) : (
                <p className="count">
                  {lastThirtyDays ? lastThirtyDays.count : 0}
                </p>
              )}
            </div>
          </LegacyCard>
        </div>
      </div>
    </Layout.Section>
  );
};

export default DashboardCard;
