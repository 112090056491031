import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Layout, LegacyCard} from "@shopify/polaris";
import { ACCESS_TOKEN } from "../../../constants";
import { OrderCheckerForm } from "./OrderCheckerForm";
import { OrderCheckList } from "./OrderCheckList";
import "./style.css";

export function OrderChecker() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState();
  const [checkedAt, setCheckedAt] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const store = useSelector(
    (store) => store.requireStoreDetailsRedycer.findById,
  );

  const handleSubmit = (orderNum) => {
    if (!orderNum) {
      setError("Enter order number or ID to start the check");
      return;
    }

    setLoading(true);
    setData(null);
    setError(null);
    setShowDetails(false);

    fetchOrderData(store.myShopifyDomain, orderNum)
      .then((res) => {
        if (res.status === 401) {
          getAccessTokenSilently().then((token) => {
            localStorage.setItem(ACCESS_TOKEN, token);
          });
        }
        return res.json()
      })
      .then((data) => {
        if (data.error) {
          setError(data.error);
        } else {
          setData(data);
          setCheckedAt(new Date());
        }
      })
      .catch(() => setError("Something went wrong. Please try again"))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Layout.Section>
        <div className="wrapper">
          <LegacyCard sectioned>
            <div style={{ padding: "15px" }}>
              <LegacyCard sectioned>
                <div className="orderChecker-content">
                  <div>
                    <OrderCheckerForm
                      enabled={!!store}
                      error={error}
                      loading={loading}
                      dataReady={!!data?.order}
                      showDetails={showDetails}
                      onSubmit={handleSubmit}
                      onShowDetails={() => setShowDetails(!showDetails)}
                    />
                    {data && (
                      <OrderCheckList
                        data={data}
                        shop={store.myShopifyDomain}
                        checkedAt={checkedAt}
                      />
                    )}
                  </div>
                  {showDetails && (
                    <pre>{formatOrderData(data.order)}</pre>
                  )}
                </div>
              </LegacyCard>
            </div>
          </LegacyCard>
        </div>
      </Layout.Section>
    </>
  );
}

function formatOrderData(data) {
  if (!data) {
    return 'No data found';
  }

  const {transactions, ...order} = data;
  const formattedTransactions = transactions.map(({ receiptJson, ...transaction }) => {

    return {
      ...transaction,
      receiptJson: receiptJson && receiptJson.startsWith("{")
        ? JSON.parse(receiptJson)
        : null,
    };
  })

  return JSON.stringify(
    {
      ...order,
      transactions: formattedTransactions,
    },
    null,
    2,
  );
}

async function fetchOrderData(shop, orderNum) {
  const url = `${process.env.REACT_APP_SHOPIFY_APP_API_URL}/manage-api/find-order`;

  return await fetch(url, {
    method: "POST",
    body: JSON.stringify({ shop, orderNum }),
    headers: {
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Content-Type": "application/json",
    },
  });
}
