import {
  SHOP_COUNTRIES_ERROR,
  SHOP_COUNTRIES_REQUEST,
  SHOP_COUNTRIES_SUCCESS,
} from "./ActionType";
const initialState = {
  isRequest: false,
  isSuccess: false,
  isError: false,
  data: {},
};

const shopCountriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOP_COUNTRIES_REQUEST:
      return {
        isRequest: true,
        isSuccess: false,
        isError: false,
        data: action.payload,
      };

    case SHOP_COUNTRIES_SUCCESS:
      return {
        isRequest: false,
        isSuccess: true,
        isError: false,
        data: action.payload,
      };

    case SHOP_COUNTRIES_ERROR:
      return {
        isRequest: false,
        isSuccess: false,
        isError: true,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default shopCountriesReducer;
