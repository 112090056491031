import {ROUTES_NAME} from "../../../config/constants";
import { ACCESS_TOKEN } from "../../../constants";
import {PAYPAL_SETTING_ERROR, PAYPAL_SETTING_REQUEST, PAYPAL_SETTING_SUCCESS} from "./ActionType";

export const paypalSettingApi = (payload) => {
  const {storeId, page} = payload
    return (dispatch) => {
        dispatch(paypalSettingRequest());
        const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.paypalSetting}`;
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({
              page: page,
              pageLength: 10,
              storeId:storeId
            }),
            credentials: 'include',
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
                'Content-Type': 'application/json',
                'Origin': `${process.env.REACT_APP_URL}`
            },
          })
          .then((res) => res.json())
          .then((tableData) => {
            dispatch(paypalSettingSuccess(tableData));
          })
          .catch((data) => {
            console.log(data,"error");
            dispatch(paypalSettingError());
          })
    }
}

function paypalSettingRequest(paypalSettingData) {
    return {
      type: PAYPAL_SETTING_REQUEST,
      payload: paypalSettingData,
    };
  }
  function paypalSettingSuccess(paypalSettingData) {
    return {
      type: PAYPAL_SETTING_SUCCESS,
      payload: paypalSettingData,
    };
  }
  function paypalSettingError(paypalSettingData) {
    return {
      type: PAYPAL_SETTING_ERROR,
      payload: paypalSettingData,
    };
  }
  