import React from "react";
import CongratulationsBanner from "./CongratulationBanner";
import Table from "./Table";
import { Page } from "@shopify/polaris";

const UserDashboard = () => {
  return (
    <>
      <Page fullWidth>
        <CongratulationsBanner />
        <Table />
      </Page>
    </>
  );
};

export default UserDashboard;
