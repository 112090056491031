import {
  PAYPAL_SETTING_ERROR,
  PAYPAL_SETTING_REQUEST,
  PAYPAL_SETTING_SUCCESS,
} from "./ActionType";
const initialState = {
  isRequest: false,
  isSuccess: false,
  isError: false,
  data: [],
};

const paypalSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYPAL_SETTING_REQUEST:
      return {
        isRequest: true,
        isSuccess: false,
        isError: false,
        data: action.payload,
      };

    case PAYPAL_SETTING_SUCCESS:
      return {
        isRequest: false,
        isSuccess: true,
        isError: false,
        data: action.payload,
      };
    case PAYPAL_SETTING_ERROR:
      return {
        isRequest: false,
        isSuccess: false,
        isError: true,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default paypalSettingReducer;