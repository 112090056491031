import {
  STATS_SUCCESS, STATS_ERROR, STATS_REQUEST,
  TOTAL_ORDERS_REQUEST, TOTAL_ORDERS_SUCCESS, TOTAL_ORDERS_ERROR,
  AWAITING_SYNC_ORDERS_ERROR, AWAITING_SYNC_ORDERS_REQUEST, AWAITING_SYNC_ORDERS_SUCCESS, TOTAL_SYNC_ORDERS_ERROR, TOTAL_SYNC_ORDERS_REQUEST, TOTAL_SYNC_ORDERS_SUCCESS

} from "./ActionType";
const initialState = {
  isRequest: false,
  isSuccess: false,
  isError: false,
  data: {},
  totalOrders: {},
  awaitingOrders: {},
  totalSyncOrders: {}

};

const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATS_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        data: action.payload,
      };
    case STATS_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        data: action.payload,
      };
    case STATS_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        data: action.payload,
      };

    case TOTAL_ORDERS_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        totalOrders: action.payload,
      };


    case TOTAL_ORDERS_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        totalOrders: action.payload,
      };

    case TOTAL_ORDERS_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        totalOrders: action.payload,
      };

    case AWAITING_SYNC_ORDERS_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        awaitingOrders: action.payload,
      };


    case AWAITING_SYNC_ORDERS_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        awaitingOrders: action.payload,
      };

    case AWAITING_SYNC_ORDERS_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        awaitingOrders: action.payload,
      };


    case TOTAL_SYNC_ORDERS_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        totalSyncOrders: action.payload,
      };


    case TOTAL_SYNC_ORDERS_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        totalSyncOrders: action.payload,
      };

    case TOTAL_SYNC_ORDERS_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        totalSyncOrders: action.payload,
      };


    default:
      return state;
  }
};

export default statsReducer;