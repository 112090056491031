export const STATS_ERROR = 'STATS_ERROR';
export const STATS_REQUEST = 'STATS_REQUEST';
export const STATS_SUCCESS = 'STATS_SUCCESS';

export const TOTAL_ORDERS_ERROR = `TOTAL_ORDERS_ERROR`;
export const TOTAL_ORDERS_SUCCESS = `TOTAL_ORDERS_SUCCESS`;
export const TOTAL_ORDERS_REQUEST = `TOTAL_ORDERS_REQUEST`;

export const AWAITING_SYNC_ORDERS_ERROR = `AWAITING_SYNC_ORDERS_ERROR`;
export const AWAITING_SYNC_ORDERS_SUCCESS = `AWAITING_SYNC_ORDERS_SUCCESS`;
export const AWAITING_SYNC_ORDERS_REQUEST = `AWAITING_SYNC_ORDERS_REQUEST`;

export const TOTAL_SYNC_ORDERS_ERROR = `TOTAL_SYNC_ORDERS_ERROR`;
export const TOTAL_SYNC_ORDERS_SUCCESS = `TOTAL_SYNC_ORDERS_SUCCESS`;
export const TOTAL_SYNC_ORDERS_REQUEST = `TOTAL_SYNC_ORDERS_REQUEST`;

