import {ROUTES_NAME} from "../../../config/constants";
import { ACCESS_TOKEN } from "../../../constants";
import {FIND_BY_ID_ERROR, FIND_BY_ID_REQUEST, FIND_BY_ID_SUCCESS} from "./ActionType";

export const findByIdApi  = (id)=>{
    return (dispatch) => {
        dispatch(finByIDRequest());
        const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.find_detail}`;
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({
              page: 0,
              pageLength: 10,
              storeId: id
            }),
            credentials: 'include',
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
                'Content-Type': 'application/json',
                'Origin': `${process.env.REACT_APP_URL}`
            },
          })
          .then((res) => res.json())
          .then((tableData) => {
            dispatch(finByIDSuccess(tableData));
          })
          .catch((data) => {
            console.log(data,"error");
            dispatch(finByIDError());
          })
    }
}

function finByIDRequest(findByIdData) {
    return {
      type: FIND_BY_ID_REQUEST,
      payload: findByIdData,
    };
  }
  function finByIDSuccess(findByIdData) {
    return {
      type: FIND_BY_ID_SUCCESS,
      payload: findByIdData,
    };
  }
  function finByIDError(findByIdData) {
    return {
      type: FIND_BY_ID_ERROR,
      payload: findByIdData,
    };
  }
  