import {
    FIND_BY_ID_ERROR, FIND_BY_ID_REQUEST, FIND_BY_ID_SUCCESS
  } from "./ActionType";
  const initialState = {
    isRequest: false,
    isSuccess: false,
    isError: false,
    data: {},
  };
  
  const findByIDReducer = (state = initialState, action) => {
    switch (action.type) {
      case FIND_BY_ID_REQUEST:
        return {
          isRequest: true,
          isSuccess: false,
          isError: false,
          data: action.payload,
        };
  
      case FIND_BY_ID_SUCCESS:
        return {
          isRequest: false,
          isSuccess: true,
          isError: false,
          data: action.payload,
        };
      case FIND_BY_ID_ERROR:
        return {
          isRequest: false,
          isSuccess: false,
          isError: true,
          data: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default findByIDReducer;