import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Layout, Spinner } from "@shopify/polaris";
import "./style.css";
import LatestStore from "./table/LatestStore";
import DashboardCard from "./dashboardCard";
import Header from "../../components/header";
import { ACCESS_TOKEN } from "../../constants";

const Dashboard = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [authToken, setAuthToken] = useState('');

  const tokenHandler = async () => {
    const token = await getAccessTokenSilently();
    localStorage.setItem(ACCESS_TOKEN, token);
    setAuthToken(token);
  }

  useEffect(() => {
    tokenHandler()
  }, [user?.email])

  return (
    <Layout>
      <Header title="Dashboard" />
      {
        authToken ? (
          <>
            <DashboardCard />
            <Layout.Section>
              <LatestStore />
            </Layout.Section>
          </>
        ) : (
          <div className='d-flex justify-content-center aligns-Items-center h-100vh w-100'>
            <Spinner accessibilityLabel="Spinner example" size="large" />
          </div>
        )
      }
    </Layout>
  );
};

export default Dashboard;
