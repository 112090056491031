import {
  ALL_COUNTRIES_ERROR,
  ALL_COUNTRIES_REQUEST,
  ALL_COUNTRIES_SUCCESS,
} from "./ActionType";
const initialState = {
  isRequest: false,
  isSuccess: false,
  isError: false,
  data: {},
};

const allCountriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_COUNTRIES_REQUEST:
      return {
        isRequest: true,
        isSuccess: false,
        isError: false,
        data: action.payload,
      };

    case ALL_COUNTRIES_SUCCESS:
      return {
        isRequest: false,
        isSuccess: true,
        isError: false,
        data: action.payload,
      };

    case ALL_COUNTRIES_ERROR:
      return {
        isRequest: false,
        isSuccess: false,
        isError: true,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default allCountriesReducer;
