import {ROUTES_NAME} from "../../../config/constants";
import { ACCESS_TOKEN } from "../../../constants";
import {EVENTS_ERROR, EVENTS_REQUEST, EVENTS_SUCCESS} from './ActionType';

export const eventApi = (payload) => {
  const {storeId, page} = payload
    return (dispatch) => {
        dispatch(eventsRequest());
        const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.events}`;
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({
              page: page,
              pageLength: 10,
              storeId:storeId
            }),
            credentials: 'include',
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
                'Content-Type': 'application/json',
                'Origin': `${process.env.REACT_APP_URL}`
            },
          })
          .then((res) => res.json())
          .then((tableData) => {
            dispatch(eventsSuccess(tableData));
          })
          .catch((data) => {
            console.log(data,"error");
            dispatch(eventsError());
          })
    }
}

function eventsRequest(eventData) {
    return {
      type: EVENTS_REQUEST,
      payload: eventData,
    };
  }
  function eventsSuccess(eventData) {
    return {
      type: EVENTS_SUCCESS,
      payload: eventData,
    };
  }
  function eventsError(eventData) {
    return {
      type: EVENTS_ERROR,
      payload: eventData,
    };
  }
  