import {Banner} from "@shopify/polaris";
import React from "react";

function SuccessBanner(props) {
  return (
    <div className="mb-1" style={{marginTop:"33px", display: props.message &&  props.runTime ? "" : "none"}}>
    <Banner title="Success" status="success">
      <p>{props.message}</p>
    </Banner>
    </div>
  );
}

export default SuccessBanner;
