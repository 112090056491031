import { COURIER_MAPPING_TOGGLE_REQUEST, COURIER_MAPPING_TOGGLE_ERROR, COURIER_MAPPING_TOGGLE_SUCCESS } from "./ActionType";
import { ROUTES_NAME } from "../../../config/constants";
import { ACCESS_TOKEN } from "../../../constants";

export const courierSyncStatus = (payload) => {
    const {state, shopId, storeType} = payload;
    return (dispatch) => {
      dispatch(courierSyncRequest());
      const url = `${
        process.env.REACT_APP_BACKEND_API_URL
      }${ROUTES_NAME.courier_mapping(shopId, storeType)}`;
      fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          value: state,
        }),
        credentials: 'include',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
          'Content-Type': 'application/json',
          'Origin': `${process.env.REACT_APP_URL}`
        },
      })
        .then((res) => res.json())
        .then((response) => {
          dispatch(courierSyncSuccess(response));
        })
        .catch((data) => {
          console.log(data, "error");
          dispatch(courierSyncError());
        });
    };
  };
  
  function courierSyncRequest(data) {
    return {
      type: COURIER_MAPPING_TOGGLE_REQUEST,
      payload: data,
    };
  }
  function courierSyncSuccess(data) {
    return {
      type: COURIER_MAPPING_TOGGLE_SUCCESS,
      payload: data,
    };
  }
  function courierSyncError(data) {
    return {
      type: COURIER_MAPPING_TOGGLE_ERROR,
      payload: data,
    };
  }
  