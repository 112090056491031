import Main from "./main";
import {Route, Routes, useNavigate} from "react-router-dom";
import {useState} from "react";
// import Login from "./login/Login";
import LoginPage from "./pages/login/Login";
import LoginCallback from "./login/LoginCallback";
import OAuth2RedirectHandler from "./login/OAuth2RedirectHandler";
import Profile from "./login/Profile";
import { GOOGLE_AUTH_URL } from "./constants";
import googleLogo from "./assets/google-logo.png";
import Dashboard from "./pages/dashboard";
import React, { Component } from "react";

import AppHeader from "./common/AppHeader";
import PrivateRoute from "./common/PrivateRoute";
import NotFound from "./common/NotFound";
import { getCurrentUser } from "./util/APIUtils";
import { ACCESS_TOKEN } from "./constants";
import LoadingIndicator from "./common/LoadingIndicator";
import Logout from "./components/logout/Logout";

const App = () => {
   const [state, setState] = useState("");
   const [authenticated, setAuthenticated] = useState(false);
   const [currentUser, setCurrentUser] = useState(null);
   const [loading, setLoading] = useState(false);
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //       authenticated: false,
    //       currentUser: null,
    //       loading: false
    //     };

    //     // this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this);
    //     // this.handleLogout = this.handleLogout.bind(this);
    //   }

      // loadCurrentlyLoggedInUser() {
      //   this.setState({
      //     loading: true
      //   });

      //   getCurrentUser()
      //     .then(response => {
      //       this.setState({
      //         currentUser: response,
      //         authenticated: true,
      //         loading: false
      //       });
      //     })
      //     .catch(error => {
      //       this.setState({
      //         loading: false
      //       });
      //     });
      // }

    const handleLogout  =()=> {
        localStorage.removeItem(ACCESS_TOKEN);
        this.setState({
          authenticated: false,
          currentUser: null
        });
       console.log("You're safely logged out!");
      }

      // componentDidMount() {
      //   this.loadCurrentlyLoggedInUser();
      // }

      // render() {
      //   if (this.state.loading) {
      //     return <LoadingIndicator />;
      //   }

  return (
    <div className="app">
            <div className="app-top-box">
              {/* <AppHeader authenticated={this.state.authenticated} onLogout={this.handleLogout} /> */}
            </div>
            <div className="app-body">
            <Routes>
                <Route exact path="/"
                //  element={<Login   />}
                element={<LoginPage />}
                 >
                </Route>
                <Route path="/logout" element={<Logout />} />
                <Route
                  path="/profile"
                  // authenticated={this.state.authenticated}
                  // currentUser={this.state.currentUser}
                  component={Profile}
                ></Route>
                <Route
                  path="/login"
                  // element={<Login   />}
                  element={<LoginPage />}
                ></Route>
                <Route path="/oauth2/redirect" element={<OAuth2RedirectHandler />}></Route>
                <Route component={NotFound}></Route>
                </Routes>
                <Main state={state} setState={setState}/>
            </div>
          </div>
  );
}

export default App;
