import {
  FIND_BY_ID_SUCCESS,
  FIND_BY_ID_ERROR,
  FIND_BY_ID_REQUEST,
} from "./ActionType";

const initialState = {
  isRequest: false,
  isSuccess: false,
  isError: false,
  findById: {},
};

const findByIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIND_BY_ID_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        findById: action.payload,
      };
    case FIND_BY_ID_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        findById: action.payload,
      };
    case FIND_BY_ID_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        findById: action.payload,
      };
    default:
      return state;
  }
};

export default findByIdReducer;