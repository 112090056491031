import {ROUTES_NAME} from "../../../config/constants";
import { ACCESS_TOKEN } from "../../../constants";
import {PROCESSES_ERROR, PROCESSES_REQUEST, PROCESSES_SUCCESS} from './ActionType';

export const processesApi = (payload) => {
  const {storeId, page} = payload;
    return (dispatch) => {
        dispatch(processesRequest());
        const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.processes}`;
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({
              page: page,
              pageLength: 10,
              storeId:storeId,
            }),
            credentials: 'include',
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
                'Content-Type': 'application/json',
                'Origin': `${process.env.REACT_APP_URL}`
            },
          })
          .then((res) => res.json())
          .then((tableData) => {
            dispatch(processesSuccess(tableData));
          })
          .catch((data) => {
            console.log(data,"error");
            dispatch(processesError());
          })
    }
}

function processesRequest(processesData) {
    return {
      type: PROCESSES_REQUEST,
      payload: processesData,
    };
  }
  function processesSuccess(processesData) {
    return {
      type: PROCESSES_SUCCESS,
      payload: processesData,
    };
  }
  function processesError(processesData) {
    return {
      type: PROCESSES_ERROR,
      payload: processesData,
    };
  }
  