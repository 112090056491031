import React, { useEffect } from "react";
import Header from "../../components/header";
import {
  Layout,
  LegacyCard,
  Select,
  Pagination,
  Divider,
} from "@shopify/polaris";
import GdprTable from "./Table";
import { useState, useCallback } from "react";
import { getgdprData } from "../../redux/gdpr/Action";
import { useSelector, useDispatch } from "react-redux";

const Gdpr = () => {
  const [gdprContant, setGdprContant] = useState([]);
  const [type, setType] = useState("SHOP_REDACT");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const dispatch = useDispatch();

  const totalGDPRList = useSelector((store) => store.requiredGdprReducer);

  useEffect(() => {
    const payload = {
      type: type,
      page: 0,
      storeType:"shopify"
    };
    dispatch(getgdprData(payload));
    setCurrentPage(0);
  }, [type]);

  useEffect(() => {
    if (totalGDPRList && totalGDPRList.isSuccess && totalGDPRList.gdprData.content) {
      setGdprContant(totalGDPRList.gdprData.content);
      setPageCount(totalGDPRList.gdprData.totalPages);
    }
  }, [totalGDPRList]);

  const handleNumberofEntries = useCallback(
    (newValue) => setType(newValue),
    []
  );

  const displayLenghtOptions = [
    { label: "Shop redact", value: "SHOP_REDACT" },
    { label: "Customer redact", value: "CUSTOMER_REDACT" },
    { label: "Customer request", value: "CUSTOMER_REQUEST" },
  ];

  const handlePreviousPagination = () => {
    if (currentPage === 0) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
      const payload = {
        type: type,
        page: currentPage - 1,
        storeType:"shopify"
      };
      dispatch(getgdprData(payload));
    }
  };
  const handleNextPagination = () => {
    if (currentPage === pageCount - 1) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
      const payload = {
        type: type,
        page: currentPage + 1,
        storeType:"shopify"
      };
      dispatch(getgdprData(payload));
    }
  };
  return (
    <Layout>
      <Header title="GDPR" />
      <Layout.Section>
        <div className="wrapper">
          <LegacyCard sectioned>
            <div className="d-flex justify-content-between flex-wrap">
              <div>
                <Select
                  label="Type"
                  options={displayLenghtOptions}
                  onChange={handleNumberofEntries}
                  value={type}
                />
              </div>
            </div>
            <div className="mt-10">
              <GdprTable gdprContant={gdprContant} />
            </div>
            <Divider />
            <div
              className="mt-10 justify-content-center"
              style={{ display: pageCount > 1 ? "flex" : "none" }}
            >
              <Pagination
                label={currentPage + 1}
                hasPrevious
                onPrevious={() => {
                  handlePreviousPagination();
                }}
                hasNext
                onNext={() => {
                  handleNextPagination();
                }}
              />
            </div>
          </LegacyCard>
        </div>
      </Layout.Section>
    </Layout>
  );
};

export default Gdpr;
