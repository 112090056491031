import React from "react";
import Header from "../../components/header";
import { Layout } from "@shopify/polaris";
import "./style.css";
import Cards from "./cards/Cards";
import ButtonGroup from "./buttonSegment";
import ClientDetails from "./clientDetails";
import SecondButtonSegment from "./buttonSegment/fullFillmentButton";

const TroubleShoot = () => {
  return (
    <Layout>
      <Header title="Trouble Shoot" />
      <Cards />
      <ClientDetails />
      <SecondButtonSegment />
      <ButtonGroup />
    </Layout>
  );
};

export default TroubleShoot;
