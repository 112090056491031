import { Banner, Link } from "@shopify/polaris";
import React from "react";

function CriticalBanner(props) {
  return (
    <div className=" mb-1" style={{marginTop:"33px", display: props.message   && props.runTime ? "" : "none"}}>

    <Banner title="Failed" status="critical">
      <p>{props.message}</p>
    </Banner>
    </div>
  );
}

export default CriticalBanner;