import {Badge, DataTable, LegacyCard, useBreakpoints,} from "@shopify/polaris";

function CarrierTable() {
  const rows = [
    [
      "Jhon Doe",
      "Germany",
      <Badge progress="complete" status="success">
        Basic
      </Badge>,
      <Badge progress="complete" status="success">
        Basic
      </Badge>,
      <Badge progress="complete" status="success">
        Active
      </Badge>,
      <Badge progress="complete" status="critical">
        Declined
      </Badge>,
      "Oct 7th 2022, 12:45 PM",
      "Oct 7th 2022, 12:45 PM",
    ],
    [
        "Jhon Doe",
        "Germany",
        <Badge progress="complete" status="success">
          Basic
        </Badge>,
        <Badge progress="complete" status="success">
          Basic
        </Badge>,
        <Badge progress="complete" status="success">
          Active
        </Badge>,
        <Badge progress="complete" status="critical">
          Declined
        </Badge>,
        "Oct 7th 2022, 12:45 PM",
        "Oct 7th 2022, 12:45 PM",
      ],
      [
        "Jhon Doe",
        "Germany",
        <Badge progress="complete" status="success">
          Basic
        </Badge>,
        <Badge progress="complete" status="success">
          Basic
        </Badge>,
        <Badge progress="complete" status="success">
          Active
        </Badge>,
        <Badge progress="complete" status="critical">
          Declined
        </Badge>,
        "Oct 7th 2022, 12:45 PM",
        "Oct 7th 2022, 12:45 PM",
      ],
      [
        "Jhon Doe",
        "Germany",
        <Badge progress="complete" status="success">
          Basic
        </Badge>,
        <Badge progress="complete" status="success">
          Basic
        </Badge>,
        <Badge progress="complete" status="success">
          Active
        </Badge>,
        <Badge progress="complete" status="critical">
          Declined
        </Badge>,
        "Oct 7th 2022, 12:45 PM",
        "Oct 7th 2022, 12:45 PM",
      ],
      [
        "Jhon Doe",
        "Germany",
        <Badge progress="complete" status="success">
          Basic
        </Badge>,
        <Badge progress="complete" status="success">
          Basic
        </Badge>,
        <Badge progress="complete" status="success">
          Active
        </Badge>,
        <Badge progress="complete" status="critical">
          Declined
        </Badge>,
        "Oct 7th 2022, 12:45 PM",
        "Oct 7th 2022, 12:45 PM",
      ],
      [
        "Jhon Doe",
        "Germany",
        <Badge progress="complete" status="success">
          Basic
        </Badge>,
        <Badge progress="complete" status="success">
          Basic
        </Badge>,
        <Badge progress="complete" status="success">
          Active
        </Badge>,
        <Badge progress="complete" status="critical">
          Declined
        </Badge>,
        "Oct 7th 2022, 12:45 PM",
        "Oct 7th 2022, 12:45 PM",
      ],
      [
        "Jhon Doe",
        "Germany",
        <Badge progress="complete" status="success">
          Basic
        </Badge>,
        <Badge progress="complete" status="success">
          Basic
        </Badge>,
        <Badge progress="complete" status="success">
          Active
        </Badge>,
        <Badge progress="complete" status="critical">
          Declined
        </Badge>,
        "Oct 7th 2022, 12:45 PM",
        "Oct 7th 2022, 12:45 PM",
      ],
      [
        "Jhon Doe",
        "Germany",
        <Badge progress="complete" status="success">
          Basic
        </Badge>,
        <Badge progress="complete" status="success">
          Basic
        </Badge>,
        <Badge progress="complete" status="success">
          Active
        </Badge>,
        <Badge progress="complete" status="critical">
          Declined
        </Badge>,
        "Oct 7th 2022, 12:45 PM",
        "Oct 7th 2022, 12:45 PM",
      ],
  ];
  const { lgDown } = useBreakpoints();
  const fixedFirstColumns = lgDown ? 1 : 0;

  return (
    <div className="mt-2">
      <LegacyCard>
        <DataTable
          columnContentTypes={[
            "text",
            "numeric",
            "numeric",
            "numeric",
            "numeric",
            "numeric",
            "numeric",
          ]}
          headings={[
            "Name",
            "Country name",
            "Plan",
            "Shopify plan",
            "Paypal status",
            "Charges status",
            "Occoured at",
            "First activated at",
          ]}
          rows={rows}
          sortable={[false, true, false, false, true]}
          defaultSortDirection="descending"
          initialSortColumnIndex={4}
          stickyHeader
          fixedFirstColumns={fixedFirstColumns}
        />
      </LegacyCard>
      </div>
  );
}

export default CarrierTable;
