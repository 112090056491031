import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from '@shopify/polaris';

const Login = () => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();

    useEffect(() => {
        if (!isAuthenticated) {
            loginWithRedirect();
        }
    }, []);

    return (
        <div className='d-flex justify-content-center aligns-Items-center h-100vh'>
            <Spinner accessibilityLabel="Spinner example" size="large" />
        </div>
    );
};

export default Login;
