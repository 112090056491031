import {
  Badge,
  Button,
  Divider,
  Image,
  Layout,
  LegacyCard,
  Page,
  Pagination,
  Tooltip,
  EmptySearchResult,
  IndexTable
} from "@shopify/polaris";
import { React, useCallback, useEffect, useState } from "react";
import { Clear, SearchIcon } from "../../../assets";
import "./style.css";
import Table from "./Table";
import DateRangePicker from "../../../components/datepicker/DateRangePicker";
import CustomBadge from "../../../components/customBadge";
import {
  getAdvanceSearchData,
  getDashboardAllData,
  getFailedData,
  getMissingData,
  getPendingData,
  getQueuedData,
  getSyncedData,
  invalidGatewayData,
} from "../../../redux/store/dashboard/Action";
import { getResyncOrdersCount } from "../../../redux/store/orders/Action";
import { useDispatch, useSelector } from "react-redux";
import { Processed } from "../../../assets";
import { useNavigate, useParams } from "react-router-dom";
import { OrderTableSkeleton } from "../../../components/skeletonLoader";
import { useTranslation } from "react-i18next";
import moment from "moment";

const Orders = () => {
  const navigate = useNavigate();
  const { id, storeType } = useParams();
  const { t } = useTranslation();
  const [customers, setCustomer] = useState([]);
  const [popupOpen, setPopup] = useState(false);
  const [synced, setSynced] = useState(0);
  const [queue, setQueue] = useState(0);
  const [processing, setProcessing] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [failed, setFailed] = useState(0);
  const [invalid_gateway, setInvalid_gateway] = useState(0);
  const [missing, setMissing] = useState(0);
  const [allOrder, setAllOrders] = useState(0);
  const [pageCount, setPageCount] = useState();
  const [searchData, setSearchData] = useState("");
  const [resetDate, setResetDate] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const ordersData = useSelector((store) => store.requiredStoreDashboardData);
  const reSynceData = useSelector((store) => store.requiredStoreOrdersReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    sessionStorage.removeItem("orderStatus");
    const payload = {
      page: 0,
      ordersStatus: null,
      search: "",
      startDate: null,
      endDate: null,
      shopId: id,
      storeType: storeType
    };
    dispatch(getSyncedData(id, storeType));
    dispatch(getDashboardAllData(id, storeType));
    dispatch(getFailedData(id, storeType));
    dispatch(getMissingData(id, storeType));
    dispatch(getQueuedData(id, storeType));
    dispatch(getPendingData(id, storeType));
    dispatch(invalidGatewayData(id, storeType));
    dispatch(getAdvanceSearchData(payload));
  }, [dispatch,id,storeType]);

  useEffect(() => {
    if (
      ordersData.isSuccess &&
      ordersData.pending !== undefined &&
      ordersData.failed !== undefined &&
      ordersData.synced !== undefined &&
      ordersData.advanceSearch !== undefined &&
      ordersData.advanceSearch.shopifyFulfillmentActiveResponses !==
      undefined &&
      ordersData?.missing &&
      reSynceData
    ) {
      setMissing(ordersData?.missing?.count);
      setQueue(ordersData?.queued?.count);
      setSynced(ordersData?.synced?.count);
      setFailed(ordersData?.failed?.count);
      setInvalid_gateway(ordersData?.invalid_gateway?.count);
      setProcessing(ordersData?.pending?.count);
      setAllOrders(ordersData?.dashboard_all?.count);
      setCustomer(ordersData.advanceSearch.shopifyFulfillmentActiveResponses);
      setPageCount(ordersData.advanceSearch.totalPages);
    }
    if (ordersData?.pending?.message === "Actual error message" ||
      ordersData?.failed?.message === "Actual error message" ||
      ordersData?.synced?.message === "Actual error message" ||
      ordersData?.advanceSearch?.message === "Actual error message" ||
      ordersData?.missing?.message === "Actual error message"
    ) {
      navigate('/logout')
    }
  }, [ordersData, reSynceData, navigate]);

  useEffect(() => {
    if (reSynceData.isSuccess && openPopup) {
      setPopup(true);
    }
  }, [reSynceData,openPopup]);

  const handleResyncOrders = () => {
    dispatch(getResyncOrdersCount(id, storeType));
  };
  // const handleFiltering = (page, orderStatus, orderStatusValue) => { };

  const handlePreviousPagination = () => {
    if (currentPage === 0) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
      const payload = {
        search: searchData === "" ? null : searchData,
        ordersStatus: sessionStorage.getItem("orderStatus")
          ? sessionStorage.getItem("orderStatus")
          : null,
        startDate: sessionStorage.getItem("startDate")
          ? sessionStorage.getItem("startDate")
          : null,
        endDate: sessionStorage.getItem("endDate")
          ? sessionStorage.getItem("endDate")
          : null,
        page: currentPage - 1,
        shopId: id,
        storeType: storeType
      };
      dispatch(getAdvanceSearchData(payload));
    }
  };
  const handleOrdersFilter = (ordersStatus, search, startdate, endDate) => {
    setCurrentPage(0);
    const payload = {
      page: 0,
      ordersStatus: ordersStatus,
      search: searchData === "" ? null : searchData,
      startDate: sessionStorage.getItem("startDate")
        ? sessionStorage.getItem("startDate")
        : null,
      endDate: sessionStorage.getItem("endDate")
        ? sessionStorage.getItem("endDate")
        : null,
      shopId: id,
      storeType: storeType
    };
    dispatch(getAdvanceSearchData(payload));
  };

  const handleNextPagination = () => {
    if (currentPage === pageCount - 1) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
      const payload = {
        search: searchData === "" ? null : searchData,
        ordersStatus: sessionStorage.getItem("orderStatus")
          ? sessionStorage.getItem("orderStatus")
          : null,
        startDate: sessionStorage.getItem("startDate")
          ? sessionStorage.getItem("startDate")
          : null,
        endDate: sessionStorage.getItem("endDate")
          ? sessionStorage.getItem("endDate")
          : null,
        page: currentPage + 1,
        shopId: id,
        storeType: storeType
      };
      dispatch(getAdvanceSearchData(payload));
    }
  };

  const handleUpdateTabAfterResync = useCallback(() => {
    if (reSynceData.isSuccess) {
      const payload = {
        page: 0,
        ordersStatus: null,
        search: null,
        startDate: null,
        endDate: null,
        shopId: id,
        storeType: storeType
      };
      dispatch(getAdvanceSearchData(payload));
      dispatch(getQueuedData(id, storeType));
      dispatch(getFailedData(id, storeType));
      sessionStorage.removeItem("orderStatus");
    }
  }, [reSynceData, dispatch, id, storeType]);

  const popup = (
    <div className="popup" id="popup">
      <Image src={Processed} width={40} />
      <p className="popupHeading">{failed} Orders have been found!</p>
      <p className="popupDetails">
        {failed === 0
          ? "No orders Found to Re-sync"
          : "The system is running to process these orders. It may take few minutes to get updates on your dashboard"}
      </p>
      <Button
        primary
        onClick={() => {
          setPopup(false);
          setOpenPopup(false);
          handleUpdateTabAfterResync();
        }}
      >
        OK
      </Button>
    </div>
  );

  const emptyStateMarkup = (
    <EmptySearchResult
      title={t("No_orders_found")}
      description={t("filter_message")}
      withIllustration
    />
  );

  function formatDateTime(datetime) {
    try {
      const date = moment(datetime);
      const formattedDate = date.format("MMM DD, YYYY, h:mm A");
      return formattedDate;
    } catch (err) {
      return "";
    }
  }

  return (
    <Page fullWidth>
      <Layout>
        {popupOpen ? popup : ""}
        <Layout.Section>
          <LegacyCard>
            <LegacyCard.Section>
              <div
                className="d-flex flex-wrap cursor_p"
                style={{ gap: "10px" }}
              >
                <div
                  onClick={() => {
                    sessionStorage.setItem("orderStatus", "QUEUED");
                    handleOrdersFilter("QUEUED");
                  }}
                  className={
                    sessionStorage.getItem("orderStatus") === "QUEUED"
                      ? "d-flex gap-10 filterConatiner activeFilter"
                      : "d-flex gap-10 filterConatiner"
                  }
                >
                  <div>
                    <Badge>{queue}</Badge>
                  </div>
                  <p className="filters">{t("InQueue")}</p>
                </div>
                <div
                  onClick={() => {
                    sessionStorage.setItem("orderStatus", "PROCESSING");
                    handleOrdersFilter("PROCESSING");
                  }}
                  className={
                    sessionStorage.getItem("orderStatus") === "PROCESSING"
                      ? "d-flex gap-10 filterConatiner activeFilter"
                      : "d-flex gap-10 filterConatiner"
                  }
                >
                  <div>
                    <Badge>{processing}</Badge>
                  </div>
                  <p className="filters">{t("Processing")}</p>
                </div>
                <div
                  data-testid='failed-order-test-id'
                  onClick={() => {
                    sessionStorage.setItem("orderStatus", "FAILED");
                    handleOrdersFilter("FAILED");
                  }}
                  className={
                    sessionStorage.getItem("orderStatus") === "FAILED"
                      ? "d-flex gap-10 filterConatiner activeFilter"
                      : "d-flex gap-10 filterConatiner"
                  }
                >
                  <div>
                    <Badge>{failed}</Badge>
                  </div>
                  <p className="filters"> {t("FailedOrders")}</p>
                </div>
                <div
                  onClick={() => {
                    sessionStorage.setItem("orderStatus", "MISSING_INFO");
                    handleOrdersFilter("MISSING_INFO");
                  }}
                  className={
                    sessionStorage.getItem("orderStatus") === "MISSING_INFO"
                      ? "d-flex gap-10 filterConatiner activeFilter"
                      : "d-flex gap-10 filterConatiner"
                  }
                >
                  <div>
                    <Badge>{missing}</Badge>
                  </div>
                  <p className="filters"> {t("MissingInfo")}</p>
                </div>
                <div
                  onClick={() => {
                    sessionStorage.setItem("orderStatus", "SUCCESS");
                    handleOrdersFilter("SUCCESS");
                  }}
                  className={
                    sessionStorage.getItem("orderStatus") === "SUCCESS"
                      ? "d-flex gap-10 filterConatiner activeFilter"
                      : "d-flex gap-10 filterConatiner"
                  }
                >
                  <div>
                    {" "}
                    <Badge>{synced}</Badge>
                  </div>
                  <p className="filters">{t("SuccessfulOrders")}</p>
                </div>
                <div
                  onClick={() => {
                    sessionStorage.setItem("orderStatus", "INVALID_GATEWAY");
                    handleOrdersFilter("INVALID_GATEWAY");
                  }}
                  className={
                    sessionStorage.getItem("orderStatus") === "INVALID_GATEWAY"
                      ? "d-flex gap-10 filterConatiner activeFilter"
                      : "d-flex gap-10 filterConatiner"
                  }
                >
                  <div>
                    <Badge>{invalid_gateway}</Badge>
                  </div>
                  <p className="filters">{t("Invalid_Gateway")}</p>
                </div>
                <div
                  onClick={() => {
                    sessionStorage.removeItem("orderStatus");
                    handleOrdersFilter(null);
                  }}
                  className={
                    sessionStorage.getItem("orderStatus") === null
                      ? "d-flex gap-10 filterConatiner activeFilter"
                      : "d-flex gap-10 filterConatiner"
                  }
                >
                  <div>
                    <Badge>{allOrder}</Badge>
                  </div>
                  <p className="filters"> {t("AllOrders")}</p>
                </div>
              </div>
            </LegacyCard.Section>
            {failed === 0 ? null : (
              <LegacyCard.Section>
                <div className="resyncOrderContainer">
                  <p className="resyncText">
                    {t("Would_you_like_to_re-sync_failed_orders")}
                  </p>
                  <div className="d-flex gap-10">
                    <Button>{t("Contact_support")}</Button>
                    <Button
                      loading={reSynceData.isRequest}
                      onClick={() => {
                        handleResyncOrders();
                        setOpenPopup(true);
                      }}
                      destructive
                    >
                      {t("Resync_failed_orders")}
                    </Button>
                  </div>
                </div>
              </LegacyCard.Section>
            )}
            <LegacyCard.Section>
              <div
                className="d-flex justify-content-between flex-wrap gap-10"
                style={{ alignItems: "end" }}
              >
                <div className="inputContainer">
                  <Image src={SearchIcon} width={20} />
                  <input
                    placeholder={t("SearchOrder")}
                    value={searchData}
                    onChange={(e) => {
                      setSearchData(e.target.value);
                      handleOrdersFilter(
                        sessionStorage.getItem("orderStatus"),
                        e.target.value,
                        sessionStorage.getItem("startDate"),
                        sessionStorage.getItem("endDate")
                      );
                    }}
                  />
                </div>
                <div>
                  <span>{t("Daterange")}</span>
                  <DateRangePicker resetDate={resetDate} handleOrdersFilter={handleOrdersFilter} />
                </div>
                <Tooltip content={t("Clear_all_the_filters")}>
                  <div
                    onClick={() => {
                      sessionStorage.removeItem("startDate");
                      sessionStorage.removeItem("endDate");
                      sessionStorage.removeItem("orderStatus");
                      setSearchData("");
                      setResetDate(!resetDate);
                      handleOrdersFilter(null, null, null, null);
                    }}
                    className="clearFilter cursor_p"
                  >
                    <Image src={Clear} width={20} />
                  </div>
                </Tooltip>
              </div>
              <div style={{ marginTop: "20px" }}>
                {ordersData.isRequest ? (
                  <OrderTableSkeleton />
                ) : (
                  <Table customers={customers} />
                )}{" "}
              </div>
              <Divider />
              <div
                className="paginationContainer"
                style={{ display: pageCount > 1 ? "flex" : "none" }}
              >
                <Pagination
                  label={currentPage + 1}
                  hasPrevious
                  onPrevious={() => {
                    handlePreviousPagination();
                  }}
                  hasNext
                  onNext={() => {
                    handleNextPagination();
                  }}
                />
              </div>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default Orders;
