import {Button, ButtonGroup, Layout} from "@shopify/polaris";
import React, {useState} from "react";
import PaypalSettings from "../segmentedTabs/PaypalSettings";
import EventsTab from "../segmentedTabs/EventsTab";
import PullLogs from "../segmentedTabs/PullLogs";
import FullfilmentChecker from "../segmentedTabs/FullfilmentChecker";
import PaypalChecker from "../segmentedTabs/PaypalChecker";
import PaypalAccountSettings from "../segmentedTabs/AccountSettings"
 
function ButtonGroups() {
  const [activeSegment, setActiveSegment] = useState(0);
  const [tab, setTab] = useState(<PaypalSettings />);

  const handleSegment = (active, tabName) => {
    switch (tabName) {
      case "paypalsetting":
        setTab(<PaypalSettings />);
        break;
      case "event":
        setTab(<EventsTab />);
        break;
      case "pulllogs":
        setTab(<PullLogs />);
        break;

      case "accountsetting":
        setTab(<PaypalAccountSettings />);

        break;
      case "fulfillmentchecker":
        setTab(<FullfilmentChecker />);
        break;
      case "paypalchecker":
        setTab(<PaypalChecker />);
        break;
      default:
        break;
    }
    setActiveSegment(active);
  };
  return (
    <>
      <Layout.Section>
        <div className="wrapper d-flex flex-col gap-10">
          <div>
            <ButtonGroup fullWidth={true} segmented>
              <Button
                primary={activeSegment === 0 ? true : false}
                onClick={() => {
                  handleSegment(0, "paypalsetting");
                }}
              >
                PayPal settings
              </Button>
              <Button
                primary={activeSegment === 1 ? true : false}
                onClick={() => {
                  handleSegment(1, "event");
                }}
              >
                Events
              </Button>
              <Button
                primary={activeSegment === 2 ? true : false}
                onClick={() => {
                  handleSegment(2, "pulllogs");
                }}
              >
                Pull logs
              </Button>
                            <Button
                primary={activeSegment === 4 ? true : false}
                onClick={() => {
                  handleSegment(4, "accountsetting");
                }}
              >
                Account settings
              </Button>
              
            </ButtonGroup>
          </div>
          <div>
          
          </div>
        </div>
      </Layout.Section>
      {tab}
    </>
  );
}

export default ButtonGroups;
