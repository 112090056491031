import { IndexTable, LegacyCard, Text, Button } from "@shopify/polaris";
import React from "react";
import { HistoricalSkeletonTable } from "../../../components/skeletonLoader";

const FullfillmentOrderNameTable = (props) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  }; 

  const rowMarkup = props.list
    .slice(0, 10)
    .map(
      (
        {
          orderId,
          orderName,
          totalPrice,
          currency,
          transactionId,
          trackingNumber,
          carrier,
          financialStatus,
          fulfillmentStatus,
          statusDescription,
          processingMethod,
          processedAt,
        },
        index
      ) => (
        <IndexTable.Row id={orderId} key={orderId} position={index}>
          <IndexTable.Cell>
            <Text variant="bodyMd" fontWeight="bold" as="span">
              {orderId}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>{orderName}</IndexTable.Cell>
          <IndexTable.Cell>
            {totalPrice
              ? totalPrice.toLocaleString("en-US", {
                  style: "currency",
                  currency: currency,
                })
              : ""}
          </IndexTable.Cell>
          <IndexTable.Cell>{currency}</IndexTable.Cell>
          <IndexTable.Cell>{transactionId}</IndexTable.Cell>
          <IndexTable.Cell>{trackingNumber}</IndexTable.Cell>
          <IndexTable.Cell>{carrier}</IndexTable.Cell>
          <IndexTable.Cell>{financialStatus}</IndexTable.Cell>
          <IndexTable.Cell>{fulfillmentStatus}</IndexTable.Cell>
          <IndexTable.Cell>{statusDescription}</IndexTable.Cell>
          <IndexTable.Cell>{processingMethod}</IndexTable.Cell>
          <IndexTable.Cell>{processedAt}</IndexTable.Cell>

          <IndexTable.Cell>
            <Button
              primary
              onClick={() => {
                props.handleMultipleOrderNameList(orderId);
              }}
            >
              Submit
            </Button>
          </IndexTable.Cell>
        </IndexTable.Row>
      )
    );

  const storeOrderId = <div className="table_heading">Order Id</div>;
  const storeId = <div className="table_heading">OrderName</div>;
  const orderName = <div className="table_heading">Total Price</div>;
  const transactionId = <div className="table_heading">Transaction Id</div>;
  const trackingNumber = <div className="table_heading"> Tracking Number</div>;
  const carrier = <div className="table_heading">Carrier </div>;
  const financialStatus = (
    <div className="table_heading"> Financial Status</div>
  );
  const fulfillmentStatus = (
    <div className="table_heading">Fulfillment Status </div>
  );
  const statusDescription = (
    <div className="table_heading">Status Description</div>
  );
  const processingMethod = (
    <div className="table_heading">Processing Method</div>
  );
  const processedAt = <div className="table_heading">Processed At</div>;

  const merchantAccountId = <div className="table_heading">Currency</div>;
  return (
    <div style={{ marginTop: "20px" }}>
      <LegacyCard>
        {props.loading ? (
          <HistoricalSkeletonTable />
        ) : (
          <IndexTable
            resourceName={resourceName || 0}
            itemCount={props.list.length}
            headings={[
              { id: "1", title: storeOrderId },
              { id: "2", title: storeId },
              { id: "3", title: orderName },
              { id: "4", title: merchantAccountId },
              { id: "5", title: transactionId },
              { id: "6", title: trackingNumber },
              { id: "7", title: carrier },
              { id: "8", title: financialStatus },
              { id: "9", title: fulfillmentStatus },
              { id: "10", title: statusDescription },
              { id: "11", title: processingMethod },
              { id: "12", title: processedAt },
            ]}
            selectable={false}
          >
            {rowMarkup}
          </IndexTable>
        )}
      </LegacyCard>
    </div>
  );
};

export default FullfillmentOrderNameTable;
