import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from '@shopify/polaris';

const Logout = () => {
    const { logout } = useAuth0();

    useEffect(() => {
        localStorage.clear();
        sessionStorage.clear();
        logout({
            returnTo: window.location.origin,
        })
    }, []);

    return (
        <div className='d-flex justify-content-center aligns-Items-center h-100vh'>
            <Spinner accessibilityLabel="Spinner example" size="large" />
        </div>
    );
};

export default Logout;
