import React from 'react'
import {Area, AreaChart, Tooltip} from 'recharts';


const Chart = (chartData) => {
  return (
    <>
        <AreaChart
          width={200}
          height={70}
          data={chartData.data}
        >
          <Tooltip labelFormatter={() => ""} formatter={(okay) => [new Intl.NumberFormat('en').format(okay), undefined]} />
          <Area  type="monotone" dataKey="count" stroke="#4C50C4" fill="#fff"   />
        </AreaChart>
    </>
  )
}

export default Chart
