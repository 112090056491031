import { PROCESSES_QUEUE_ERROR, PROCESSES_QUEUE_REQUEST,PROCESSES_QUEUE_SUCCESS } from "../ActionType";
const initialState = {
    isRequest: false,
    isSuccess: false,
    isError: false,
    data: {},
  };
  
  const processesQueueReducer = (state = initialState, action) => {
    switch (action.type) {
      case PROCESSES_QUEUE_REQUEST:
        return {
          isRequest: true,
          isSuccess: false,
          isError: false,
          data: action.payload,
        };
  
      case PROCESSES_QUEUE_SUCCESS:
        return {
          isRequest: false,
          isSuccess: true,
          isError: false,
          data: action.payload,
        };
      case PROCESSES_QUEUE_ERROR:
        return {
          isRequest: false,
          isSuccess: false,
          isError: true,
          data: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default processesQueueReducer;