import {
  FETCH_BY_NAME_ERROR,
  FETCH_BY_NAME_REQUEST,
  FETCH_BY_NAME_SUCCESS,
} from "./ActionType";
const initialState = {
  isRequest: false,
  isSuccess: false,
  isError: false,
  data: {},
};

const fetchByNameReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BY_NAME_REQUEST:
      return {
        isRequest: true,
        isSuccess: false,
        isError: false,
        data: action.payload,
      };
    case FETCH_BY_NAME_SUCCESS:
      return {
        isRequest: false,
        isSuccess: true,
        isError: false,
        data: action.payload,
      };
    case FETCH_BY_NAME_ERROR:
      return {
        isRequest: false,
        isSuccess: false,
        isError: true,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default fetchByNameReducer;