import {Banner, Button, Divider, Icon, Image, Layout, LegacyCard, Popover, Select, TextField,} from "@shopify/polaris";
import {React, useCallback, useState} from "react";
import Header from "../../components/header";
import "./style.css";
import {Bold, Gallery, Italic, Underline} from "../../assets";
import {DeleteMinor, EditMinor} from "@shopify/polaris-icons";

const Global = () => {
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("Blue");
  const [bold, setBold] = useState(false);
  const [italic, setIlatic] = useState(false);
  const [underline, setUnderLine] = useState(false);
  const [gallery, setGallery] = useState(false);
  const [message, setMessage] = useState("");
  const [link, setLink] = useState("https://");

  const handleBold = () => {
    setBold(!bold);
  };
  const handleItalic = () => {
    setIlatic(!italic);
  };

  const handleUnderLine = () => {
    setUnderLine(!underline);
  };

  const handleGallery = () => {
    setGallery(!gallery);
  };

  const handleChange = (e) => {
    setTitle(e);
  };

  const handleSelectChange = useCallback((value) => setColor(value), []);

  const options = [
    { label: "Grey", value: "grey" },
    { label: "Blue", value: "blue" },
    { label: "Black", value: "black" },
  ];

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const handleLink = useCallback((newValue) => setLink(newValue), []);

  const activator = (
    <Image onClick={togglePopoverActive} src={Gallery} width={15} />
  );

  return (
    <Layout>
      <Header title="Global" />
      <Layout.Section>
        <div className="wrapper">
          <LegacyCard sectioned>
            <div className="globalContainer">
              <p className="heading">Announcement</p>
              <div className="d-flex gap-10 flex-col">
                <p>Current Announcement</p>
                <Banner title="Informational Banner" status="info">
                  <p>Body text.</p>
                  <div className="mt-1 d-flex gap-10 flex-wrap">
                    <Button>
                      <div style={{ display: "flex", gap: "10px" }}>
                        {" "}
                        <Icon source={EditMinor} />
                        Edit
                      </div>
                    </Button>
                    <Button>
                      <div style={{ display: "flex", gap: "10px" }}>
                        {" "}
                        <Icon source={DeleteMinor} />
                        Remove
                      </div>
                    </Button>
                  </div>
                </Banner>{" "}
              </div>

              <TextField
                label="Title"
                value={title}
                onChange={handleChange}
                autoComplete="off"
              />

              <Select
                label="Color"
                options={options}
                onChange={handleSelectChange}
                value={color}
              />
              <div className="d-flex gap-5 flex-col">
                <span className="sub_heading">Message</span>
                <div className="message_container">
                  <div className="action_container">
                    <span
                      onClick={handleBold}
                      className="icon_container"
                      style={{
                        background: bold
                          ? "var(--p-decorative-three-surface)"
                          : "",
                      }}
                    >
                      <Image src={Bold} width={9} />
                    </span>
                    <span
                      onClick={handleItalic}
                      className="icon_container"
                      style={{
                        background: italic
                          ? "var(--p-decorative-three-surface)"
                          : "",
                      }}
                    >
                      <Image src={Italic} width={9} />
                    </span>
                    <span
                      onClick={handleUnderLine}
                      className="icon_container"
                      style={{
                        background: underline
                          ? "var(--p-decorative-three-surface)"
                          : "",
                      }}
                    >
                      <Image src={Underline} width={15} />
                    </span>
                    <span
                      // onClick={handleGallery}
                      className="icon_container"
                    >
                      <Popover
                        active={popoverActive}
                        activator={activator}
                        autofocusTarget="first-node"
                        onClose={togglePopoverActive}
                      >
                        <div
                          style={{
                            display: "flex",
                            padding: "20px",
                            flexDirection: "column",
                            gap: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <TextField
                              value={link}
                              onChange={handleLink}
                              autoComplete="off"
                            />{" "}
                          </div>
                          <div>
                            <Button primary>Save</Button>
                            <Button onClick={togglePopoverActive}>
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </Popover>
                    </span>
                  </div>
                  <Divider borderStyle="base" />

                  <textarea
                    style={{
                      fontSize: "14px",
                      fontWeight: bold ? "bolder" : "",
                      textDecoration: underline ? "underline" : "",
                      fontStyle: italic ? "italic" : "",
                    }}
                    onChange={handleMessage}
                    value={message}
                  ></textarea>
                </div>
              </div>
              <div>
                <Button primary>Add announcement</Button>
              </div>
            </div>
          </LegacyCard>
        </div>
      </Layout.Section>
    </Layout>
  );
};

export default Global;
