import { IndexTable, LegacyCard } from "@shopify/polaris";
import React from "react";

function GdprTable({gdprContant}) {

  console.log(gdprContant)

  const resourceName = {
    singular: "data",
    plural: "data",
  };

  const rowMarkup = gdprContant.map(
    (
      {
        shopDomain,
        customerId,
        customerEmail,
        customerPhone,
        dataRequestId,
        body,
        gdprActionType,
        requestedAt,
        processedAt,
      },
      index
    ) => (
      <IndexTable.Row id={customerId} key={customerId} position={index}>
        <IndexTable.Cell>{shopDomain}</IndexTable.Cell>
        <IndexTable.Cell>{customerId}</IndexTable.Cell>
        <IndexTable.Cell>{customerEmail}</IndexTable.Cell>
        <IndexTable.Cell>{customerPhone}</IndexTable.Cell>
        <IndexTable.Cell>{dataRequestId}</IndexTable.Cell>
        <IndexTable.Cell>{body}</IndexTable.Cell>
        <IndexTable.Cell>{gdprActionType}</IndexTable.Cell>
        <IndexTable.Cell>{requestedAt}</IndexTable.Cell>
        <IndexTable.Cell>{processedAt}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  return (
    <LegacyCard>
      <IndexTable
        resourceName={resourceName}
        itemCount={gdprContant.length}
        headings={[
          { id: "1", title: "Shop domain" },
          { id: "2", title: "Customer Id" },
          { id: "3", title: "Customer email" },
          { id: "4", title: "Customer phone" },
          { id: "5", title: "Data Request Id" },
          { id: "6", title: "Body" },
          { id: "7", title: "GDPR action type" },
          { id: "8", title: "Requested At" },
          { id: "9", title: "Precessed At" },
        ]}
        selectable={false}
      >
        {rowMarkup}
      </IndexTable>
    </LegacyCard>
  );
}

export default GdprTable;
