import React, { useEffect, useState } from "react";
import { Banner, LegacyCard, Page } from "@shopify/polaris";
import ToggleButton from "../../../components/toggleButton/ToggleButton";
import { useNavigate } from "react-router-dom";
import { digitalSyncStatus } from "../../../redux/store/paypalSettings/Action";
import { useDispatch, useSelector } from "react-redux";
import { getStoreDetails } from "../../../redux/store/find_by_id/Action";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DigitalProduct = () => {
  const { id, storeType } = useParams();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [visible, setVisible] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { digital_sync } = useSelector(
    (store) => store.requiredPaypalSettingReducer
  );
  const StoreDetailsData = useSelector(
    (store) => store.requireStoreDetailsRedycer
  );

  useEffect(() => {
    dispatch(getStoreDetails(id,storeType));
  }, []);

  const handleToggleAction = (state) => {
    const payload = {
      state: state,
      shopId: id,
      storeType: storeType
    }
    dispatch(digitalSyncStatus(payload));
  };

  useEffect(() => {
    if (digital_sync.isRequest === true) {
      setLoading(true);
    }
    if (
      digital_sync.isSuccess === true &&
      digital_sync.data !== undefined &&
      digital_sync.data.digitalSync !== undefined
    ) {
      setVisible(digital_sync.data.digitalSync);
    }
    if (digital_sync?.data?.message === "Actual error message") {
      navigate('/logout')
    }
  }, [digital_sync]);

  useEffect(() => {
    if (
      StoreDetailsData.isSuccess === true &&
      StoreDetailsData?.findById !== undefined &&
      StoreDetailsData?.findById?.digitalSync !== undefined
    ) {
      setVisible(StoreDetailsData?.findById?.digitalSync);
      setLoading(false);
    }
    if (StoreDetailsData?.findById?.message === "Actual error message") {
      navigate('/logout')
    }
  }, [StoreDetailsData]);

  return (
    <>
      <Page fullWidth>
        <div className="maindiv " style={{ display: "flex", gap: "20px" }}>
          <div className="firstdiv">
            <LegacyCard sectioned>
              <ul className="navItems">
                <li
                  data-testid="subscription-side-nav-id"
                  className="navItem"
                  onClick={() => {
                    navigate(
                      `/viewStore/${id}/${storeType}/subscription`
                    );
                  }}
                >
                  {t("Subscription")}
                </li>
                <li
                  data-testid="digital-product-side-nav-id"
                  className="navItem activeTab"
                  onClick={() => {
                    navigate(
                      `/viewStore/${id}/${storeType}/digital-product`
                    );
                  }}
                >
                  {t("Digital_product")}
                </li>
                <li
                  data-testid="courier-mapping-side-nav-id"
                  className=" navItem"
                  onClick={() => {
                    navigate(
                      `/viewStore/${id}/${storeType}/courier-mapping`
                    );
                  }}
                >
                  {t("Courier_mapping")}
                </li>
                <li
                  data-testid="paypal-setting-side-nav-id"
                  className="navItem"
                  onClick={() => {
                    navigate(
                      `/viewStore/${id}/${storeType}/paypalsetting`
                    );
                  }}
                >
                  {t("PayPal_settings")}
                </li>
              </ul>
            </LegacyCard>
          </div>{" "}
          <div className="abc ">
            <LegacyCard sectioned>
              <div
                className="d-flex pr-20 pl-20"
                style={{
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <span className="courierHeading">{t("Digital_orders")}</span>
                <p className="couriertext">{t("Digital_Order_Sync_info")}</p>
                <div
                  className="d-flex"
                  style={{ alignItems: "baseline", gap: "5px" }}
                >
                  <ToggleButton
                    visible={visible}
                    setVisible={setVisible}
                    loading={loading}
                    buttonOne={t("Yes")}
                    buttonTwo={t("No")}
                    handleToggleAction={handleToggleAction}
                  />
                </div>
              </div>
            </LegacyCard>
            <div style={{ marginTop: "20px" }}>
              <Banner title="Info" status="info">
                <p>{t("digital_order_syncing")}</p>
              </Banner>
            </div>
          </div>
        </div>
      </Page>
    </>
  );
};

export default DigitalProduct;
