import {
  REINDEXING_REQUEST,
  REINDEXING_ERROR,
  REINDEXING_SUCCESS,

  CANCEL_DIGITAL_ORDER_ERROR,
  CANCEL_DIGITAL_ORDER_REQUEST,
  CANCEL_DIGITAL_ORDER_SUCCESS,
} from "./ActionType";

const initialState = {
  isRequest: false,
  isSuccess: false,
  isError: false,
  reindex: {},
  cancelDigitalOrder: {}
};

const reindexReducer = (state = initialState, action) => {
  switch (action.type) {
    case REINDEXING_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        reindex: action.payload,
      };
    case REINDEXING_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        reindex: action.payload,
      };
    case REINDEXING_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        reindex: action.payload,
      };
    case CANCEL_DIGITAL_ORDER_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        cancelDigitalOrder: action.payload,
      };
    case CANCEL_DIGITAL_ORDER_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        cancelDigitalOrder: action.payload,
      };
    case CANCEL_DIGITAL_ORDER_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        cancelDigitalOrder: action.payload,
      };
    default:
      return state;
  }
};

export default reindexReducer;