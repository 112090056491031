export const SET_DEFAULT_REQUEST = "SET_DEFAULT_REQUEST";
export const SET_DEFAULT_SUCCESS = "SET_DEFAULT_SUCCESS";
export const SET_DEFAULT_ERROR= "SET_DEFAULT_ERROR";

export const SET_DELETE_ERROR= "SET_DELETE_ERROR";
export const SET_DELETE_SUCCESS= "SET_DELETE_SUCCESS";
export const SET_DELETE_REQUEST= "SET_DELETE_REQUEST";

export const DIGITAL_SYNC_ERROR= "DIGITAL_SYNC_ERROR";
export const DIGITAL_SYNC_SUCCESS= "DIGITAL_SYNC_SUCCESS";
export const DIGITAL_SYNC_REQUEST= "DIGITAL_SYNC_REQUEST";
