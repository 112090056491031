
export const ALL_CARRIERS_REQUEST = "ALL_CARRIERS_REQUEST";
export const ALL_CARRIERS_SUCCESS = "ALL_CARRIERS_SUCCESS ";
export const ALL_CARRIERS_ERROR = "ALL_CARRIERS_ERROR";

export const LIST_CARRIERS_GEO_REQUEST = "ALL_CARRIERS_REQUEST";
export const LIST_CARRIERS_GEO_SUCCESS = "LIST_CARRIERS_GEO_SUCCESS ";
export const LIST_CARRIERS_GEO_ERROR = "LIST_CARRIERS_GEO_ERROR";

export const DELETE_CARRIERS_REQUEST = "DELETE_CARRIERS_REQUEST";
export const DELETE_CARRIERS_SUCCESS = "DELETE_CARRIERS_SUCCESS ";
export const DELETE_CARRIERS_ERROR = "DELETE_CARRIERS_ERROR";

export const ADD_CARRIERS_REQUEST = "ADD_CARRIERS_REQUEST";
export const ADD_CARRIERS_SUCCESS = "ADD_CARRIERS_SUCCESS ";
export const ADD_CARRIERS_ERROR = "ADD_CARRIERS_ERROR";
