import { React, useCallback, useEffect, useState } from "react";
import {
  Button,
  Layout,
  LegacyCard,
  Select,
  TextField,
  InlineError,
} from "@shopify/polaris";
import SuccessBanner from "../../../components/banner/SuccessBanner";
import CriticalBanner from "../../../components/banner/criticalBanner";
import Transcript from "../../../components/transcript";
import { getpaypalCheckerData } from "../../../redux/store/paypalChecker/Action";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import './style.css';

const PaypalChecker = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [accountID, setAccountID] = useState("");
  const [transactionID, setTransactionID] = useState("");
  const [displayticket, setDisplayTicket] = useState(false);
  const [visible, setVisible] = useState(false);
  const [flag, setFlag] = useState(false);
  const [message, setMessage] = useState("");
  const [transcript, setTranscript] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [error, setError] = useState({ merchnatId: "", transactionId: "", trackingId:"" });
  const [runTime, setRunTime] = useState(0);
  const handleTranactionIDChange = useCallback(
    (newValue) => setTransactionID(newValue),
    []
  );
  const handleTrackingNumberChange = useCallback(
    (newValue) => setTrackingNumber(newValue),
    []
  );

  const handleMerchantAccount = useCallback((value) => setAccountID(value), []);
  const paypalCheckerData = useSelector(
    (store) => store.requiredPaypalCheckerReducer
  );
  const paypalSettingData = useSelector(
    (store) => store.requriedPaypalSettingData
  );

  useEffect(() => {
    if (
      paypalSettingData.isSuccess === true &&
      paypalSettingData.data !== undefined
    ) {
      setMerchantId(paypalSettingData?.data?.content);
    }
    if(paypalSettingData?.data?.message === "Actual error message") {
      navigate('/logout')
    }
  }, [paypalSettingData, navigate]);

  const handleSubmit = () => {
    const payload = {
      shopId: id,
      shopMerchantAccountId: accountID === undefined ? null : accountID,
      transactionId: transactionID,
      trackingNumber: trackingNumber,
    };
    if (accountID === undefined) {
      setError({
        merchnatId: "Please select Merchant Id",
        transactionId:"",
        trackingId:""
      })
    } else if (transactionID === "") {
      setError({
        merchnatId: "",
        transactionId:"Please add transaction id",
        trackingId:""
      })
    } else if (trackingNumber === "") {
      setError({
        merchnatId: "",
        transactionId:"",
        trackingId:"please add tracking number"
      })
    } else {
              setRunTime(1)
      dispatch(getpaypalCheckerData(payload));
      setError({
        merchnatId: "",
        transactionId:"",
        trackingId:""
      })
    }
  };
  useEffect(() => {
    if (paypalCheckerData.isSuccess) {
      setTranscript(paypalCheckerData.paypalchecker.responseData);
      setFlag(paypalCheckerData.paypalchecker.success !== null ? paypalCheckerData.paypalchecker.success : false);
      setMessage(paypalCheckerData.paypalchecker.message);
      setDisplayTicket(paypalCheckerData.paypalchecker.createTicket);
      setVisible(paypalCheckerData.paypalchecker.responseData === null ? false : true);
    }
    if(paypalCheckerData?.paypalchecker?.message === "Actual error message") {
      navigate('/logout')
    }
  }, [paypalCheckerData, navigate]);

  const merchantIdOptions = [];
  if (merchantId?.length > 0) {
    merchantId.forEach((i) => {
      if (i !== null) {
        const data = {
          label: i.merchantAccountId,
          value: i.merchantAccountId,
        };
        merchantIdOptions.push(data);
      }
    });
  }

  const handleKeyPress = (event) => {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed) {
      event.preventDefault();
      handleSubmit();
    }
  }

  return (
    <>
      <Layout.Section>
        <div className="wrapper">
          <LegacyCard sectioned>
            <div style={{ padding: "15px" }}>
              <LegacyCard sectioned>
              <div className="d-flex flex-wrap"
                style={{
                  alignItems: "end",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <div className="textFieldContainer">
                  <Select
                    label="Merchant account ID"
                    options={merchantIdOptions}
                    onChange={handleMerchantAccount}
                    value={accountID}
                    placeholder="Select Merchant account id"
                  />
                  {error ? (
                    <div style={{ position: "absolute" }}>
                      <InlineError
                        message={error.merchnatId}
                        fieldID="myFieldID"
                      />
                    </div>
                  ) : null}
                </div>

                <div onKeyDown={handleKeyPress} className="textFieldContainer">
                  <TextField
                    label="Transaction ID"
                    value={transactionID}
                    onChange={handleTranactionIDChange}
                    autoComplete="off"
                    placeholder="Please enter Transaction ID"
                  />
                  {error ? (
                    <div style={{ position: "absolute" }}>
                      <InlineError
                        message={error.transactionId}
                        fieldID="myFieldID"
                      />
                    </div>
                  ) : null}
                </div>
                <div onKeyDown={handleKeyPress} className="textFieldContainer">
                  <TextField
                    label="Tracking number"
                    value={trackingNumber}
                    onChange={handleTrackingNumberChange}
                    autoComplete="off"
                    placeholder="Please enter Tracking Number"
                  />
                  {error ? (
                    <div style={{ position: "absolute" }}>
                      <InlineError
                        message={error.trackingId}
                        fieldID="myFieldID"
                      />
                    </div>
                  ) : null}
                </div>

                <div>
                  <Button primary loading={paypalCheckerData.isRequest} onClick={handleSubmit}>Submit</Button>
                </div>
              </div>
              {flag ? (
                <SuccessBanner runTime={runTime} message={message} />
              ) : (
                <CriticalBanner runTime={runTime} message={message} />
              )}
             {visible && runTime ? <Transcript
                displayticket={displayticket}
                transcript={transcript}
              /> : ""}
              </LegacyCard>
            </div>
          </LegacyCard>
        </div>
      </Layout.Section>
    </>
  );
};

export default PaypalChecker;
