import {
  IndexTable,
  Layout,
  LegacyCard,
  EmptySearchResult
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pullLogsApi } from "../../../redux/troubleshooting/pullLogs/Action";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { HistoricalSkeletonTable } from "../../../components/skeletonLoader";

const PullLogs = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [row, setRow] = useState([]);

  const dispatch = useDispatch();

  const pullLogsData = useSelector((store) => store.requriedPullLogData);

  useEffect(() => {
    const payload = {
      storeId: id,
      page: 0,
    };
    dispatch(pullLogsApi(payload));
  }, []);

  useEffect(() => {
    if (pullLogsData?.data?.content) {
      setRow(pullLogsData.data.content);
    }
    if(pullLogsData?.data?.message === "Actual error message") {
      navigate('/logout')
    }
  }, [pullLogsData]);

  const emptyStateMarkup = (
    <EmptySearchResult
      title={'No  pull logs found'}
      description={''}
      withIllustration
    />
  );

  function formatDateTime(datetime) {
    try {
      const date = moment(datetime);
      const formattedDate = date.format("MMM DD, YYYY, h:mm A");
      return formattedDate;
    } catch (err) {
      return "";
    }
  }

  const rowMarkup = row.map(
    (
      {
      
        loggedAt,
        report,
        
      },
      index
    ) => (
      <IndexTable.Row>
        <IndexTable.Cell>
          <p style={{ padding: "10px" }}>
            {loggedAt !== null ? formatDateTime(loggedAt) : ""}
          </p>
        </IndexTable.Cell>
        <IndexTable.Cell>
            <p
              style={{
                width: "",
                overflow: "hidden",
                textOverflow: "",
              }}
            >
              {report}
            </p>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );
  const resourceName = {
    singular: "Pull log",
    plural: "Pull logs",
  };
  const pullLogs = (
    <span className="d-flex gap-10">
      <span className="table_heading">PullLogs</span>
    </span>
  );
  const report = (
    <span className="d-flex gap-10">
      <span className="table_heading">Report</span>
    </span>
  );


  return (
    <Layout.Section>
      <div className="wrapper">
        <LegacyCard sectioned>
          <p className="card_title" style={{ padding: "15px" }}>
            Pull logs
          </p>
          <LegacyCard sectioned>
            {pullLogsData.isRequest ? (
              <HistoricalSkeletonTable />
            ) : (
              <IndexTable
                resourceName={resourceName || 0}
                itemCount={row.length}
                emptyState={emptyStateMarkup}
                headings={[
                  { id: "1", title: pullLogs },
                  { id: "2", title: report },
                ]}
                selectable={false}
              >
                {rowMarkup}
              </IndexTable>
            )}
          </LegacyCard>
        </LegacyCard>
      </div>
    </Layout.Section>
  );
};

export default PullLogs;
