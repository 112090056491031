import {Pagination} from '@shopify/polaris';
import React from 'react';
const Paginations = () => {
  return (
    <>
      <Pagination
      hasPrevious
      onPrevious={() => {
        console.log('Previous');
      }}
      hasNext
      onNext={() => {
        console.log('Next');
      }}
    /> 
    </>
  )
}

export default Paginations
