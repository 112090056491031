import { React, useEffect, useState } from "react";
import {
  VerticalStack,
  Badge,
  Button,
  Divider,
  Image,
  LegacyCard,
  Page,
} from "@shopify/polaris";
import { GreyTick } from "../../../assets";
import { useNavigate, useParams } from "react-router-dom";
import { getStoreDetails } from "../../../redux/store/find_by_id/Action";
import { useSelector, useDispatch } from "react-redux";
import { findByIdApi } from "../../../redux/troubleshooting/clientDetails/Action";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";

const Subscription = (props) => {
  const { id, storeType } = useParams();
  const { i18n, t } = useTranslation();
  const [activePlan, setActivePlan] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getPlanDetails = useSelector(
    (store) => store.requireStoreDetailsRedycer
  );
  const findClientsDetails = useSelector((store) => store.requriedFindDetails);
  useEffect(() => {
    dispatch(getStoreDetails(id,storeType));
    dispatch(findByIdApi(id));
  }, [dispatch, id, storeType]);

  useEffect(() => {
    if (
      getPlanDetails.isSuccess &&
      getPlanDetails.findById.planName !== undefined
    ) {
      setActivePlan(getPlanDetails.findById.planName);
    }
    if(getPlanDetails?.findById?.message === "Actual error message") {
      navigate('/logout')
    }
  }, [getPlanDetails, navigate]);

  useEffect(() => {
    if (findClientsDetails.isSuccess) {
      setCreatedAt(
        findClientsDetails.data.chargeFirstAcceptedAt === null
          ? ""
          : findClientsDetails.data.chargeFirstAcceptedAt
      );
    }
    if(findClientsDetails?.data?.message === "Actual error message") {
      navigate('/logout')
    }
  }, [findClientsDetails, navigate]);

  function formatDateTime(datetime) {
    try {
      const date = moment(datetime);
      const formattedDate = date.format("MMM DD, YYYY, h:mm A");
      return formattedDate;
    } catch (err) {
      return "";
    }
  }

  return (
    <Page fullWidth>
      <div className="maindiv " style={{ display: "flex", gap: "20px" }}>
        <div className="firstdiv">
          <LegacyCard sectioned>
            <ul className="navItems">
              <li
                data-testid="subscription-side-nav-id"
                className="navItem activeTab"
                onClick={() => {
                  navigate(`/viewStore/${id}/${storeType}/subscription`);
                }}
              >
                {t("Subscription")}
              </li>
              <li
                data-testid="digital-product-side-nav-id"
                className="navItem"
                onClick={() => {
                  navigate(`/viewStore/${id}/${storeType}/digital-product`);
                }}
              >
                {t("Digital_product")}{" "}
              </li>
              <li
                data-testid="courier-mapping-side-nav-id"
                className=" navItem"
                onClick={() => {
                  navigate(`/viewStore/${id}/${storeType}/courier-mapping`);
                }}
              >
                {t("Courier_mapping")}
              </li>
              <li
                data-testid="paypal-setting-side-nav-id"
                className="navItem"
                onClick={() => {
                  navigate(`/viewStore/${id}/${storeType}/paypalsetting`);
                }}
              >
                {t("PayPal_settings")}
              </li>
            </ul>
          </LegacyCard>
        </div>
        <div className="abc">
          <LegacyCard sectioned>
            <div
              className="d-flex pr-20 pl-20"
              style={{ justifyContent: "space-between" }}
            >
              <div
                className="d-flex"
                style={{ gap: "10px", alignItems: "center" }}
              >
                <span className="courierHeading">
                  {getPlanDetails.isRequest ? (
                    <Skeleton width={60} />
                  ) : (
                    activePlan
                  )}
                </span>

                <div>
                  <Badge status="success" progress="complete">
                    {t("Active_Plan")}{" "}
                  </Badge>
                </div>
              </div>
              <div style={{ marginTop: "20px" }}>
                {/* <Button outline>
                  <span className="cancelSubscription">
                    Cancel subscription
                  </span>
                </Button> */}
              </div>
            </div>
            <p className="activationDate pr-20 pl-20">
              Activation Date:{" "}
              {createdAt === null || createdAt === undefined || createdAt === ""
                ? ""
                : formatDateTime(createdAt)}
            </p>

            <div
              className="p-20"
              style={{ marginBottom: "20px", marginTop: "20px" }}
            >
              <Divider borderStyle="base" />
            </div>
            {/* Braek */}

            {activePlan === "Plus" ? (
              <div className="w-50 card_padding_left_20">
                <LegacyCard>
                  <div>
                    <div style={{ padding: "40px" }}>
                      <p className="planName">Plus</p>
                      <p className="planValue">$79.99 / {t("month")}</p>
                      <VerticalStack gap="5">
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            marginTop: "20px",
                          }}
                        >
                          <Image src={GreyTick} width={15} />
                          <p className="planDiscription">
                            {t("Unlimited_Paypal_Account")}
                          </p>
                        </div>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <Image src={GreyTick} width={15} />
                          <p className="planDiscription">
                            {t("Advanced_Courier_Rule")}
                          </p>
                        </div>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <Image src={GreyTick} width={15} />
                          <p className="planDiscription">
                            {t("Sync_digital_product_orders")}
                          </p>
                        </div>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <Image src={GreyTick} width={15} />
                          <p className="planDiscription">
                            {t("Dedicated_processing_clusters")}
                          </p>
                        </div>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <Image src={GreyTick} width={15} />
                          <p className="planDiscription">
                            {t("Advanced_reporting")}
                          </p>
                        </div>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <Image src={GreyTick} width={15} />
                          <p className="planDiscription">
                            {t("PCI_SOC2_Compliance_Priority_Support")}
                          </p>
                        </div>
                        <div>
                          <Button disabled outline>
                            {t("Active_Plan")}
                          </Button>
                        </div>
                      </VerticalStack>
                    </div>
                  </div>
                </LegacyCard>
              </div>
            ) : (
              <div
                className="d-flex cards"
                style={{
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <div className="w-50 card_padding_left_20">
                  <LegacyCard title="">
                    <div>
                      <div style={{ padding: "40px" }}>
                        <p className="planName">Basic</p>
                        <p className="planValue">$9.99 / {t("month")}</p>
                        <VerticalStack gap="5">
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              marginTop: "20px",
                            }}
                          >
                            <Image src={GreyTick} width={15} />
                            <p className="">
                              <Trans
                                i18nKey="Sync_unlimited_orders"
                                components={{ b: <strong /> }}
                              >
                                Sync <strong> unlimited </strong> orders
                              </Trans>{" "}
                            </p>
                          </div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <Image src={GreyTick} width={15} />
                            <p className="planDiscription">
                              {t("Connect_PayPal_account")}
                            </p>
                          </div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <Image src={GreyTick} width={15} />
                            <p className="planDiscription">
                              {t("Real_time_Synchronization")}
                            </p>
                          </div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <Image src={GreyTick} width={15} />
                            <p className="planDiscription">
                              {t("Support_for_Payflow")}
                            </p>
                          </div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <Image src={GreyTick} width={15} />
                            <p className="planDiscription">
                              {t("Other_3rd_Party_Checkouts_24")}
                            </p>
                          </div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <Image src={GreyTick} width={15} />
                            <p className="planDiscription">
                              {" "}
                              {t("customer_support")}
                            </p>
                          </div>
                          <div className="cursor_none">
                            {activePlan === "Basic" ? (
                              <Button disabled outline>
                                {t("Active_Plan")}{" "}
                              </Button>
                            ) : (
                              <Button disabled onClick={() => {}}>
                                {t("downgrade")}
                              </Button>
                            )}
                          </div>
                        </VerticalStack>
                      </div>
                    </div>
                  </LegacyCard>
                </div>

                <div className=" w-50 card_padding_right_20">
                  <LegacyCard title="">
                    <div>
                      <div style={{ padding: "40px" }}>
                        <p className="planName">Premium</p>
                        <p className="planValue">$39.99 / {t("month")}</p>
                        <VerticalStack gap="5">
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              marginTop: "20px",
                            }}
                          >
                            <Image src={GreyTick} width={15} />
                            <p className="planDiscription">
                              {t("All_features_of_BASIC")}
                            </p>
                          </div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <Image src={GreyTick} width={15} />
                            <p className="planDiscription">
                              {t("Connect_up_to_five_PayPal_accounts")}
                            </p>
                          </div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <Image src={GreyTick} width={15} />
                            <p className="planDiscription">
                              {t("Create_Custom_Carrier_info_rules")}
                            </p>
                          </div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <Image src={GreyTick} width={15} />
                            <p className="planDiscription">
                              {t("Sync_Digital_Product_Orders")}
                            </p>
                          </div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <Image src={GreyTick} width={15} />
                            <p className="planDiscription">
                              {" "}
                              {t("Premium_support")}
                            </p>
                          </div>

                          <div
                            className="cursor_none"
                            style={{ marginTop: "40px" }}
                          >
                            {activePlan === "Premium" ? (
                              <Button disabled outline>
                                {t("Active_Plan")}
                              </Button>
                            ) : (
                              <Button onClick={() => {}} primary disabled>
                                {t("Upgrade")}
                              </Button>
                            )}
                          </div>
                        </VerticalStack>
                      </div>
                    </div>
                  </LegacyCard>
                </div>
              </div>
            )}
          </LegacyCard>
        </div>
      </div>
    </Page>
  );
};

export default Subscription;
