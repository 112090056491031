import React, { useEffect } from 'react';
import { ACCESS_TOKEN } from '../constants';
import { Navigate, useLocation } from 'react-router-dom'

function OAuth2RedirectHandler() {
    const location = useLocation();
    useEffect(() => {
        console.log("LOCATION",location);

    });
//    getUrlParameter(name) {
//        console.log("redirected", location);
//        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
//        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
//
//        var results = regex.exec(this.props.location.search);
//        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
//    };
//
//    render() {
//
//        const token = this.getUrlParameter('token');
//        const error = this.getUrlParameter('error');
//        console.log("token", token);
//        console.log("error", error);
//        if(token) {
//            localStorage.setItem(ACCESS_TOKEN, token);
//            return <Navigate to={{
//                pathname: "/profile",
//                state: { from: this.props.location }
//            }}/>;
//        } else {
//            return <Navigate to={{
//                pathname: "/login",
//                state: {
//                    from: this.props.location,
//                    error: error
//                }
//            }}/>;
//        }
//    }
}

export default OAuth2RedirectHandler;