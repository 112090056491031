export const HISTORICAL_STATUS_REQUEST = 'HISTORICAL_STATUS_REQUEST';
export const HISTORICAL_STATUS_SUCCESS = 'HISTORICAL_STATUS_SUCCESS ';
export const HISTORICAL_STATUS_ERROR = 'HISTORICAL_STATUS_ERROR';

export const HISTORICAL_QUEUE_REQUEST = 'HISTORICAL_QUEUE_REQUEST';
export const HISTORICAL_QUEUE_SUCCESS = 'HISTORICAL_QUEUE_SUCCESS ';
export const HISTORICAL_QUEUE_ERROR = 'HISTORICAL_QUEUE_ERROR';

export const HISTORICAL_TOKEN_REQUEST = 'HISTORICAL_TOKEN_REQUEST';
export const HISTORICAL_TOKEN_SUCCESS = 'HISTORICAL_TOKEN_SUCCESS ';
export const HISTORICAL_TOKEN_ERROR = 'HISTORICAL_TOKEN_ERROR';

export const HISTORICAL_TOTAL_POSSIBLE_ORDERS_REQUEST = 'HISTORICAL_TOTAL_POSSIBLE_ORDERS_REQUEST';
export const HISTORICAL_TOTAL_POSSIBLE_ORDERS_TOKEN_SUCCESS = 'HISTORICAL_TOTAL_POSSIBLE_ORDERS_TOKEN_SUCCESS ';
export const HISTORICAL_TOTAL_POSSIBLE_ORDERS_TOKEN_ERROR = 'HISTORICAL_TOTAL_POSSIBLE_ORDERS_TOKEN_ERROR';