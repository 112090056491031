import {
  PAYPAL_CHECKER_REQUEST,
  PAYPAL_CHECKER_ERROR,
  PAYPAL_CHECKER_SUCCESS,
} from "./ActionType";
const initialState = {
  isRequest: false,
  isSuccess: false,
  isError: false,
  paypalchecker: {},
};

const paypalCheckerReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYPAL_CHECKER_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        paypalchecker: action.payload,
      };
    case PAYPAL_CHECKER_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        paypalchecker: action.payload,
      };

    case PAYPAL_CHECKER_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        paypalchecker: action.payload,
      };
    default:
      return state;
  }
};

export default paypalCheckerReducer;