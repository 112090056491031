import React from "react";
import { Image, Button, Modal, LegacyCard } from "@shopify/polaris";
import { Processed } from "../../assets";


export const DeleteWarning = (props) => {
    return (
      <>
        <div className="popup" id="popup" style={{gap:"15px",left:"40%" }}>
          <p className="popupHeading">Are you sure you want to delete?</p>
          <div className="" style={{ display: "flex", gap: "10px" }}>
            <Button
              primary
              onClick={() => {
                props.setPopup(false);
              }}
              outline
            >
              No
            </Button>
            <Button
              primary
              onClick={() => {
                props.handleDelete(props.carrierOverrideId);
                props.setPopup(false);
                props.setDeletedAlert(true);
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </>
    );
  };
  

export const CarrierAddedPopup = (props) => {
    const handleChange = () => {
      props.setDeletedAlert(false);
    };
  
    return (
      <>
        <div style={{ height: "", width: "100px" }}>
          <Modal open={props.deletedAlert} onClose={handleChange} title="">
            <LegacyCard sectioned>
              <div
                className="d-flex"
                style={{ alignItems: "center", gap: "15px" , flexDirection:"column"}}
              >
                <Image src={Processed} width={50} />
                <p className="popupHeading">
                  Your carrier was Added successfully!
                </p>
              </div>
            </LegacyCard>
          </Modal>
        </div>
      </>
    );
  };
  
  export const CarrierDeletedAlert = (props) => {
    const handleChange = () => {
      props.setDeletedAlert(false);
    };
  
    return (
      <>
        <div style={{ height: "", width: "100px" }}>
          <Modal open={props.deletedAlert} onClose={handleChange} title="">
            <LegacyCard sectioned>
              <div
                className="d-flex"
                style={{ alignItems: "center", gap: "15px", flexDirection:"column" }}
              >
                <Image src={Processed} width={50} />
                <p className="popupHeading">
                  Your carrier was deleted successfully!
                </p>
              </div>
            </LegacyCard>
          </Modal>
        </div>
      </>
    );
  };

  export const OrdersCount = (props) => {
    const handleChange = () => {
      props.setDeletedAlert(false);
      props.setOpenPopup(false)
    };
    return (
      <>
        <div style={{ height: "", width: "" }}>
          <Modal open={props.deletedAlert} onClose={handleChange} title="">
            <LegacyCard sectioned>
              <div
                className="d-flex"
                style={{ alignItems: "center", gap: "15px" , flexDirection:"column"}}
              >
                <Image src={Processed} width={50} />
                <p className="popupHeading">
                  Number of possible orders {props.orders}
                </p>
                <p data-testid="para-test-id">Are you Sure you want to create?</p>
                <div className="d-flex gap-10">
                <Button primary onClick={()=>{props.handleOrders(props.formattedValue,props.orders); handleChange()}}>Yes</Button>
                <Button onClick={()=>{handleChange()}}>No</Button>
                </div>
              </div>
            </LegacyCard>
          </Modal>
        </div>
      </>
    );
  };