import {React, useState} from "react";
import {Image, IndexTable, LegacyCard, Text, Tooltip,EmptySearchResult} from "@shopify/polaris";
import {DangerTable, Delete} from "../../../assets";
import {CarrierDeletedAlert, DeleteWarning} from "../../../components/popup";
import {deleteCarrier} from "../../../redux/store/courierMapping/Action";
import {useDispatch} from "react-redux";
import { useParams } from "react-router-dom";
import {useTranslation} from "react-i18next";

const CourierMappingTable = (props) => {
  const {id, storeType} = useParams();
  const {i18n, t} = useTranslation();
  const [popupOpen, setPopup] = useState(false);
  const [carrierOverrideId, setcarrierOverrideId] = useState(0);
  const [deletedAlert, setDeletedAlert] = useState(false);
  const dispatch = useDispatch()
 
  const handleDelete = (carrierOverrideId) => {
    const payload = {
      id:carrierOverrideId,
      shopId:id,
      storeType:storeType
    }
    dispatch(deleteCarrier(payload));
  };
  const resourceName = {
    singular: "Rule",
    plural: "Rules",
  };
  const emptyStateMarkup = (
    <EmptySearchResult
    title={t("No_Rules_found")}
    description={''}
      withIllustration
    />
  );

  const rowMarkup = props?.carrier.map(
    (
      { id, carrier, carrierToReplaceId, reason, createdAt, country },
      index
    ) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">
            <p className="table-data">{carrier}</p>
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <Text>
            <p className="table-data">{country}</p>
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <Text variant="bodyMd" as="span" numeric>
            <p className="table-data">{carrierToReplaceId}</p>
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <Text variant="bodyMd" as="span" numeric>
            <p className="table-data">{reason}</p>
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <p className="table-data">{createdAt}</p>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <p
            className="table-data d-flex gap-10"
            style={{ alignItems: "baseline" }}
          >
            <Image
              onClick={() => {
                setPopup(true);
                setcarrierOverrideId(id);
              }}
              src={Delete}
              width={15}
            />
            <Tooltip content="Delete">
              <Image src={DangerTable} width={15} />
            </Tooltip>
          </p>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const orderfrom = <div className="table_heading"> {t("PayPal_carrier")} </div>;
  const ordersupto = <div className="table_heading"> {t("Country")} </div>;
  const totalorderFound = <div className="table_heading">{t("Original_carrier")} </div>;
  const orderAwaiting = <div className="table_heading">{t("Reason")}</div>;
  const Actions = <div className="table_heading">{t("Created_at")} </div>;
  const Createdat = <div className="table_heading">{t("Actions")}</div>;

  return (
    <>
      <div style={{ marginTop: "30px" }}>
        {popupOpen ? (
          <DeleteWarning
            setDeletedAlert={setDeletedAlert}
            setPopup={setPopup}
            carrierOverrideId={carrierOverrideId}
            handleDelete={handleDelete}
          />
        ) : null}
        {deletedAlert ? (
          <CarrierDeletedAlert
            setDeletedAlert={setDeletedAlert}
            deletedAlert={deletedAlert}
          />
        ) : null}
        <LegacyCard sectioned>
          <LegacyCard.Section>
          
              <IndexTable
                resourceName={resourceName || 0}
                itemCount={props.carrier.length}
                emptyState={emptyStateMarkup}
                headings={[
                  { id: "1", title: orderfrom },
                  { id: "2", title: ordersupto },
                  { id: "3", title: totalorderFound },
                  { id: "4", title: orderAwaiting },
                  { id: "5", title: Createdat },
                  { id: "6", title: Actions },
                ]}
                selectable={false}
              >
                {rowMarkup}
              </IndexTable>
           
          </LegacyCard.Section>
        </LegacyCard>
      </div>
    </>
  );
};

export default CourierMappingTable;
