import React, {useEffect, useState} from 'react'
import {
    Layout,
    LegacyCard,
    SkeletonBodyText,
    SkeletonDisplayText,
    SkeletonPage,
    TextContainer,
} from '@shopify/polaris';

const Partners = () => {
  const [loading, setLoading] = useState(true);

  useEffect(()=> {
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  },[])

  const skeleton =  <SkeletonPage primaryAction>
    <Layout.Section>
      <LegacyCard sectioned>
        <SkeletonBodyText />
      </LegacyCard>
      <LegacyCard sectioned>
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </TextContainer>
      </LegacyCard>
      <LegacyCard sectioned>
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </TextContainer>
      </LegacyCard>
    </Layout.Section>
    <Layout.Section secondary>
      <LegacyCard>
        <LegacyCard.Section>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={2} />
          </TextContainer>
        </LegacyCard.Section>
        <LegacyCard.Section>
          <SkeletonBodyText lines={1} />
        </LegacyCard.Section>
      </LegacyCard>
      <LegacyCard subdued>
        <LegacyCard.Section>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={2} />
          </TextContainer>
        </LegacyCard.Section>
        <LegacyCard.Section>
          <SkeletonBodyText lines={2} />
        </LegacyCard.Section>
      </LegacyCard>
    </Layout.Section>
</SkeletonPage>
  return (
    <div>
      {loading ? skeleton : <iframe src="https://trackipal.partnerpage.io/integrations" height="800px" width="100%" style={{border:"none"}}></iframe>
 }
    </div>
  )
}

export default Partners
