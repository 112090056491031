import {
    PROCESSES_ERROR, PROCESSES_REQUEST,PROCESSES_SUCCESS
  } from "./ActionType";
  const initialState = {
    isRequest: false,
    isSuccess: false,
    isError: false,
    data: {},
  };
  
  const processesReducer = (state = initialState, action) => {
    switch (action.type) {
      case PROCESSES_REQUEST:
        return {
          isRequest: true,
          isSuccess: false,
          isError: false,
          data: action.payload,
        };
  
      case PROCESSES_SUCCESS:
        return {
          isRequest: false,
          isSuccess: true,
          isError: false,
          data: action.payload,
        };
      case PROCESSES_ERROR:
        return {
          isRequest: false,
          isSuccess: false,
          isError: true,
          data: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default processesReducer;