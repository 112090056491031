import {
  SET_DEFAULT_ERROR,
  SET_DEFAULT_REQUEST,
  SET_DEFAULT_SUCCESS,
  SET_DELETE_ERROR,
  SET_DELETE_REQUEST,
  SET_DELETE_SUCCESS,
  DIGITAL_SYNC_ERROR,
  DIGITAL_SYNC_REQUEST,
  DIGITAL_SYNC_SUCCESS
} from "./ActionType";

const initialState = {
  delete: {
    isRequest: false,
    isSuccess: false,
    isError: false,
    data: {},
  },
  digital_sync: {
    isRequest: false,
    isSuccess: false,
    isError: false,
    data: {},
  },
  default: {
    isRequest: false,
    isSuccess: false,
    isError: false,
    data: {},
  }
};

const storePaypalSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEFAULT_REQUEST:
      return {
        ...state,
        default: {
          ...state.default,
          isRequest: true,
          isSuccess: false,
          isError: false,
          data: action.payload,
        },
      };

    case SET_DEFAULT_SUCCESS:
      return {
        ...state,
        default: {
          ...state.default,
          isRequest: false,
          isSuccess: true,
          isError: false,
          data: action.payload,
        },
      };

    case SET_DEFAULT_ERROR:
      return {
        ...state,
        default: {
          ...state.default,
          isRequest: false,
          isSuccess: false,
          isError: true,
          data: action.payload,
        },
      };
    case SET_DELETE_REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          isRequest: true,
          isSuccess: false,
          isError: false,
          data: action.payload,
        },
      };
    case SET_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          isRequest: false,
          isSuccess: true,
          isError: false,
          data: action.payload,
        },
      };

    case SET_DELETE_ERROR:
      return {
        ...state,
        delete: {
          ...state.delete,
          isRequest: false,
          isSuccess: false,
          isError: true,
          data: action.payload,
        },
      };

      case DIGITAL_SYNC_REQUEST:
        return {
          ...state,
          digital_sync: {
            ...state.digital_sync,
            isRequest: true,
            isSuccess: false,
            isError: false,
            data: action.payload,
          },
        };
      case DIGITAL_SYNC_SUCCESS:
        return {
          ...state,
          digital_sync: {
            ...state.digital_sync,
            isRequest: false,
            isSuccess: true,
            isError: false,
            data: action.payload,
          },
        };
  
      case DIGITAL_SYNC_ERROR:
        return {
          ...state,
          digital_sync: {
            ...state.digital_sync,
            isRequest: false,
            isSuccess: false,
            isError: true,
            data: action.payload,
          },
        };

    default:
      return state;
  }
};

export default storePaypalSettingsReducer;
