import { React } from "react";
import {
  Badge,
  IndexTable,
  LegacyCard,
  EmptySearchResult,
  Text,
} from "@shopify/polaris";
import { HistoricalSkeletonTable } from "../../../components/skeletonLoader";
import moment from "moment";
import { useTranslation } from "react-i18next";

const HistoricalDataTable = (props) => {
  const { i18n, t } = useTranslation();

  const resourceName = {
    singular: "Historical Orders",
    plural: "Historical Orders",
  };

  const emptyStateMarkup = (
    <EmptySearchResult
    title={t("No_Historical_Orders_found")}
    description={''}
      withIllustration
    />
  );
  function formatDateTime(datetime) {
    try {
      const date = moment(datetime);
      const formattedDate = date.format("MMM DD, YYYY, h:mm A");
      return formattedDate;
    } catch (err) {
      return "";
    }
  }

  const rowMarkup = props.orders.map(
    (
      { fromDate, endDate, total, pending, id, status, creationDate },
      index
    ) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">
            <p style={{padding:"10px"}} className="table-data">{fromDate}</p>
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <Text>
            <p className="table-data">{endDate}</p>
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <Text variant="bodyMd" as="span" numeric>
            <p className="table-data">{total}</p>
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <Text variant="bodyMd" as="span" numeric>
            <p className="table-data">{pending}</p>
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <Badge
            status="success"
            variant="bodyMd"
            as="span"
            alignment="end"
            numeric
            progress="complete"
            statusAndProgressLabelOverride="Status: Published. Your online store is visible."
          >
            <p className="table-data">{status}</p>
          </Badge>
        </IndexTable.Cell>

        <IndexTable.Cell>{creationDate === null ? "" : formatDateTime(creationDate)}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const orderfrom = <div className="table_heading">{t("Orders_from")}</div>;
  const ordersupto = <div className="table_heading">{t("Orders_up_to")}</div>;
  const totalorderFound = (
    <div className="table_heading">{t("Total_orders_found")}</div>
  );
  const orderAwaiting = (
    <div className="table_heading"> Orders awaiting sync </div>
  );
  const status = <div className="table_heading">{t("Status")}</div>;
  const RequestedAt = <div className="table_heading">{t("Requested_at")}</div>;

  return (
    <>
      <div style={{ marginTop: "0px" }}>
        <LegacyCard sectioned>
          <LegacyCard.Section>
            {props.loading ? (
              <HistoricalSkeletonTable />
            ) : (
              <IndexTable
                resourceName={resourceName || 0}
                itemCount={props.orders.length}
                emptyState={emptyStateMarkup}
                headings={[
                  { id: "orderfrom", title: orderfrom },
                  { id: "ordersupto", title: ordersupto },
                  { id: "totalorderFound", title: totalorderFound },
                  { id: "orderAwaiting", title: orderAwaiting },
                  { id: "status", title: status },
                  { id: "RequestedAt", title: RequestedAt },
                ]}
                selectable={false}
              >
                {rowMarkup}
              </IndexTable>
            )}
            
          </LegacyCard.Section>
        </LegacyCard>
      </div>
    </>
  );
};

export default HistoricalDataTable;
