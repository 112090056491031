import i18n from "i18next";
import { initReactI18next } from "react-i18next";


// Importing translation files

import translationEN from "../components/language/en.json";
import translationHE from "../components/language/fr.json";
import translationES from "../components/language/es.json";
import translationDE from "../components/language/de.json";
import translationIT from "../components/language/it.json";
import translationPT from "../components/language/pt.json";
import translationPL from "../components/language/pl.json";
import translationNL from "../components/language/nl.json";


//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationHE,
  },
  es: {
    translation: translationES,
  },
  de:{
    translation:translationDE
  },
  it:{
    translation:translationIT
  },
  pt:{
    translation:translationPT
  },
  pl:{
    translation:translationPL
  },
  nl:{
    translation:translationNL
  },
};

//i18N Initialization

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng:localStorage.getItem("code") ? localStorage.getItem("code") : "en", //default language
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
