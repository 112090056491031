import { Button, Layout, LegacyCard, TextField } from "@shopify/polaris";
import { React, useCallback, useState } from "react";
import Header from "../../components/header";
import CarrierTable from "./table/CarrierTable";
import Pagination from "../../components/pagination";
const Carrier = () => {
  const [entries, setEntries] = useState("1");
  const [carrierName, setCarrierName] = useState("");

  const handleNumberofEntries = useCallback(
    (newValue) => setEntries(newValue),
    []
  );

  const handleCarrierName = useCallback(
    (newValue) => setCarrierName(newValue),
    []
  );

  return (
    <Layout>
      <Header title="Carrier" />
      <Layout.Section>
        <div className="wrapper">
          <LegacyCard sectioned>
            <div className="d-flex justify-content-between flex-wrap">
              <div>
                <TextField
                  label="Quantity"
                  type="number"
                  value={entries}
                  onChange={handleNumberofEntries}
                  autoComplete="off"
                />
              </div>
              <div className="d-flex gap-20 align-item-end">
                <div>
                <TextField
                  label="Store name"
                  value={carrierName}
                  onChange={handleCarrierName}
                  autoComplete="off"
                  placeholder="New carrier name"
                />
                </div>
                
                <div>
                  <Button primary>New</Button>
                </div>
              </div>
            </div>
            <CarrierTable/>
            <div className="d-flex mt-2 justify-content-between">
                <p>Showing 0 to 0 of 0 entries</p>
                <Pagination/>
            </div>
          </LegacyCard>
        </div>
      </Layout.Section>
    </Layout>
  );
};

export default Carrier;
