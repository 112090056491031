import { ADVANCE_SEARCH_ERROR, ADVANCE_SEARCH_REQUEST, ADVANCE_SEARCH_SUCCESS } from "./ActionType";

const initialState = {
    isRequest: false,
    isSuccess: false,
    isError: false,
    advanceSearch: {}
  };
  
  const advanceSearchStoreReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADVANCE_SEARCH_REQUEST:
          return {
            ...state,
            isRequest: true,
            isSuccess: false,
            isError: false,
            advanceSearch: action.payload,
          };
        case ADVANCE_SEARCH_SUCCESS:
          return {
            ...state,
            isRequest: false,
            isSuccess: true,
            isError: false,
            advanceSearch: action.payload,
          };
        case ADVANCE_SEARCH_ERROR:
          return {
            ...state,
            isRequest: false,
            isSuccess: false,
            isError: true,
            advanceSearch: action.payload,
          };
          default:
            return state;
        }
  }

  export default advanceSearchStoreReducer;