import {
  CARRIERS_SUCCESS,
  CARRIERS_ERROR,
  CARRIERS_REQUEST,
} from "./ActionType";

const initialState = {
  isRequest: false,
  isSuccess: false,
  isError: false,
  data: {},
};

const carriersReducer = (state = initialState, action) => {
  switch (action.type) {
    case CARRIERS_REQUEST:
      return {
        isRequest: true,
        isSuccess: false,
        isError: false,
        data: action.payload,
      };
    case CARRIERS_SUCCESS:
      return {
        isRequest: false,
        isSuccess: true,
        isError: false,
        data: action.payload,
      };
    case CARRIERS_ERROR:
      return {
        isRequest: false,
        isSuccess: false,
        isError: true,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default carriersReducer;