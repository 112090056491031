import { GDPR_REQUEST, GDPR_SUCCESS, GDPR_ERROR } from "./ActionType";

const initialState = {
  isRequest: false,
  isSuccess: false,
  isError: false,
  gdprData: {},
};

const gdprDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GDPR_REQUEST:
      return {
        ...state,
        isRequest: true,
        isSuccess: false,
        isError: false,
        gdprData: action.payload,
      };
    case GDPR_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        isError: false,
        gdprData: action.payload,
      };
    case GDPR_ERROR:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isError: true,
        gdprData: action.payload,
      };
    default:
      return state;
  }
};

export default gdprDataReducer;
