import { ROUTES_NAME } from "../../../config/constants";
import { ACCESS_TOKEN } from "../../../constants";
import { FETCH_BY_NAME_ERROR, FETCH_BY_NAME_REQUEST, FETCH_BY_NAME_SUCCESS } from "./ActionType";

export const fetchByNameApi = (payload) => {
    const {storeId,orderName} = payload;
    return (dispatch) => {
        dispatch(fetchByNameRequest());
        const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.fetch_by_name}`;
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({
              orderName: orderName,
              storeId:storeId
            }),
            credentials: 'include',
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
                'Content-Type': 'application/json',
                'Origin': `${process.env.REACT_APP_URL}`
            },
          })
          .then((res) => res.json())
          .then((pullLogData) => {
            dispatch(fetchByNameSuccess(pullLogData));
          })
          .catch((data) => {
            console.log(data,"error");
            dispatch(fetchByNameError());
          })  
    }
}


function fetchByNameRequest(fetchData) {
    return {
      type: FETCH_BY_NAME_REQUEST,
      payload: fetchData,
    };
  }
  function fetchByNameSuccess(fetchData) {
    return {
      type: FETCH_BY_NAME_SUCCESS,
      payload: fetchData,
    };
  }
  function fetchByNameError(fetchData) {
    return {
      type: FETCH_BY_NAME_ERROR,
      payload: fetchData,
    };
  }
  